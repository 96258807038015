import { Box, Tab, Tabs } from '@mui/material';
import { useEffect } from 'react';
import { Link, useMatch, useSearchParams } from 'react-router-dom';
import {
	AccountsAccountType,
	AccountsConfig,
	accountApprovalsMap,
	accountsAccountType,
} from '../../constants/Accounts';
import {
	Company,
	CompanyTypes,
	UserDetails,
	UserProps,
	accountTypes,
	assertAccountType,
} from '../../constants/Common';
import { UserInvite } from '../../constants/UserInvite';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { useFeatureFlagContext } from '../../providers/featureFlags/Provider';
import { AccountsState } from './StateManagement/actions';

export type AccountsProps = UserProps & {
	setUserCompany: (company: Company) => void;
	setSites: (sites: AccountsState['sites']) => void;
	setCompanySites: (companySites: AccountsState['sites']) => void;
	setCompanies: (companies: AccountsState['companies']) => void;
	setUsers: (users: AccountsState['users']) => void;
	setUserInvites: (userInvites: AccountsState['userInvites']) => void;
	setUnapprovedUsers: (
		unapprovedUsers: AccountsState['unapprovedUsers'],
	) => void;
	setLoadingNewAccounts: (
		loadingNewAccountsState: AccountsState['loadingNewAccounts'],
	) => void;
	setLoadingUsers: (loadingUsersState: AccountsState['loadingUsers']) => void;
	setLoadingUserInvites: (
		loadingUserInvites: AccountsState['loadingUserInvites'],
	) => void;
	firebaseApi: Pick<
		FirebaseApi,
		| 'activeSitesByCompanySubscription'
		| 'companiesSubscriptionByType'
		| 'activeSitesByCompanyTypeSubscription'
		| 'subscribeUnapprovedUsersByCompany'
		| 'subscribeNonKioskUsersByCompany'
		| 'getCompany'
		| 'subscribeUserInvitesByCompany'
	>;
};

export const Accounts = ({
	userDetails,
	setUserCompany,
	setSites,
	setCompanySites,
	setCompanies,
	setUnapprovedUsers,
	setUsers,
	setUserInvites,
	setLoadingNewAccounts,
	setLoadingUsers,
	setLoadingUserInvites,
	firebaseApi,
}: AccountsProps): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<AccountsAccountType>(accountType, accountsAccountType);
	// todo: Remove me once userInvites fully merged
	const featureFlags = useFeatureFlagContext();
	const enableUserInvites = featureFlags.get('enableUserInvites');
	const titles = AccountsConfig[accountType].titles.filter(
		// easiest to do this this way, as the accountMap is used too often to refactor for a patchjob like this
		(title) => !(!enableUserInvites && title === 'invites'),
	);
	const showContractedTo = accountType === accountTypes.handler;
	const pathMatch = useMatch('/accounts/:tab');
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const getCompany = async (): Promise<void> => {
			const company = await firebaseApi.getCompany(userDetails.companyID);
			if (company) setUserCompany(company);
		};
		getCompany();
	}, [
		firebaseApi,
		setUserCompany,
		userDetails.accountType,
		userDetails.companyID,
	]);

	useEffect(() => {
		const companiesSubscription = firebaseApi.companiesSubscriptionByType(
			CompanyTypes.construction,
			(companiesList) => {
				const companiesMap = companiesList.reduce<
					Record<string, Company>
				>((companyRecords, company) => {
					companyRecords[company.id] = company;
					return companyRecords;
				}, {});
				setCompanies(companiesMap);
			},
		);

		if (!showContractedTo) {
			const companySitesSubscription =
				firebaseApi.activeSitesByCompanySubscription(
					userDetails.companyID,
					(siteMap) => {
						setSites(siteMap);
					},
				);
			return (): void => {
				companySitesSubscription();
				companiesSubscription();
			};
		} else {
			const constructionSites =
				firebaseApi.activeSitesByCompanyTypeSubscription(
					CompanyTypes.construction,
					(siteMap) => setSites(siteMap),
				);
			const companySitesSubscription =
				firebaseApi.activeSitesByCompanySubscription(
					userDetails.companyID,
					(siteMap) => setCompanySites(siteMap),
				);
			return (): void => {
				constructionSites();
				companySitesSubscription();
				companiesSubscription();
			};
		}
	}, [
		firebaseApi,
		setCompanies,
		setCompanySites,
		setSites,
		showContractedTo,
		userDetails.accountType,
		userDetails.companyID,
	]);

	useEffect(() => {
		return firebaseApi.subscribeUnapprovedUsersByCompany(
			userDetails.companyID,
			(unapprovedUsers: Record<string, UserDetails>): void => {
				setUnapprovedUsers(unapprovedUsers);
				setLoadingNewAccounts(false);
			},
		);
	}, [
		firebaseApi,
		setLoadingNewAccounts,
		setUnapprovedUsers,
		userDetails.accountType,
		userDetails.companyID,
	]);

	useEffect(() => {
		return firebaseApi.subscribeNonKioskUsersByCompany(
			userDetails.companyID,
			(newUsers: UserDetails[]): void => {
				const filteredUsers = newUsers.filter((user) =>
					accountApprovalsMap[accountType].includes(user.accountType),
				);
				const users = filteredUsers.reduce<Record<string, UserDetails>>(
					(acc, user) => {
						acc[user.userID] = user;
						return acc;
					},
					{},
				);
				setUsers(users);
				setLoadingUsers(false);
			},
		);
	}, [accountType, firebaseApi, setLoadingUsers, setUsers, userDetails]);

	useEffect(() => {
		return firebaseApi.subscribeUserInvitesByCompany(
			userDetails.companyID,
			(newUserInvites: UserInvite[]): void => {
				const userInvites = newUserInvites.reduce<
					Record<string, UserInvite>
				>((acc, userInvite) => {
					acc[userInvite.id] = userInvite;
					return acc;
				}, {});
				setUserInvites(userInvites);
				setLoadingUserInvites(false);
			},
		);
	}, [
		accountType,
		firebaseApi,
		setLoadingUserInvites,
		setUserInvites,
		userDetails,
	]);

	return (
		<Box
			sx={{
				borderBottom: 1,
				borderColor: 'divider',
			}}>
			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
				{titles.map((title, index) => (
					<Tab
						label={title}
						value={title}
						key={index}
						component={Link}
						to={`${title.replace(/\s+/g, '-')}?${searchParams}`}
					/>
				))}
			</Tabs>
		</Box>
	);
};
