import { AlertColor, SnackbarCloseReason } from '@mui/material';
import { CustomSnackBar } from '../../SnackBar/SnackBar';

export enum CreateUserInviteStatuses {
	None = 'None',
	Loading = 'Loading',
	Error = 'Error',
	Success = 'Success',
}

export type CreateUserInviteSnackBarProps = {
	snackBarStatus: CreateUserInviteStatuses;
	onClose: (
		event: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason,
	) => void;
};

export const CreateUserInviteSnackBar = ({
	snackBarStatus,
	onClose,
}: CreateUserInviteSnackBarProps): JSX.Element => {
	const snackBarContent: Record<
		CreateUserInviteStatuses,
		{ text: string; severity: AlertColor }
	> = {
		[CreateUserInviteStatuses.None]: { severity: 'info', text: '' },
		[CreateUserInviteStatuses.Error]: {
			severity: 'error',
			text: 'Could not create user invite',
		},
		[CreateUserInviteStatuses.Success]: {
			severity: 'success',
			text: 'User invite created',
		},
		[CreateUserInviteStatuses.Loading]: {
			severity: 'info',
			text: 'Creating user invite',
		},
	};

	return (
		<CustomSnackBar
			open={true}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			onClose={onClose}
			snackBarText={snackBarContent[snackBarStatus].text}
			severity={snackBarContent[snackBarStatus].severity}
		/>
	);
};
