import { Stack, Typography } from '@mui/material';
import { UserInvitesTableData } from './helpers';

export type UserInvitePlacementProps = {
	placement: UserInvitesTableData['placement'];
};

export const UserInvitePlacement = ({
	placement,
}: UserInvitePlacementProps): JSX.Element => {
	const company = placement?.contractedTo?.name;
	const site = placement?.site?.name;
	return (
		<Stack justifyContent="center" alignItems="center" textAlign="center">
			{!company && !site ? (
				<Typography variant="body2">-</Typography>
			) : (
				<>
					<Typography variant="body2">
						{company ?? '(No Placement)'}
					</Typography>
					<Typography variant="body2" lineHeight={1}>
						{site ?? '(No Site)'}
					</Typography>
				</>
			)}
		</Stack>
	);
};
