import { Stack, Typography } from '@mui/material';
import { UserInvitesTableData } from './helpers';

export type UserInviteEmployeeProps = {
	userInfo: UserInvitesTableData['userInfo'];
};

export const UserInviteEmployee = ({
	userInfo,
}: UserInviteEmployeeProps): JSX.Element => {
	return (
		<Stack
			display="flex"
			alignItems={{ xs: 'center', lg: 'flex-start' }}
			textAlign={{ xs: 'center', lg: 'left' }}>
			<Typography variant="body1" lineHeight={1}>
				{userInfo.name}
			</Typography>
			<Typography variant="caption" lineHeight={1}>
				{userInfo.email}
			</Typography>
			<Typography variant="caption" lineHeight={1.5}>
				{userInfo.mobileNumber}
			</Typography>
		</Stack>
	);
};
