import { Stack, Typography } from '@mui/material';
import { Activity, TimesheetActivity } from '../../../../../constants/Common';
import { getTimeForHours } from '../../../../../constants/stringUtilities';
import { AddActivityButton } from '../../Details/EditTimesheet/Hours/AddActivityButton';
import { EditRow } from '../../Details/EditTimesheet/Hours/EditRow';
import { NewActivity } from '../reducer';

export type TimesheetHoursCellProps = {
	disabled: boolean;
	siteActivities: TimesheetActivity[];
	activities: NewActivity[];
	addActivity: () => void;
	updateActivity: (
		id: Activity['id'],
		activity: Activity['activity'],
		hours: number,
	) => void;
	deleteActivity: (id: Activity['id']) => void;
};

export const TimesheetHoursCell = ({
	disabled,
	siteActivities,
	activities,
	addActivity,
	updateActivity,
	deleteActivity,
}: TimesheetHoursCellProps): JSX.Element => {
	const totalHours = activities.reduce(
		(total, activity) => total + activity.hours,
		0,
	);
	return (
		<Stack spacing={0.5}>
			<Stack direction="row" spacing={1} justifyContent="space-between">
				<Typography fontSize={{ md: 'small' }}>
					Total Paid Hours
				</Typography>
				<Stack direction="row" spacing={1}>
					<Typography fontSize="small">
						{getTimeForHours(totalHours)}
					</Typography>
					<AddActivityButton
						disabled={disabled}
						addActivity={addActivity}
						buttonContainerProps={{
							// To align the button with the text
							margin: '-8px !important',
							paddingX: 1,
							paddingBottom: 1,
						}}
					/>
				</Stack>
			</Stack>
			{activities.map((activity) => (
				<EditRow
					key={`activity-${activity.id}`}
					disabled={disabled}
					activity={{
						...activity,
						activity:
							activity.activity === null
								? { id: '', name: '' }
								: activity.activity,
					}}
					error={activity.hasError}
					siteActivities={siteActivities}
					updateActivity={updateActivity}
					deleteActivity={deleteActivity}
				/>
			))}
		</Stack>
	);
};
