import { createDocument } from './createDocument';
import { createLeaveNote } from './createLeaveNote';
import { createTemplate } from './createTemplate';
import { createTimesheetNote } from './createTimesheetNote';
import { createUserInvite } from './createUserInvite';
import { deleteAllTimesheetNotes } from './deleteAllTimesheetNotes';
import { deleteLeaveNote } from './deleteLeaveNote';
import { deletePayrollIntegration } from './deletePayrollIntegration';
import { deleteTemplate } from './deleteTemplate';
import { deleteTimesheetNote } from './deleteTimesheetNote';
import { editLeaveNote } from './editLeaveNote';
import { editTimesheetNote } from './editTimesheetNote';
import { fetchCheckoutPortal } from './fetchCheckoutPortal';
import { fetchCustomerOutstandingPayments } from './fetchCustomerOutstandingPayments';
import { fetchCustomerPortal } from './fetchCustomerPortal';
import { fetchInvoicingActivityIDs } from './fetchInvoicingActivityIDs';
import { fetchInvoicingContactIDs } from './fetchInvoicingContacts';
import { fetchInvoicingContractIDs } from './fetchInvoicingContractIDs';
import { fetchInvoicingEmployeeIDs } from './fetchInvoicingEmployeeIDs';
import { fetchInvoicingTokens } from './fetchInvoicingTokens';
import { fetchMultiTimesheet } from './fetchMultiTimesheet';
import { fetchNewStripeCustomer } from './fetchNewStripeCustomer';
import { fetchPayrollActivityIDs } from './fetchPayrollActivityIDs';
import { fetchPayrollEmployeeIDs } from './fetchPayrollEmployeeIDs';
import { fetchPayrollLeaveIDs } from './fetchPayrollLeaveIDs';
import { fetchPayrollTokens } from './fetchPayrollTokens';
import { fetchSingleTimesheet } from './fetchSingleTimesheet';
import { fetchSubscriptionTrialDates } from './fetchSubscriptionTrialDates';
import { inductionsFirestore } from './inductionsFirestore';
import { invoicingIntegrationsTrigger } from './invoicingIntegrationsTrigger';
import { payrollEmployeeImport } from './payrollEmployeeImport';
import { payrollTimesheetExport } from './payrollTimesheetExport';
import { payrollTriggerLeaveIntegration } from './payrollTriggerLeaveIntegration';
import { payrollTriggerTimesheetIntegration } from './payrollTriggerTimesheetIntegration';
import { projectTracking } from './projectTracking';
import { projectTrackingFirestore } from './projectTrackingFirestore';
import { resendUserInvite } from './resendUserInvite';
import { safetyCourseFirestore } from './safetyCourseFirestore';
import { sendTimesheetReminderNotification } from './sendTimesheetReminderNotification';
import { sitesFirestore } from './sitesFirestore';
import { validateSafetyCourse } from './validateSafetyCourse';
import { workHistoryTrigger } from './workHistoryTrigger';

const cloudFunctionApi = {
	createDocument,
	createLeaveNote,
	createTemplate,
	createTimesheetNote,
	deleteAllTimesheetNotes,
	deleteLeaveNote,
	deletePayrollIntegration,
	deleteTemplate,
	deleteTimesheetNote,
	editLeaveNote,
	editTimesheetNote,
	fetchCheckoutPortal,
	fetchCustomerOutstandingPayments,
	fetchCustomerPortal,
	fetchInvoicingActivityIDs,
	fetchInvoicingContactIDs,
	fetchInvoicingContractIDs,
	fetchInvoicingEmployeeIDs,
	fetchInvoicingTokens,
	fetchMultiTimesheet,
	fetchNewStripeCustomer,
	fetchPayrollActivityIDs,
	fetchPayrollEmployeeIDs,
	fetchPayrollLeaveIDs,
	fetchPayrollTokens,
	fetchSingleTimesheet,
	fetchSubscriptionTrialDates,
	invoicingIntegrationsTrigger,
	payrollEmployeeImport,
	payrollTimesheetExport,
	payrollTriggerLeaveIntegration,
	payrollTriggerTimesheetIntegration,
	sendTimesheetReminderNotification,
	validateSafetyCourse,
	workHistoryTrigger,
	createUserInvite,
	resendUserInvite,
	...projectTracking,
	...projectTrackingFirestore,
	...safetyCourseFirestore,
	...sitesFirestore,
	...inductionsFirestore,
};

export type CloudFunctionApi = typeof cloudFunctionApi;

export default cloudFunctionApi;
