import { AllowDateOrTimestamp } from '../components/helpers/dateUtilities';
import type { Timestamp } from '../firebase/firebase';
import { TimesheetActivity } from './Common';
// These type definitions are really verbose, but it enables us to be far more exact about what states are and aren't possible

type BaseLeave = {
	id: string;
	leaveType: LeaveType;
	dateRequested: Timestamp;
	startDate: Timestamp;
	employer: {
		id: string;
		name: string;
	};
	employee: {
		id: string;
		name: string;
	};
	payrollStatus: PayrollStatus;
	reviewStatus: ReviewStatus;
};

type ApprovedLeave = BaseLeave & {
	reviewStatus: ReviewStatus.Approved;
	reviewedAt: Timestamp;
	reviewer: {
		name: string;
		id: string;
	};
};
type DisputedLeave = BaseLeave & {
	reviewStatus: ReviewStatus.Disputed;
	reviewedAt: Timestamp;
	reviewer: {
		name: string;
		id: string;
	};
};

type SubmittedLeave = BaseLeave & {
	reviewStatus: ReviewStatus.Submitted;
	reviewedAt: null;
	reviewer: null;
};

type LeaveReviewTypes = ApprovedLeave | SubmittedLeave | DisputedLeave;

type LeaveWithHours = LeaveReviewTypes & {
	endDate: null;
	hours: number;
};

type LeaveWithEndDate = LeaveReviewTypes & {
	endDate: Timestamp;
	hours: null;
};

export type Leave = LeaveWithEndDate | LeaveWithHours;

export enum ReviewStatus {
	Approved = 'Approved',
	Disputed = 'Disputed',
	Submitted = 'Submitted',
}

export enum LeaveType {
	Sick = 'Sick',
	Annual = 'Annual',
	Bereavement = 'Bereavement',
	Training = 'Training',
	AlternativeHolidays = 'AlternativeHolidays',
	MinimumLeaveAndHolidaysEntitlements = 'MinimumLeaveAndHolidaysEntitlements',
	OtherwiseWorkingDay = 'OtherwiseWorkingDay',
	Parental = 'Parental',
	FamilyViolence = 'FamilyViolence',
	Stress = 'Stress',
	Garden = 'Garden',
	DefenceForceVolunteers = 'DefenceForceVolunteers',
	LeaveWithoutPay = 'LeaveWithoutPay',
	GeneralElectionVoting = 'GeneralElectionVoting',
	EmploymentDuringAndAfterDisasters = 'EmploymentDuringAndAfterDisasters',
	LongService = 'LongService',
	JuryService = 'JuryService',
}

export type RelevantLeaveType =
	| LeaveType.Sick
	| LeaveType.Annual
	| LeaveType.Bereavement
	| LeaveType.Training
	| LeaveType.LeaveWithoutPay;

export type RequiredLeaveType =
	| LeaveType.Sick
	| LeaveType.Annual
	| LeaveType.Bereavement;

export const RelevantLeaveTypes: RelevantLeaveType[] = [
	LeaveType.Sick,
	LeaveType.Annual,
	LeaveType.Bereavement,
	LeaveType.Training,
	LeaveType.LeaveWithoutPay,
];

export const RequiredLeaveTypes: RelevantLeaveType[] = [
	LeaveType.Sick,
	LeaveType.Annual,
	LeaveType.Bereavement,
];

export enum LeavePayrollStatus {
	Unsent = 'Unsent',
	Sent = 'Sent',
	Failed = 'Failed',
}

type PayrollStatus =
	| LeavePayrollStatus.Unsent
	| LeavePayrollStatus.Sent
	| LeavePayrollStatus.Failed;

export const leaveLabels: Record<LeaveType, string> = {
	Sick: 'Sick Leave',
	Annual: 'Annual Leave',
	Bereavement: 'Bereavement Leave',
	Training: 'Training',
	AlternativeHolidays: 'Alternative Holidays',
	MinimumLeaveAndHolidaysEntitlements:
		'Minimum Leave and Holidays Entitlements',
	OtherwiseWorkingDay: 'Otherwise Working Day',
	Parental: 'Parental Leave',
	FamilyViolence: 'Family Violence Leave',
	Stress: 'Stress Leave',
	Garden: 'Garden Leave',
	DefenceForceVolunteers: 'Defence Force Volunteers',
	LeaveWithoutPay: 'Leave Without Pay',
	GeneralElectionVoting: 'General Election Voting Leave',
	EmploymentDuringAndAfterDisasters: 'Employment During and After Disasters',
	LongService: 'Long Service Leave',
	JuryService: 'Jury Service',
};

export const relevantLeaveTypesMap: Record<
	RelevantLeaveType,
	TimesheetActivity
> = RelevantLeaveTypes.reduce((acc, leaveType) => {
	acc[leaveType] = {
		id: leaveType,
		activityName: leaveLabels[leaveType],
		active: true,
	};
	return acc;
}, {} as Record<RelevantLeaveType, TimesheetActivity>);

export type NewLeave = AllowDateOrTimestamp<Omit<Leave, 'id'>>;
export type UpdateLeave = Pick<
	AllowDateOrTimestamp<Leave>,
	'leaveType' | 'startDate' | 'endDate' | 'hours'
>;

export const isRequiredLeave = (
	leaveType: string,
): leaveType is RequiredLeaveType =>
	RequiredLeaveTypes.includes(leaveType as RequiredLeaveType);

export const isRequiredLeaveMapped = (leaveTypes: string[]): boolean =>
	RequiredLeaveTypes.every((leaveType) => leaveTypes.includes(leaveType));
