import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnBlob,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type SingleTimesheetRequest = {
	timesheetID: string;
	includeSiteLogs?: boolean;
};

export type SingleTimesheetResponse = {
	blobUrl: string;
	size: number;
};

export const fetchSingleTimesheet = async (
	abortSignal: AbortSignal,
	user: User,
	requestBody: SingleTimesheetRequest,
): Promise<SingleTimesheetResponse | undefined> => {
	const blob =
		await makeCloudFunctionRequestReturnBlob<SingleTimesheetRequest>({
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.SingleTimesheet,
			method: 'POST',
			services: defaultCloudFunctionServices,
			body: requestBody,
		});

	if (!blob) return;

	return {
		blobUrl: URL.createObjectURL(blob),
		size: blob.size,
	};
};
