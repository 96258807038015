import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { endOfWeek, startOfWeek } from 'date-fns';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
	Activity,
	CompanyTypes,
	DayString,
	EntriesAddToDay,
	Site,
	TimesheetActivity,
	UserDetails,
	UserProps,
	accountTypes,
	emptyFunction,
	lowercaseDayString,
} from '../../../../constants/Common';
import {
	InvoiceStatuses,
	ProjectTrackingStatuses,
	Timesheet,
	TimesheetPayrollStatuses,
	WorkHistoryStatus,
} from '../../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../../constants/Timesheet/TimesheetStatus';
import {
	getRates,
	validTimesheetDateRange,
} from '../../../../constants/Timesheet/TimesheetUtilities';
import { Timestamp } from '../../../../firebase/firebase';
import type { FirebaseApi } from '../../../../firebase/firebaseApi';
import { CompanyAutoComplete } from '../../../Autocomplete/CompanyAutocomplete';
import DateWeekSelector from '../../../DateWeekSelector/DateWeekSelector';
import {
	formatSearchParamsDate,
	formatSpacedDate,
} from '../../../helpers/dateFormatters';
import { dateWithinCurrentWeeksRange } from '../../../helpers/dateUtilities';
import { sortObjectByField } from '../../../helpers/sortHelpers';
import { SiteSelectDropdown } from '../../../SiteSelectDropdown/SiteSelectDropdown';
import { DuplicateTimesheetAlert } from '../../DuplicateTimesheetAlert';
import {
	TimesheetError,
	TotalError,
	WeekActivities,
	validateTimesheetEntries,
} from '../../HandleActivityFunctions';
import TimesheetDisplayContentActivities from '../../TimesheetDisplayContentActivities';

/**
 * @deprecated This is the old Create timesheets display view firebase calls and should not be used
 */
export type CreateDeprecatedFirebaseCalls =
	| 'createTimesheet'
	| 'getSite'
	| 'companiesSubscriptionByType'
	| 'getLimitedContractsByEmployeeSiteSupplierAccepterStatus'
	| 'getLimitedContractByEmployeeSupplierAccepterStatus'
	| 'getUser'
	| 'activeSitesByCompanyTypeSubscription'
	| 'activeSitesByCompanySubscription'
	| 'findDuplicateTimesheet';

/**
 * @deprecated This is the old Create timesheets display view type and should not be used
 */
export type CreateDeprecatedProps = UserProps & {
	firebaseApi: Pick<FirebaseApi, CreateDeprecatedFirebaseCalls>;
	weekEnding: Date;
	setWeekEnding: (date: Date) => void;
	users: Record<string, UserDetails>;
	setSelected: Dispatch<string>;
	loading: boolean;
	navigateWithoutRouter: (href: string) => void;
	canActionTimesheets: boolean;
};

const totalErrorMap: Partial<Record<DayString, TotalError>> = {};

type TimesheetFieldErrors = Partial<
	Record<'site' | 'contractedTo' | 'user' | 'weekEnding', string | undefined>
>;

/**
 * @deprecated This is the old Create timesheets display view and should not be used
 */
export const CreateDeprecated = ({
	userDetails,
	firebaseApi,
	users,
	weekEnding,
	setWeekEnding,
	setSelected,
	loading,
	canActionTimesheets,
	navigateWithoutRouter,
}: CreateDeprecatedProps): JSX.Element => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const initialWeek: WeekActivities = useMemo(
		() => ({
			Monday: { activities: {} },
			Tuesday: { activities: {} },
			Wednesday: { activities: {} },
			Thursday: { activities: {} },
			Friday: { activities: {} },
			Saturday: { activities: {} },
			Sunday: { activities: {} },
		}),
		[],
	);

	const canChangeContractedTo =
		userDetails.accountType === accountTypes.handler;
	const isManagement = userDetails.accountType === accountTypes.management;
	const createApprovedTimesheetDisabled =
		userDetails.disableTimesheetApproval && isManagement;
	const [userID, setUserID] = useState<string>('');
	const [initialTimesheetStatus, setTimesheetStatus] = useState<
		Timesheet['timesheetStatus']
	>(
		canChangeContractedTo
			? TimesheetStatus.Submitted
			: TimesheetStatus.Approved,
	);
	const timesheetStatus = createApprovedTimesheetDisabled
		? TimesheetStatus.Submitted
		: initialTimesheetStatus;

	const newTimesheetStatuses = Object.values(TimesheetStatus).filter(
		(status) =>
			status !== TimesheetStatus.Archived &&
			status !== TimesheetStatus.Active,
	);
	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [contractedToCompany, setContractedToCompany] =
		useState<UserDetails['contractedTo']>(null);
	const [submitTimesheetLoading, setSubmitTimesheetLoading] = useState(false);
	const [site, setSite] = useState<string>(
		userDetails ? userDetails.siteID : '',
	);
	const [allSites, setAllSites] = useState<Record<string, Site>>({});
	const [updatedWeekActivities, setUpdatedWeekActivities] =
		useState<WeekActivities>(initialWeek);
	const [activityTypeMap, setActivityTypeMap] = useState<
		Record<string, TimesheetActivity>
	>({});
	const [timesheetEntriesErrorMap, setTimesheetEntriesErrorMap] = useState<
		Partial<Record<string, TimesheetError>>
	>({});
	const [timesheetFieldsErrorMap, setTimesheetFieldsErrorMap] =
		useState<TimesheetFieldErrors>({});
	const [toAddActivitiesList, setToAddActivitiesList] = useState<
		Record<string, Activity>
	>({});
	const [toDeleteActivitiesList, setToDeleteActivitiesList] = useState<
		string[]
	>([]);
	const [createDisabled, setCreateDisabled] = useState<boolean>(true);
	const [duplicateTimesheet, setDuplicateTimesheet] =
		useState<Timesheet | null>(null);

	const [entriesAddToDay, setEntriesAddToDay] = useState<EntriesAddToDay>({
		week: Timestamp.fromDate(startOfWeek(weekEnding)),
		weekEnding: Timestamp.fromDate(weekEnding),
		employerName: users[userID]?.company,
		employerID: users[userID]?.companyID,
		siteCompany: allSites[site]?.company,
		siteCompanyID: allSites[site]?.companyID,
		siteID: allSites[site]?.id,
		siteName: allSites[site]?.name,
		status: timesheetStatus,
		timesheetID: '',
		workerID: userID,
		workerName: users[userID]?.displayName,
		rate: 0,
	});

	const isRecruitmentSite =
		allSites[site] &&
		allSites[site].companyType === CompanyTypes.recruitment;

	const selectedContractedTo = isRecruitmentSite
		? { id: allSites[site].companyID, name: allSites[site].company }
		: !canChangeContractedTo
		? userDetails.contractedTo
		: contractedToCompany;

	const timesheetHasHours = (weekActivities: WeekActivities): boolean => {
		return Object.values(weekActivities)
			.map((day) =>
				Object.values(day.activities).map(({ hours }) => hours > 0),
			)
			.flat()
			.some((hasHours) => hasHours);
	};

	useEffect(() => {
		if (loading || submitTimesheetLoading) {
			return;
		}

		const findDuplicateTimesheet = async (
			userID: string,
			contractedToCompanyID: string,
			siteID: string,
		): Promise<void> => {
			const duplicateTimesheet = await firebaseApi.findDuplicateTimesheet(
				userID,
				contractedToCompanyID,
				siteID,
				Timestamp.fromDate(startOfWeek(weekEnding)),
			);

			if (duplicateTimesheet === null) {
				setDuplicateTimesheet(null);
				setCreateDisabled(false);
			} else {
				setDuplicateTimesheet(duplicateTimesheet);
				setCreateDisabled(true);
			}
		};

		if (!userID || !selectedContractedTo?.id || !allSites[site]) {
			setDuplicateTimesheet(null);
			setCreateDisabled(true);
		} else {
			findDuplicateTimesheet(userID, selectedContractedTo?.id, site);
		}
	}, [
		allSites,
		firebaseApi,
		loading,
		site,
		weekEnding,
		submitTimesheetLoading,
		userID,
		selectedContractedTo?.id,
	]);

	useEffect(() => {
		if (userID === '' || !allSites[site] || !users[userID]) {
			setEntriesAddToDay({
				week: Timestamp.fromDate(startOfWeek(weekEnding)),
				weekEnding: Timestamp.fromDate(weekEnding),
				employerName: users[userID]?.company,
				employerID: users[userID]?.companyID,
				siteCompany: allSites[site]?.company,
				siteCompanyID: allSites[site]?.companyID,
				siteID: allSites[site]?.id,
				siteName: allSites[site]?.name,
				status: timesheetStatus,
				timesheetID: '',
				workerID: userID,
				workerName: users[userID]?.displayName,
				rate: 0,
			});
			return;
		}
		getRates(
			userID,
			allSites[site]?.id,
			users[userID]?.companyID,
			users[userID]?.contractedTo?.id,
			firebaseApi,
		).then((rates) =>
			setEntriesAddToDay({
				week: Timestamp.fromDate(startOfWeek(weekEnding)),
				weekEnding: Timestamp.fromDate(weekEnding),
				employerName: users[userID]?.company,
				employerID: users[userID]?.companyID,
				siteCompany: allSites[site]?.company,
				siteCompanyID: allSites[site]?.companyID,
				siteID: allSites[site]?.id,
				siteName: allSites[site]?.name,
				status: timesheetStatus,
				timesheetID: '',
				workerID: userID,
				workerName: users[userID]?.displayName,
				rate: rates.chargeOutRate,
			}),
		);
	}, [
		site,
		allSites,
		userID,
		users,
		weekEnding,
		timesheetStatus,
		firebaseApi,
	]);

	useEffect(() => {
		if (!site) return;
		firebaseApi.getSite(site).then((site) => {
			if (site) {
				setActivityTypeMap(
					Object.fromEntries(
						site.timesheetActivitiesV2.map((item) => [
							item.id,
							item,
						]),
					),
				);
			}
		});
	}, [firebaseApi, site]);

	useEffect(() => {
		if (userDetails.accountType === '') return;
		let constructionSitesSubscription = emptyFunction;
		if (canChangeContractedTo) {
			constructionSitesSubscription =
				firebaseApi.activeSitesByCompanyTypeSubscription(
					CompanyTypes.construction,
					(sites) =>
						setAllSites((prevState) => ({
							...prevState,
							...sortObjectByField(sites, 'name'),
						})),
				);
		}

		const activeSitesByCompanySubscription =
			firebaseApi.activeSitesByCompanySubscription(
				userDetails.companyID,
				(sites) =>
					setAllSites((prevState) => ({
						...prevState,
						...sortObjectByField(sites, 'name'),
					})),
			);

		return () => {
			constructionSitesSubscription();
			activeSitesByCompanySubscription();
		};
	}, [
		userDetails.companyID,
		userDetails.accountType,
		canChangeContractedTo,
		firebaseApi,
	]);

	const navigateToTimesheet = (
		targetTimesheet: Pick<
			Timesheet,
			'id' | 'weekEnding' | 'timesheetStatus'
		>,
	): void => {
		const params = new URLSearchParams(searchParams);
		params.set(
			'endDate',
			formatSearchParamsDate(targetTimesheet.weekEnding.toDate()),
		);
		params.set('timesheetID', targetTimesheet.id);

		const page =
			targetTimesheet.timesheetStatus === TimesheetStatus.Active
				? 'overview'
				: canActionTimesheets
				? 'review'
				: 'details';

		navigateWithoutRouter(`/timesheets/${page}?${params}`);
	};

	const handleClose = (): void => {
		setShowConfirmationDialog(false);
	};

	const clearUpdates = (): void => {
		setUserID('');
		setContractedToCompany(null);
		setWeekEnding(endOfWeek(new Date()));
		setToAddActivitiesList({});
		setUpdatedWeekActivities(initialWeek);
		setToDeleteActivitiesList([]);
		setTimesheetEntriesErrorMap({});
		setTimesheetFieldsErrorMap({});
		setSite(userDetails.siteID);
	};

	const handleSelectSite = (newSite: string): void => {
		setUpdatedWeekActivities(initialWeek);
		setSite(newSite);
	};
	const handleUpdateWeek = (newWeek: Date | null): void => {
		setTimesheetFieldsErrorMap((prevErrors) => ({
			...prevErrors,
			weekEnding: undefined,
		}));
		setWeekEnding(newWeek ?? endOfWeek(new Date()));
	};

	const handleSelectUser = (user: string): void => {
		if (user !== '') {
			const selectedUser = users[user];
			setUserID(selectedUser.userID);
		}
	};

	const validateTimesheetFields: () => boolean = () => {
		const newErrors: TimesheetFieldErrors = {};
		if (!dateWithinCurrentWeeksRange(weekEnding))
			newErrors.weekEnding = 'Please select a valid week';
		if (userID === '') newErrors.user = 'You must select a user';
		if (site === '') newErrors.site = 'You must select a site';
		if (canChangeContractedTo && !selectedContractedTo)
			newErrors.contractedTo = 'You must select a client';
		if (Object.keys(newErrors).length === 0) {
			return true;
		} else {
			setTimesheetFieldsErrorMap(newErrors);
			return false;
		}
	};

	const handleSubmit = (): void => {
		const entriesValid = validateTimesheetEntries(
			updatedWeekActivities,
			setTimesheetEntriesErrorMap,
		);
		const fieldsValid = validateTimesheetFields();
		if (fieldsValid && entriesValid) {
			setShowConfirmationDialog(true);
		}
	};

	const publishChanges = async (): Promise<void> => {
		if (userID === '' || !users[userID] || !allSites[site]) {
			return;
		}

		setSubmitTimesheetLoading(true);
		let billableTotal = 0;

		const worker = users[userID];
		const timesheetSite = allSites[site];

		const rates = await getRates(
			userID,
			site,
			worker.companyID,
			selectedContractedTo?.id,
			firebaseApi,
		);
		const newTimesheet: Omit<Timesheet, 'id'> = {
			employer: {
				id: worker.companyID,
				name: worker.company,
			},
			invoiceStatus: InvoiceStatuses.Unsent,
			site: {
				company: timesheetSite.company,
				companyID: timesheetSite.companyID,
				id: timesheetSite.id,
				name: timesheetSite.name,
			},
			contractedTo: selectedContractedTo,
			timesheetStatus: timesheetStatus,
			cost: {
				billable: 0,
			},
			employee: {
				id: userID,
				name: worker.displayName,
				paid: false,
				type: worker.workerType,
			},
			week: Timestamp.fromDate(startOfWeek(weekEnding)),
			weekEnding: Timestamp.fromDate(weekEnding),
			contract: rates,
			hours: {
				monday: {
					billable: 0,
					break: 0,
				},
				tuesday: {
					billable: 0,
					break: 0,
				},
				wednesday: {
					billable: 0,
					break: 0,
				},
				thursday: {
					billable: 0,
					break: 0,
				},
				friday: {
					billable: 0,
					break: 0,
				},
				saturday: {
					billable: 0,
					break: 0,
				},
				sunday: {
					billable: 0,
					break: 0,
				},
				total: {
					billable: 0,
					break: 0,
				},
			},
			dateSubmitted: Timestamp.now(),
			reviewedAt: null,
			reviewer: null,
			lastEditedBy: {
				name: userDetails.displayName,
				id: userDetails.userID,
			},
			payrollStatus: TimesheetPayrollStatuses.Unsent,
			projectTrackingStatus: ProjectTrackingStatuses.Unsent,
			workHistoryStatus: WorkHistoryStatus.Unsent,
		};

		Object.entries(updatedWeekActivities).forEach(([day, data]) => {
			const activityDayTotal = Object.values(data.activities).reduce(
				(previous, current) => (previous += current.hours),
				0,
			);

			const field = lowercaseDayString(day as DayString);

			newTimesheet.hours[field].billable = activityDayTotal;
			billableTotal += activityDayTotal;
		});

		newTimesheet.hours.total.billable = billableTotal;

		newTimesheet.cost.billable =
			newTimesheet.contract.chargeOutRate * billableTotal;

		newTimesheet.lastEditedBy = {
			id: userDetails.userID,
			name: userDetails.displayName,
		};

		let activityFinalReviewAt: Activity['finalReviewAt'] = null;
		let activityFinalReviewBy: Activity['finalReviewBy'] = null;
		if (newTimesheet.timesheetStatus === TimesheetStatus.Approved) {
			newTimesheet.reviewedAt = Timestamp.now();
			newTimesheet.reviewer = {
				id: userDetails.userID,
				name: userDetails.displayName,
			};
			activityFinalReviewAt = Timestamp.now();
			activityFinalReviewBy = userDetails.displayName;
		}

		const activityList = Object.values(toAddActivitiesList)
			.filter((activityItem) => activityItem && activityItem?.hours > 0)
			.map((activityItem) => ({
				activity: activityItem.activity,
				date: activityItem.date,
				day: activityItem.day,
				displayDay: activityItem.displayDay,
				employerID: worker.companyID,
				employerName: worker.company,
				finalReviewAt: activityFinalReviewAt,
				finalReviewBy: activityFinalReviewBy,
				hours: activityItem.hours,
				rate: rates.chargeOutRate,
				siteID: site,
				siteName: timesheetSite.name,
				siteCompany: timesheetSite.company,
				siteCompanyID: timesheetSite.companyID,
				status: newTimesheet.timesheetStatus,
				totalCost: activityItem.hours * rates.chargeOutRate,
				week: newTimesheet.week,
				weekEnding: newTimesheet.weekEnding,
				workerID: worker.userID,
				workerName: worker.displayName,
			}));

		await firebaseApi.createTimesheet(newTimesheet, activityList);
		clearUpdates();
		setSubmitTimesheetLoading(false);
		setShowConfirmationDialog(false);
		navigate(`../overview`);
	};

	const goToDulplicateTimesheet = (): void => {
		if (duplicateTimesheet === null) return;
		setSelected(duplicateTimesheet?.id);
		navigateToTimesheet(duplicateTimesheet);
	};

	const ConfirmationDialog = (): JSX.Element => {
		return (
			<Dialog
				onClose={(): void => setShowConfirmationDialog(false)}
				open={showConfirmationDialog}>
				<DialogTitle>
					{`Confirm timesheet submission for 
					${users && userID !== '' ? users[userID]?.displayName : ''}`}
				</DialogTitle>
				<DialogContent>
					<Typography>
						{`Site: 
						${
							site !== '' && Object.values(allSites).length > 0
								? allSites[site]?.name
								: ''
						}`}
					</Typography>
					<Typography>
						{`Client: ${
							selectedContractedTo?.name ?? 'Not Contracted'
						}`}
					</Typography>
					<Typography>{`Week: ${formatSpacedDate(
						weekEnding,
					)}`}</Typography>
					<TimesheetDisplayContentActivities
						isPDF={false}
						weekActivities={updatedWeekActivities}
						isEditing={false}
						totalErrorMap={totalErrorMap}
						updatedWeekActivities={updatedWeekActivities}
						errorMap={timesheetEntriesErrorMap}
						activityTypeMap={activityTypeMap}
						toAddActivitiesList={toAddActivitiesList}
						toDeleteActivitiesList={toDeleteActivitiesList}
						selectedSite={allSites[site]?.id ?? null}
						setToAddActivitiesList={setToAddActivitiesList}
						setUpdatedWeekActivities={setUpdatedWeekActivities}
						setToDeleteActivitiesList={setToDeleteActivitiesList}
						entriesAddToDay={entriesAddToDay}
						setErrorMap={setTimesheetEntriesErrorMap}
						modifyActivitiesDisabled={createDisabled}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleClose}>
						Cancel
					</Button>
					<LoadingButton
						variant="contained"
						onClick={publishChanges}
						disabled={createDisabled}
						loading={submitTimesheetLoading}>
						Confirm
					</LoadingButton>
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<>
			{ConfirmationDialog()}
			{duplicateTimesheet !== null && (
				<Box mb={2}>
					<DuplicateTimesheetAlert
						handleGoToTimesheet={goToDulplicateTimesheet}
					/>
				</Box>
			)}
			<Card>
				<CardContent>
					<Stack spacing={1}>
						<Grid
							container
							spacing={{ xs: 2, md: 3 }}
							justifyContent="center"
							mb={2}>
							<Grid item xs={12} sm={6} md={2.4}>
								<TextField
									value={userID}
									fullWidth
									label="User"
									onChange={(event): void => {
										setTimesheetFieldsErrorMap(
											(prevErrors) => ({
												...prevErrors,
												user: undefined,
											}),
										);
										handleSelectUser(event.target.value);
									}}
									select
									error={!!timesheetFieldsErrorMap.user}
									helperText={timesheetFieldsErrorMap.user}>
									{Object.values(users).map((user) => (
										<MenuItem
											key={user.userID}
											value={user.userID}>
											{user.displayName}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={6} md={2.4}>
								<CompanyAutoComplete
									label="Client"
									recentClientIDs={
										users[userID]?.recentClients?.map(
											(item) => item.id,
										) ?? []
									}
									value={selectedContractedTo?.id ?? null}
									onChange={(contractedToCompany): void => {
										contractedToCompany &&
											setTimesheetFieldsErrorMap(
												(prevErrors) => ({
													...prevErrors,
													contractedTo: undefined,
												}),
											);
										setContractedToCompany(
											contractedToCompany,
										);
									}}
									error={
										!!timesheetFieldsErrorMap.contractedTo
									}
									helperText={
										timesheetFieldsErrorMap.contractedTo
									}
									disabled={
										!canChangeContractedTo ||
										isRecruitmentSite
									}
									firebaseApi={firebaseApi}
									includeUserCompany
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={2.4}>
								<SiteSelectDropdown<
									['Client Sites', 'Other Sites']
								>
									value={allSites[site] ?? null}
									sites={allSites}
									groupBy={
										canChangeContractedTo
											? {
													getGroup: (
														option: Site,
													):
														| 'Client Sites'
														| 'Other Sites' =>
														option.companyID ===
														selectedContractedTo?.id
															? 'Client Sites'
															: 'Other Sites',
													order: [
														'Client Sites',
														'Other Sites',
													],
											  }
											: undefined
									}
									onChange={(_, value): void => {
										setTimesheetFieldsErrorMap(
											(prevErrors) => ({
												...prevErrors,
												site: undefined,
											}),
										);
										handleSelectSite(value?.id ?? '');
									}}
									disabled={isManagement}
									error={!!timesheetFieldsErrorMap.site}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={2.4}>
								<TextField
									value={timesheetStatus}
									fullWidth
									label="Status"
									onChange={(event): void => {
										setTimesheetStatus(
											event.target
												.value as Timesheet['timesheetStatus'],
										);
									}}
									disabled={
										canChangeContractedTo ||
										createApprovedTimesheetDisabled
									}
									select>
									{Object.values(newTimesheetStatuses).map(
										(status) => (
											<MenuItem
												key={status}
												value={status}>
												{status}
											</MenuItem>
										),
									)}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={6} md={2.4}>
								<DateWeekSelector
									onChange={handleUpdateWeek}
									date={weekEnding}
									dateLimits={validTimesheetDateRange(
										new Date(),
									)}
									allowFuture
									weekEnding
									textFieldProps={{
										error: !!timesheetFieldsErrorMap.weekEnding,
										helperText:
											timesheetFieldsErrorMap.weekEnding,
									}}
								/>
							</Grid>
						</Grid>
						<TimesheetDisplayContentActivities
							isPDF={false}
							weekActivities={initialWeek}
							isEditing={true}
							totalErrorMap={totalErrorMap}
							updatedWeekActivities={updatedWeekActivities}
							errorMap={timesheetEntriesErrorMap}
							activityTypeMap={activityTypeMap}
							toAddActivitiesList={toAddActivitiesList}
							toDeleteActivitiesList={toDeleteActivitiesList}
							setToAddActivitiesList={setToAddActivitiesList}
							setUpdatedWeekActivities={setUpdatedWeekActivities}
							setToDeleteActivitiesList={
								setToDeleteActivitiesList
							}
							selectedSite={allSites[site]?.id ?? null}
							entriesAddToDay={entriesAddToDay}
							setErrorMap={setTimesheetEntriesErrorMap}
							modifyActivitiesDisabled={createDisabled}
						/>
						<Stack
							display="flex"
							justifyContent="center"
							alignItems="center"
							spacing={2}
							direction="row">
							<Button
								variant="outlined"
								onClick={(): void => clearUpdates()}>
								clear
							</Button>
							<LoadingButton
								variant="contained"
								onClick={handleSubmit}
								loading={loading}
								disabled={
									createDisabled ||
									!timesheetHasHours(updatedWeekActivities)
								}>
								submit
							</LoadingButton>
						</Stack>
					</Stack>
				</CardContent>
			</Card>
		</>
	);
};
