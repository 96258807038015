import {
	Activity,
	MinimalInterface,
	Site,
	UserDetails,
} from '../constants/Common';
import { ActivityOption } from '../constants/ProjectTrackingExport';
import { Timesheet } from '../constants/Timesheet/Timesheet';
import type { User } from '../firebase/firebase';
import { IntegrationElement } from '../models/Integrations/IntegrationElements';
import {
	ManualProjectTrackingIntegration,
	ProjectTrackingType,
	isManualProjectTrackingIntegration,
} from '../models/Integrations/ProjectTrackingIntegration';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequest,
	makeCloudFunctionRequestDeprecated,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type ProjectTrackingTokensRequestBody =
	| {
			token: string;
			projectTrackingType: 'Workbench';
			baseURL: string;
	  }
	| {
			projectTrackingType: 'CATProjects';
	  };

async function fetchProjectTrackingTokens(
	abortSignal: AbortSignal,
	user: User,
	type: ManualProjectTrackingIntegration,
): Promise<boolean | undefined>;
async function fetchProjectTrackingTokens(
	abortSignal: AbortSignal,
	user: User,
	type: Exclude<ProjectTrackingType, ManualProjectTrackingIntegration>,
	url: string,
	code: string,
): Promise<boolean | undefined>;
async function fetchProjectTrackingTokens(
	abortSignal: AbortSignal,
	user: User,
	type: ProjectTrackingType,
	url?: string,
	code?: string,
): Promise<boolean | undefined> {
	let body: ProjectTrackingTokensRequestBody;

	if (!isManualProjectTrackingIntegration(type) && code && url) {
		body = {
			token: code,
			projectTrackingType: type,
			baseURL: url,
		};
	} else if (isManualProjectTrackingIntegration(type)) {
		body = { projectTrackingType: type };
	} else {
		// this should be typesafe, but isn't checkable, because of the way overlaods work
		throw new Error(
			`Invalid code <${code}>, url <${url}> or type <${type}>`,
		);
	}

	const response =
		await makeCloudFunctionRequestReturnSuccessStatus<ProjectTrackingTokensRequestBody>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.ProjectTrackingTokens,
				method: 'POST',
				body,
				services: defaultCloudFunctionServices,
			},
		);

	return response;
}

export type ProjectTrackingEmployee = MinimalInterface;
type ProjectTrackingEmployeeIDsResponse = {
	employees: ProjectTrackingEmployee[];
};

const fetchProjectTrackingEmployeeIDs = async (
	abortSignal: AbortSignal,
	user: User,
): Promise<ProjectTrackingEmployeeIDsResponse> => {
	const response =
		await makeCloudFunctionRequest<ProjectTrackingEmployeeIDsResponse>({
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.ProjectTrackingEmployeeIDs,
			method: 'GET',
			services: defaultCloudFunctionServices,
		});

	return response;
};

export type ProjectTrackingSite = IntegrationElement;
type ProjectTrackingSiteIDsResponse = {
	projects: ProjectTrackingSite[];
};
const fetchProjectTrackingSiteIDs = async (
	abortSignal: AbortSignal,
	user: User,
): Promise<ProjectTrackingSiteIDsResponse> => {
	const response =
		await makeCloudFunctionRequest<ProjectTrackingSiteIDsResponse>({
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.ProjectTrackingProjectIDs,
			method: 'GET',
			services: defaultCloudFunctionServices,
		});

	return response;
};

export type ProjectTrackingTaskCode = IntegrationElement;
export type ProjectTrackingCostCode = IntegrationElement;
export type ProjectTrackingCostingRecords = {
	[jobID: string]: ProjectTrackingCosting;
};
export type ProjectTrackingCosting = {
	tasks: ProjectTrackingTaskCode[];
	costCodes: ProjectTrackingCostCode[];
};

type ProjectTrackingCostingIDsResponse = {
	projects: ProjectTrackingCostingRecords;
};

const fetchProjectTrackingCostingIDs = async (
	abortSignal: AbortSignal,
	user: User,
): Promise<ProjectTrackingCostingIDsResponse> => {
	const response =
		await makeCloudFunctionRequest<ProjectTrackingCostingIDsResponse>({
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.ProjectTrackingCostingIDs,
			method: 'GET',
			services: defaultCloudFunctionServices,
		});

	return response;
};

type ProjectTrackingExportRequest = {
	weekEnding: string;
	activitiesFilter: ActivityOption;
	siteOrAllSites: string;
};

type ProjectTrackingExportResponse = {
	fileLocation: string;
	errors?: ExportErrors[];
};

export enum ErrorType {
	EmployeeLink = 'EmployeeLink',
	ActivityLink = 'ActivityLink',
	ProjectLink = 'ProjectLink',
}

type EmployeeExportError = {
	id: Timesheet['id'];
	type: ErrorType.EmployeeLink;
	name: UserDetails['displayName'];
};

type ActivityExportError = {
	id: Timesheet['id'];
	type: ErrorType.ActivityLink;
	name: Activity['activity']['name'];
};

type ProjectExportError = {
	id: Timesheet['id'];
	type: ErrorType.ProjectLink;
	name: Site['name'];
};

export type ExportErrors =
	| EmployeeExportError
	| ActivityExportError
	| ProjectExportError;

const generateProjectTrackingExport = async (
	abortSignal: AbortSignal,
	user: User,
	request: ProjectTrackingExportRequest,
): Promise<ProjectTrackingExportResponse | undefined> => {
	const response = await makeCloudFunctionRequestDeprecated<
		ProjectTrackingExportResponse,
		ProjectTrackingExportRequest
	>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.ProjectTrackingExport,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: request,
	});

	return response;
};

export const projectTracking = {
	fetchProjectTrackingTokens,
	fetchProjectTrackingEmployeeIDs,
	fetchProjectTrackingSiteIDs,
	fetchProjectTrackingCostingIDs,
	generateProjectTrackingExport,
};
