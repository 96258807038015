import MailIcon from '@mui/icons-material/Mail';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { Tooltip } from '@mui/material';

export type UserInviteSeenIconProps = {
	isSeen: boolean;
};

export const UserInviteSeenIcon = ({
	isSeen,
}: UserInviteSeenIconProps): JSX.Element => {
	return (
		<Tooltip title={isSeen ? 'Seen' : 'Unseen'}>
			{isSeen ? (
				<MarkEmailReadIcon color="success" />
			) : (
				<MailIcon color="neutral" />
			)}
		</Tooltip>
	);
};
