import {
	DatePicker,
	LocalizationProvider as MUILocalization,
	PickersDay,
	TimePicker,
	PickersDayProps,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
	WrapperVariantContext,
	useLocaleText,
} from '@mui/x-date-pickers/internals';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { setDefaultOptions } from 'date-fns';
import { createContext, useContext, useEffect } from 'react';
import options from '../constants/DatePickerConfig';

type LocalizationProviderProps = {
	children: React.ReactNode;
};

export const LocalizationContext = createContext<typeof options>(options);
export const LocalizationProvider = ({
	children,
}: LocalizationProviderProps): JSX.Element => {
	useEffect(() => {
		setDefaultOptions(options);
	}, []);

	return (
		<LocalizationContext.Provider value={options}>
			<MUILocalization dateAdapter={AdapterDateFns}>
				{children}
			</MUILocalization>
		</LocalizationContext.Provider>
	);
};

export const useLocalizationContext = (): typeof options => {
	return useContext(LocalizationContext);
};

export {
	DatePicker,
	PickersDay,
	TimePicker,
	WrapperVariantContext,
	useLocaleText,
};
export type { PickersActionBarProps, PickersDayProps };
