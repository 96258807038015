import { Box, Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, {
	ReactNode,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	Navigate,
	Outlet,
	Route,
	RouteProps,
	Routes,
	matchPath,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import ContactUsDisplay from '../components/DefaultPages/ContactUsDisplay';
import { PageNotFound } from '../components/DefaultPages/PageNotFound';
import PrivacyPolicyScreen from '../components/DefaultPages/PrivacyPolicyScreen';
import TermsAndConditions from '../components/DefaultPages/TermsAndConditions';
import { AccountInformation } from '../components/LoginAndSignUp/AccountInformation';
import { AwaitingApproval } from '../components/LoginAndSignUp/AwaitingApproval';
import {
	CompanyInformation,
	NewUserCompanyInfo,
} from '../components/LoginAndSignUp/CompanyInformation';
import { NewUserInfo } from '../components/LoginAndSignUp/NewUserInfo';
import { SignInUp } from '../components/LoginAndSignUp/SignInUp';
import { SubscriptionSetup } from '../components/LoginAndSignUp/SubscriptionSetup';
import { VerifyEmail } from '../components/LoginAndSignUp/VerifyEmail';
import { SubscriptionControlPage } from '../components/SubscriptionControl/SubscriptionControlPage';
import VersionControlPage from '../components/VersionControl/VersionControlPage';
import { BrowserClient } from '../constants/BrowerClient';
import {
	UserDetails,
	appDrawerWidth,
	appHeaderHeight,
	emptyFunction,
} from '../constants/Common';
import { OperatingSystem } from '../constants/OperatingSystem';
import { Page, defaultPageConfig } from '../constants/PageConfig';
import { Auth, User } from '../firebase/firebase';
import firebaseApi from '../firebase/firebaseApi';
import { useRerenderHandler } from '../hooks/useRerenderHandler';
import { useCompanySubscriptionContext } from '../providers/CompanySubscriptionProvider';
import { useFeatureFlagContext } from '../providers/featureFlags/Provider';
import { usePageConfigContext } from '../providers/pageConfig/context';
import {
	useUserAuthContext,
	useUserDetailsContext,
	useUserLoadedContext,
} from '../providers/UserProvider';
import Bird from './../images/birdonlyblack-transparentbackground.png';
import Header from './Header';
import { getNavigationPath } from './navigation';
import PageContainer from './PageContainer';
import Sidebar from './Sidebar';

type MainProps = {
	open?: boolean;
	children?: ReactNode;
};

export type ProtectedRouteProps = {
	component: React.ElementType;
	user: User | null;
	userDetails: UserDetails | null;
	type?: string;
} & RouteProps;

const Main = (props: MainProps): JSX.Element => {
	const { open, children } = props;
	return (
		<Box
			component="main"
			sx={{
				flexGrow: 1,
				position: 'fixed',
				marginLeft: 0,
				marginTop: `${appHeaderHeight}px`,
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				...(open && {
					marginLeft: `${appDrawerWidth}px`,
				}),
			}}>
			{children}
		</Box>
	);
};

export const ProtectedRoute = ({
	component: Component,
	...routeProps
}: ProtectedRouteProps): JSX.Element => {
	return <Component {...routeProps} />;
};

const RequireAuth = ({
	children,
	requirements,
}: {
	children: JSX.Element;
	requirements: string[];
}): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const location = useLocation();
	if (
		userDetails &&
		(requirements.length === 0 ||
			requirements.includes(userDetails.accountType))
	) {
		return children;
	} else if (location.pathname.split('/').length > 1) {
		// This will only ever show up when a user navigates to a VALID nested page that they DON'T have access to
		// e.g. manager-> /timesheets/xyz ❌as/timesheets/xyz does not exist
		// e.g. manager-> /abc/xyz ❌ if the literal path in PageConfig.ts is "/abc/xyz"
		// e.g. manager-> /timesheets/details ✔️ as/timesheets/details does exist, but is only allowed for handlers

		return <Navigate to="/404" state={{ loading: false }} />;
	} else {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/sign-in" state={{ from: location }} />;
	}
};

const auth = Auth();

const Application = (): JSX.Element => {
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const userAuth = useUserAuthContext();
	const userDetails = useUserDetailsContext();
	const userLoaded = useUserLoadedContext();
	const pages = usePageConfigContext();
	const featureFlags = useFeatureFlagContext();
	const companySub = useCompanySubscriptionContext();

	const appRef = useRef<HTMLDivElement | null>();
	const drawerPermanent = useMediaQuery(theme.breakpoints.up('sm'));

	const [openDrawer, setOpenDrawer] = useState(true);
	const [liveVersion, setLiveVersion] = useState<string | null>(null);
	const [returnUrl, setReturnUrl] = useState('');
	const [operatingSystem, setOperatingSystem] = useState(
		OperatingSystem.Unknown,
	);
	const [browserClient, setBrowserClient] = useState(BrowserClient.Unknown);
	const [newUserAndCompanyInfo, setNewUserAndCompanyInfo] = useState<
		NewUserInfo & NewUserCompanyInfo
	>({
		firstName: '',
		lastName: '',
		mobileNumber: '',
		companyID: '',
		siteID: '',
		companyType: '',
	});
	const [accountInformationProvided, setAccountInformationProvided] =
		useState<boolean>(false);

	const flagsLoaded = featureFlags.loaded;

	const versionControlEnabled: boolean = featureFlags.get('versionControl');
	const currentVersion = process.env.REACT_APP_VERSION ?? null;

	useRerenderHandler();

	useEffect(() => {
		checkBrowserAndOS();
	}, []);

	useEffect(() => {
		if (userAuth) {
			const versionListener = firebaseApi.versionSubscription(
				(version) => {
					if (version !== undefined) {
						setLiveVersion(version.webVersion);
					} else {
						setLiveVersion(null);
					}
				},
			);
			return versionListener;
		}
		return emptyFunction;
	}, [userAuth]);

	useEffect(() => {
		const navigationPath = getNavigationPath(
			userDetails,
			userAuth,
			companySub,
			flagsLoaded,
			userLoaded,
			returnUrl,
			setReturnUrl,
			location,
		);

		if (navigationPath && location.pathname !== navigationPath) {
			navigate(navigationPath);
		}
	}, [
		companySub,
		flagsLoaded,
		location,
		navigate,
		userAuth,
		userDetails,
		userLoaded,
		returnUrl,
	]);

	useEffect(() => {
		appRef.current?.scrollIntoView({ block: 'start' });
	}, [navigate]);

	useEffect(() => {
		if (userDetails !== null) {
			setNewUserAndCompanyInfo({
				firstName: userDetails.firstname,
				lastName: userDetails.lastname,
				mobileNumber: userDetails.mobileNumber,
				companyID: userDetails.companyID,
				siteID: userDetails.siteID,
				companyType: '',
			});
			setAccountInformationProvided(true);
		}
	}, [userDetails]);

	const checkBrowserAndOS = (): void => {
		let osName: OperatingSystem = OperatingSystem.Unknown;
		let browserName: BrowserClient = BrowserClient.Unknown;

		if (navigator.userAgent.indexOf('Win') !== -1)
			osName = OperatingSystem.Windows;
		if (navigator.userAgent.indexOf('Mac') !== -1)
			osName = OperatingSystem.Mac;
		if (navigator.userAgent.indexOf('Linux') !== -1)
			osName = OperatingSystem.Linux;
		if (navigator.userAgent.indexOf('Android') !== -1)
			osName = OperatingSystem.Android;
		if (navigator.userAgent.indexOf('like Mac') !== -1)
			osName = OperatingSystem.iOS;

		if (navigator.userAgent.match(/chrome|chromium|crios/i)) {
			browserName = BrowserClient.Chrome;
		} else if (navigator.userAgent.match(/firefox|fxios/i)) {
			browserName = BrowserClient.Firefox;
		} else if (navigator.userAgent.match(/safari/i)) {
			browserName = BrowserClient.Safari;
		} else if (navigator.userAgent.match(/opr\//i)) {
			browserName = BrowserClient.Opera;
		} else if (navigator.userAgent.match(/edg/i)) {
			browserName = BrowserClient.Edge;
		}

		setOperatingSystem(osName);
		setBrowserClient(browserName);
	};

	const onSignIn = (): void => {
		navigate('/sign-in', { state: location });
	};
	const onSignUp = (): void => {
		navigate('/sign-up', { state: location });
	};

	const onSignOut = (): void => {
		auth.signOut().then(() => {
			setNewUserAndCompanyInfo({
				firstName: '',
				lastName: '',
				mobileNumber: '',
				companyID: '',
				siteID: '',
				companyType: '',
			});
			navigate('/sign-in');
		});
	};

	const handleDrawerOpen = (): void => setOpenDrawer(true);

	const handleDrawerClose = (): void => setOpenDrawer(false);

	const dashboardNavigate = useCallback(() => {
		const navigationPath = getNavigationPath(
			userDetails,
			userAuth,
			companySub,
			flagsLoaded,
			userLoaded,
			returnUrl,
			setReturnUrl,
			location,
			true,
		);

		if (navigationPath && location.pathname !== navigationPath) {
			navigate(navigationPath);
		}
	}, [
		companySub,
		returnUrl,
		flagsLoaded,
		location,
		navigate,
		userAuth,
		userDetails,
		userLoaded,
	]);

	const accountSettingsNavigate = useCallback(() => {
		if (
			companySub.subscriptionControlEnabled &&
			companySub.isLoaded &&
			!companySub.isSubscribed
		) {
			navigate('/subscription-control');
		} else {
			navigate('/account-settings');
		}
	}, [
		companySub.isLoaded,
		companySub.isSubscribed,
		navigate,
		companySub.subscriptionControlEnabled,
	]);

	const renderHeader = (showDrawer: boolean): JSX.Element => (
		<Header
			showSignIn={userAuth === null}
			showSignOut={userAuth !== null}
			onSignIn={onSignIn}
			onSignOut={onSignOut}
			onSignUp={onSignUp}
			open={openDrawer && drawerPermanent}
			handleDrawerOpen={handleDrawerOpen}
			showDrawer={showDrawer}
			navigateToDashboard={dashboardNavigate}
			navigateToAccountSettings={accountSettingsNavigate}
			currentPageConfig={[...pages, ...defaultPageConfig].find((item) =>
				matchPath(`${item.path}/*`, location.pathname),
			)}
			firebaseApi={firebaseApi}
		/>
	);

	const renderDefaultRoute = (): JSX.Element[] =>
		defaultPageConfig
			.filter(({ element, renderRoute }) => element && renderRoute)
			.map(({ element: Element, path }) => (
				<Route
					key={path}
					path={path}
					element={<Element renderHeader={renderHeader} />}
				/>
			));

	const recurseMapRoute = (
		page: Pick<
			Page,
			'path' | 'element' | 'nestedPages' | 'accountRequirements' | 'name'
		>,
	): JSX.Element => (
		<Route
			key={`routes-${page.path}`}
			path={page.path}
			element={
				<RequireAuth requirements={page.accountRequirements}>
					<PageContainer header={page.name}>
						<ProtectedRoute
							path={page.path}
							component={page.element}
							user={userAuth}
							userDetails={userDetails}
						/>
					</PageContainer>
				</RequireAuth>
			}>
			{page.nestedPages && page.nestedPages.map(recurseMapRoute)}
		</Route>
	);

	const renderRoutes = (): ReactNode => pages.map(recurseMapRoute);

	const renderChrome = (): JSX.Element => (
		<>
			{renderHeader(true)}
			<Sidebar
				open={openDrawer}
				drawerWidth={appDrawerWidth}
				handleDrawerClose={handleDrawerClose}
				drawerPermanent={drawerPermanent}
				pageConfig={pages}
				userDetails={userDetails}
			/>
		</>
	);

	const renderWrapper = (): JSX.Element => (
		<Box
			sx={{
				height: '100vh',
				'::before': {
					content: "' '",
					display: 'block',
					position: 'absolute',
					right: '0',
					bottom: '0',
					width: '100%',
					height: '100vh',
					opacity: '0.1',
					backgroundImage: `url(${Bird})`,
					backgroundRepeat: 'no-repeat',
					backgroundPosition: '100% 100%',
					zIndex: '-1',
				},
			}}>
			{renderChrome()}
			<Main open={openDrawer && drawerPermanent}>
				<Container
					maxWidth={false}
					sx={{
						overflow: 'auto',
						height: '100%',
						width: '100%',
					}}>
					<Outlet />
				</Container>
			</Main>
		</Box>
	);

	const handleUpdateUserInfo = useCallback(
		(newUserInfo: NewUserInfo | NewUserCompanyInfo): void =>
			setNewUserAndCompanyInfo((prev) => ({
				...prev,
				...newUserInfo,
			})),
		[],
	);

	return (
		<Box sx={{ height: '100%', width: '100%' }} ref={appRef}>
			<Routes>
				<Route path="/" element={<Navigate to="/sign-in" replace />} />
				<Route
					path="/sign-in"
					element={
						<SignInUp authState="signIn" onSignOut={onSignOut} />
					}
				/>
				<Route
					path="/sign-up"
					element={
						<SignInUp authState="signUp" onSignOut={onSignOut} />
					}
				/>
				<Route
					path="/verify-email"
					element={<VerifyEmail onSignOut={onSignOut} />}
				/>
				<Route
					path="/account-information"
					element={
						<AccountInformation
							setNewUserInfo={handleUpdateUserInfo}
							newUserInfo={newUserAndCompanyInfo}
							setAccountInformationProvided={
								setAccountInformationProvided
							}
							onSignOut={onSignOut}
						/>
					}
				/>
				<Route
					path="/company-information"
					element={
						<CompanyInformation
							setNewUserCompanyInfo={handleUpdateUserInfo}
							newUserAndCompanyInfo={newUserAndCompanyInfo}
							accountInformationProvided={
								accountInformationProvided
							}
							subscriptionEnabled={
								companySub.subscriptionControlEnabled
							}
							firebaseApi={firebaseApi}
							onSignOut={onSignOut}
						/>
					}
				/>
				<Route
					path="/subscription-setup"
					element={<SubscriptionSetup company={companySub.company} />}
				/>
				<Route
					path="/awaiting-approval"
					element={<AwaitingApproval onSignOut={onSignOut} />}
				/>
				<Route
					path="/contact-us"
					element={<ContactUsDisplay renderHeader={renderHeader} />}
				/>
				<Route
					path="/privacy-policy"
					element={
						<PrivacyPolicyScreen renderHeader={renderHeader} />
					}
				/>
				<Route
					path="/terms-and-conditions"
					element={<TermsAndConditions renderHeader={renderHeader} />}
				/>
				{userDetails && companySub.isLoaded && (
					<Route
						path="/subscription-control"
						element={
							<SubscriptionControlPage
								returnUrl={
									returnUrl
										? returnUrl
										: window.location.origin
								}
								company={companySub.company}
								renderHeader={renderHeader}
							/>
						}
					/>
				)}
				{versionControlEnabled &&
				liveVersion !== null &&
				liveVersion !== currentVersion ? (
					<Route
						path="/*"
						element={
							<VersionControlPage
								currentVersion={currentVersion}
								liveVersion={liveVersion}
								operatingSystem={operatingSystem}
								browserClient={browserClient}
							/>
						}
					/>
				) : (
					<Route element={renderWrapper()}>
						{renderDefaultRoute()}
						{flagsLoaded &&
							companySub.isSubscribed &&
							renderRoutes()}
					</Route>
				)}
				{/* Always last */}
				<Route element={renderWrapper()}>
					<Route
						path="*"
						element={
							<PageContainer header="Page Not Found">
								<PageNotFound />
							</PageContainer>
						}
					/>
				</Route>
			</Routes>
		</Box>
	);
};

export default Application;
