import { Site } from '../../../constants/Common';
import { Action } from '../../../constants/TypescriptUtilities';
import { UserInvite } from '../../../constants/UserInvite';
import { CreateUserInviteStatuses } from './CreateUserInviteSnackBar';

type CreateUserInviteDialogAction<
	T extends CreateUserInviteDialogActionTypes,
	P extends object | void = void,
> = Action<T, P>;

export type CreateStateBase = {
	firstName: UserInvite['firstName'];
	lastName: UserInvite['lastName'];
	email: UserInvite['email'];
	accountType: UserInvite['accountType'];
	mobileNumber: UserInvite['mobileNumber'];
	site: Site | null;
	contractedTo: UserInvite['contractedTo'];
	createAnother: boolean;
	createUserInviteStatus: CreateUserInviteStatuses;
	errors: {
		firstName?: string;
		lastName?: string;
		email?: string;
		mobileNumber?: string;
	};
};

export type CreateState = CreateStateBase & {
	initialState: CreateStateBase;
};

export const enum CreateUserInviteDialogActionTypes {
	UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME',
	UPDATE_LAST_NAME = 'UPDATE_LAST_NAME',
	UPDATE_EMAIL = 'UPDATE_EMAIL',
	UPDATE_ACCOUNT_TYPE = 'UPDATE_ACCOUNT_TYPE',
	UPDATE_MOBILE_NUMBER = 'UPDATE_MOBILE_NUMBER',
	UPDATE_SITE = 'UPDATE_SITE',
	UPDATE_CONTRACTED_TO = 'UPDATE_CONTRACTED_TO',
	UPDATE_CREATE_ANOTHER = 'UPDATE_CREATE_ANOTHER',
	UPDATE_ERRORS = 'UPDATE_ERRORS',
	SET_INITIAL_STATE = 'SET_INITIAL_STATE',
	UPDATE_USER_INVITE_STATUS = 'UPDATE_CREATE_USER_INVITE_STATUS',
}

type UpdateFirstNameAction = CreateUserInviteDialogAction<
	CreateUserInviteDialogActionTypes.UPDATE_FIRST_NAME,
	{ firstName: CreateState['firstName'] }
>;

type UpdateLastNameAction = CreateUserInviteDialogAction<
	CreateUserInviteDialogActionTypes.UPDATE_LAST_NAME,
	{ lastName: CreateState['lastName'] }
>;

type UpdateEmailAction = CreateUserInviteDialogAction<
	CreateUserInviteDialogActionTypes.UPDATE_EMAIL,
	{ email: CreateState['email'] }
>;

type UpdateAccountTypeAction = CreateUserInviteDialogAction<
	CreateUserInviteDialogActionTypes.UPDATE_ACCOUNT_TYPE,
	{ accountType: CreateState['accountType'] }
>;

type UpdateMobileNumberAction = CreateUserInviteDialogAction<
	CreateUserInviteDialogActionTypes.UPDATE_MOBILE_NUMBER,
	{ mobileNumber: CreateState['mobileNumber'] }
>;

type UpdateSiteAction = CreateUserInviteDialogAction<
	CreateUserInviteDialogActionTypes.UPDATE_SITE,
	{ site: CreateState['site'] }
>;

type UpdateContractedToAction = CreateUserInviteDialogAction<
	CreateUserInviteDialogActionTypes.UPDATE_CONTRACTED_TO,
	{ contractedTo: CreateState['contractedTo'] }
>;

type UpdateCreateAnotherAction = CreateUserInviteDialogAction<
	CreateUserInviteDialogActionTypes.UPDATE_CREATE_ANOTHER,
	{ createAnother: CreateState['createAnother'] }
>;

type SetErrorsAction = CreateUserInviteDialogAction<
	CreateUserInviteDialogActionTypes.UPDATE_ERRORS,
	{
		errors: Partial<CreateState['errors']>;
	}
>;

type SetInitialStateAction = CreateUserInviteDialogAction<
	CreateUserInviteDialogActionTypes.SET_INITIAL_STATE,
	{ newState?: Partial<CreateState> }
>;

type SetCreateUserInviteStatus = CreateUserInviteDialogAction<
	CreateUserInviteDialogActionTypes.UPDATE_USER_INVITE_STATUS,
	{
		createUserInviteStatus: CreateState['createUserInviteStatus'];
	}
>;

export type CreateUserInviteDialogActions =
	| UpdateFirstNameAction
	| UpdateLastNameAction
	| UpdateEmailAction
	| UpdateAccountTypeAction
	| UpdateMobileNumberAction
	| UpdateSiteAction
	| UpdateContractedToAction
	| UpdateCreateAnotherAction
	| SetErrorsAction
	| SetInitialStateAction
	| SetCreateUserInviteStatus;

export const updateFirstName = (
	firstName: CreateState['firstName'],
): UpdateFirstNameAction => ({
	type: CreateUserInviteDialogActionTypes.UPDATE_FIRST_NAME,
	payload: { firstName },
});

export const updateLastName = (
	lastName: CreateState['lastName'],
): UpdateLastNameAction => ({
	type: CreateUserInviteDialogActionTypes.UPDATE_LAST_NAME,
	payload: { lastName },
});

export const updateEmail = (
	email: CreateState['email'],
): UpdateEmailAction => ({
	type: CreateUserInviteDialogActionTypes.UPDATE_EMAIL,
	payload: { email },
});

export const updateAccountType = (
	accountType: CreateState['accountType'],
): UpdateAccountTypeAction => ({
	type: CreateUserInviteDialogActionTypes.UPDATE_ACCOUNT_TYPE,
	payload: { accountType },
});

export const updateMobileNumber = (
	mobileNumber: CreateState['mobileNumber'],
): UpdateMobileNumberAction => ({
	type: CreateUserInviteDialogActionTypes.UPDATE_MOBILE_NUMBER,
	payload: { mobileNumber },
});

export const updateSite = (site: CreateState['site']): UpdateSiteAction => ({
	type: CreateUserInviteDialogActionTypes.UPDATE_SITE,
	payload: { site },
});

export const updateContractedTo = (
	contractedTo: CreateState['contractedTo'],
): UpdateContractedToAction => ({
	type: CreateUserInviteDialogActionTypes.UPDATE_CONTRACTED_TO,
	payload: { contractedTo },
});

export const updateCreateAnother = (
	createAnother: CreateState['createAnother'],
): UpdateCreateAnotherAction => ({
	type: CreateUserInviteDialogActionTypes.UPDATE_CREATE_ANOTHER,
	payload: { createAnother },
});

export const updateErrors = (
	errors: CreateState['errors'],
): SetErrorsAction => ({
	type: CreateUserInviteDialogActionTypes.UPDATE_ERRORS,
	payload: { errors },
});

export const updateUserInviteStatus = (
	createUserInviteStatus: CreateState['createUserInviteStatus'],
): SetCreateUserInviteStatus => ({
	type: CreateUserInviteDialogActionTypes.UPDATE_USER_INVITE_STATUS,
	payload: { createUserInviteStatus },
});

export const setInitialState = (
	newState?: Partial<CreateState>,
): SetInitialStateAction => ({
	type: CreateUserInviteDialogActionTypes.SET_INITIAL_STATE,
	payload: { newState },
});
