import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import cloudFunctionApi from '../../cloudfunctions';
import {
	Company,
	stripeBillingStatus,
	StripeGetCustomerOutstandingPaymentsResponse,
	SubscriptionStatuses,
} from '../../constants/Common';
import type { User } from '../../firebase/firebase';
import { useAbortController } from '../../hooks/useAbortController';
import { CustomSnackBar } from '../SnackBar/SnackBar';
import { OutstandingPaymentsModal } from './OutstandingPaymentsModal';

type ManageSubscriptionButtonProps =
	| CreateCustomerAndSubscription
	| CreateSubscription
	| ManageSubscription
	| RestartCancelledSubscription;

type CreateCustomerAndSubscription = {
	subscriptionStatus: undefined;
	user: User;
	companyID: string;
	companyName: string;
	successUrl: string;
	cancelUrl: string;
	fullWidth?: boolean;
};

type CreateSubscription = {
	subscriptionStatus: typeof SubscriptionStatuses.Incomplete;
	user: User;
	successUrl: string;
	cancelUrl: string;
	freeTrial: boolean;
	fullWidth?: boolean;
};

type ManageSubscription = {
	subscriptionStatus: Exclude<
		(typeof SubscriptionStatuses)[keyof typeof SubscriptionStatuses],
		| typeof SubscriptionStatuses.Incomplete
		| typeof SubscriptionStatuses.Canceled
	>;
	user: User;
	returnUrl: string;
	fullWidth?: boolean;
};

type RestartCancelledSubscription = {
	subscriptionStatus: typeof SubscriptionStatuses.Canceled;
	user: User;
	successUrl: string;
	cancelUrl: string;
	fullWidth?: boolean;
};

const ManageSubscriptionButton = ({
	fullWidth = false,
	...props
}: ManageSubscriptionButtonProps): JSX.Element => {
	const defaultOutstandingPayments = {
		status: 'Outstanding' as const,
		unpaidInvoicesCount: 0,
		totalCost: 0,
		unpaidInvoices: [],
	};

	const abortSignal = useAbortController();
	const [fetching, setFetching] = useState(false);
	const [paymentsModalOpen, setPaymentsModalOpen] = useState(false);
	const [customerOutstandingPayments, setCustomerOutstandingPayments] =
		useState<
			Extract<
				StripeGetCustomerOutstandingPaymentsResponse,
				{ status: typeof stripeBillingStatus.Outstanding }
			>
		>(defaultOutstandingPayments);
	const [snackBarOpen, setSnackBarOpen] = useState(false);

	let buttonLabel = 'Start Subscription';
	let manageSubscription: () => Promise<void>;
	if (props.subscriptionStatus === undefined) {
		manageSubscription = async (): Promise<void> => {
			await cloudFunctionApi.fetchNewStripeCustomer(
				abortSignal,
				props.user,
				props.companyID,
				props.companyName,
			);
			const checkoutLink = await cloudFunctionApi.fetchCheckoutPortal(
				abortSignal,
				props.user,
				props.successUrl,
				props.cancelUrl,
				true,
			);
			if (!abortSignal.aborted) {
				if (!checkoutLink) {
					setSnackBarOpen(true);
				} else {
					window.location.replace(checkoutLink);
				}
				setFetching(false);
			}
		};
	} else if (props.subscriptionStatus === SubscriptionStatuses.Incomplete) {
		manageSubscription = async (): Promise<void> => {
			const checkoutLink = await cloudFunctionApi.fetchCheckoutPortal(
				abortSignal,
				props.user,
				props.successUrl,
				props.cancelUrl,
				props.freeTrial,
			);
			if (!abortSignal.aborted) {
				if (!checkoutLink) {
					setSnackBarOpen(true);
				} else {
					window.location.replace(checkoutLink);
				}
				setFetching(false);
			}
		};
	} else if (props.subscriptionStatus === SubscriptionStatuses.Canceled) {
		buttonLabel = 'Renew Subscription';
		manageSubscription = async (): Promise<void> => {
			const customerOutstandingPayments =
				await cloudFunctionApi.fetchCustomerOutstandingPayments(
					abortSignal,
					props.user,
				);
			if (!abortSignal.aborted) {
				if (!customerOutstandingPayments) {
					setSnackBarOpen(true);
					setFetching(false);
				} else if (
					customerOutstandingPayments.status ===
					stripeBillingStatus.Paid
				) {
					const checkoutLink =
						await cloudFunctionApi.fetchCheckoutPortal(
							abortSignal,
							props.user,
							props.successUrl,
							props.cancelUrl,
							false,
						);
					if (!abortSignal.aborted) {
						if (!checkoutLink) {
							setSnackBarOpen(true);
						} else {
							window.location.replace(checkoutLink);
						}
						setFetching(false);
					}
				} else {
					setCustomerOutstandingPayments(customerOutstandingPayments);
					setPaymentsModalOpen(true);
				}
			}
		};
	} else {
		buttonLabel = 'Manage Subscription';
		manageSubscription = async (): Promise<void> => {
			const customerPortalLink =
				await cloudFunctionApi.fetchCustomerPortal(
					abortSignal,
					props.user,
					props.returnUrl,
				);
			if (!abortSignal.aborted) {
				if (!customerPortalLink) {
					setSnackBarOpen(true);
				} else {
					window.location.replace(customerPortalLink);
				}
				setFetching(false);
			}
		};
	}

	return (
		<>
			<LoadingButton
				variant="contained"
				onClick={(): void => {
					setFetching(true);
					manageSubscription();
				}}
				endIcon={!fetching && <OpenInNewIcon />}
				disabled={fetching}
				loading={fetching}
				fullWidth={fullWidth}>
				{buttonLabel}
			</LoadingButton>
			<OutstandingPaymentsModal
				modalOpen={paymentsModalOpen}
				onClose={(): void => {
					setCustomerOutstandingPayments(defaultOutstandingPayments);
					setPaymentsModalOpen(false);
				}}
				outstandingPayments={customerOutstandingPayments}
			/>
			<CustomSnackBar
				open={snackBarOpen}
				onClose={(): void => setSnackBarOpen(false)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				snackBarTitle="Failed to open Stripe Subscription Manager."
				snackBarText="Please try again, or contact Trade Legion if the problem persists."
				severity="error"
			/>
		</>
	);
};

/**
 * This is how the above button should always get created.
 *
 * It's more verbose on required props vs the props union the ManageSubscriptionButton uses,
 * but split in case we only want one specific case in some places in the future.
 */
export const configureManageSubscriptionButton = (
	company: Company,
	user: User,
	successUrl: string = window.location.href,
	cancelUrl: string = window.location.href,
	returnUrl: string = window.location.href,
): JSX.Element => {
	if (company.subscriptionStatus === undefined) {
		return (
			<ManageSubscriptionButton
				subscriptionStatus={company.subscriptionStatus}
				user={user}
				companyID={company.id}
				companyName={company.name}
				successUrl={successUrl}
				cancelUrl={cancelUrl}
				fullWidth
			/>
		);
	} else if (company.subscriptionStatus === SubscriptionStatuses.Incomplete) {
		return (
			<ManageSubscriptionButton
				subscriptionStatus={company.subscriptionStatus}
				user={user}
				successUrl={successUrl}
				cancelUrl={cancelUrl}
				freeTrial
				fullWidth
			/>
		);
	} else if (company.subscriptionStatus === SubscriptionStatuses.Canceled) {
		return (
			<ManageSubscriptionButton
				subscriptionStatus={company.subscriptionStatus}
				user={user}
				successUrl={successUrl}
				cancelUrl={cancelUrl}
				fullWidth
			/>
		);
	} else {
		return (
			<ManageSubscriptionButton
				subscriptionStatus={company.subscriptionStatus}
				user={user}
				returnUrl={returnUrl}
				fullWidth
			/>
		);
	}
};
