import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Autocomplete, TextField, Checkbox } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UserProps, accountTypes } from '../../../constants/Common';
import { ValidStatus, Timesheet } from '../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../constants/Timesheet/TimesheetStatus';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { DataTable } from '../../DataTable/DataTable';
import DateRangeSelector from '../../DateRangeSelector/DateRangeSelector';
import { formatSlashedDate } from '../../helpers/dateFormatters';
import { stringHoursCustomTableSort } from '../../helpers/muiDataTableCustomSorts';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { TimesheetStatusChip } from '../../Timesheets/TimesheetStatusChip';
import { ReportConfigureHeader } from '../ReportConfigureHeader';
import { reportsCustomTableFilter } from '../ReportsCustomTableFilter';
import { ReportsStyleWrapper } from '../ReportsStyleWrapper';
import {
	TimesheetsReportFirebaseCalls,
	FetchButtonState,
} from '../StateManagement/models';

const validStatuses: readonly ValidStatus[] = [
	TimesheetStatus.Approved,
	TimesheetStatus.Archived,
	TimesheetStatus.Submitted,
];

export type TimesheetsReportProps = UserProps & {
	startDateContext: { date: Date; setDate: Dispatch<SetStateAction<Date>> };
	endDateContext: { date: Date; setDate: Dispatch<SetStateAction<Date>> };
	firebaseApi: Pick<FirebaseApi, TimesheetsReportFirebaseCalls>;
};

type TimesheetTableData = {
	employee: string;
	contractedTo: string;
	employer: string;
	site: string;
	weekEnding: string;
	hours: number;
	timesheetStatus: TimesheetStatus;
};

export const TimesheetsReport = ({
	userDetails,
	startDateContext,
	endDateContext,
	firebaseApi,
}: TimesheetsReportProps): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const [hasUpdates, setHasUpdates] = useState(false);
	const [tableHeader, setTableHeader] = useState('Timesheets Report');
	const [tempStartDate, setTempStartDate] = useState(startDateContext.date);
	const [tempEndDate, setTempEndDate] = useState(endDateContext.date);
	const [allTimesheets, setAllTimesheets] = useState<TimesheetTableData[]>(
		[],
	);
	const [fetchButtonText, setFetchButtonText] =
		useState<FetchButtonState>('Update');
	const [initialLoad, setInitialLoad] = useState(true);
	const [statuses, setStatuses] = useState<ValidStatus[]>([
		TimesheetStatus.Approved,
		TimesheetStatus.Archived,
	]);
	const timesheetsByClient = userDetails.accountType === 'handler';
	const noMatchTableText = initialLoad ? (
		'Configure report information above and press update'
	) : loading ? (
		<LoadingDots />
	) : (
		'Sorry, no matching timesheets found'
	);

	const cols: MUIDataTableColumnDef[] = [
		{
			label: 'Employee',
			name: 'employee',
			options: {
				filterType: 'custom',
				filterOptions: {
					logic: (name, filters, _): boolean =>
						filters.length ? !filters.includes(name) : false,
					display: reportsCustomTableFilter,
				},
			},
		},
		timesheetsByClient
			? {
					label: 'Client',
					name: 'contractedTo',
					options: {
						filterType: 'custom',
						filterOptions: {
							logic: (name, filters, _): boolean =>
								filters.length
									? !filters.includes(name)
									: false,
							display: reportsCustomTableFilter,
						},
					},
			  }
			: {
					label: 'Employer',
					name: 'employer',
					options: {
						filterType: 'custom',
						filterOptions: {
							logic: (name, filters, _): boolean =>
								filters.length
									? !filters.includes(name)
									: false,
							display: reportsCustomTableFilter,
						},
					},
			  },
		{
			label: 'Site',
			name: 'site',
			options: {
				filterType: 'custom',
				filterOptions: {
					logic: (name, filters, _): boolean =>
						filters.length ? !filters.includes(name) : false,
					display: reportsCustomTableFilter,
				},
			},
		},
		{
			label: 'Week Ending',
			name: 'weekEnding',
			options: {
				filter: false,
			},
		},
		{
			label: 'Hours',
			name: 'hours',
			options: {
				filter: false,
				sortCompare: stringHoursCustomTableSort,
			},
		},
		{
			label: 'Status',
			name: 'timesheetStatus',
			options: {
				filter: false,
				customBodyRender: (status) => (
					<TimesheetStatusChip status={status} />
				),
			},
		},
	];

	const customTableOptions: Partial<MUIDataTableOptions> = {
		filter: true,
		tableBodyHeight: 'calc(100vh - 424px)',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
		responsive: 'standard',
		isRowSelectable: () => false,
		downloadOptions: {
			filename: tableHeader
				.replace(new RegExp('/', 'g'), '-')
				.replace(/\s/g, '_'),
		},
	};

	useEffect(() => {
		setTempStartDate(startDateContext.date);
	}, [startDateContext.date]);

	useEffect(() => {
		setTempEndDate(endDateContext.date);
	}, [endDateContext.date]);

	useEffect(() => {
		setFetchButtonText('Update');
	}, [tempStartDate, tempEndDate, statuses]);

	useEffect(() => {
		let mounted = true;
		const getTimesheets = async (): Promise<void> => {
			if (!userDetails.contractedTo?.id) return;
			setLoading(true);
			let timesheets: Timesheet[] = [];
			switch (userDetails.accountType) {
				case accountTypes.handler:
					timesheets =
						await firebaseApi.getTimesheetsByWeekEmployerStatus(
							startDateContext.date,
							endDateContext.date,
							userDetails.companyID,
							statuses,
						);
					break;
				case accountTypes.management:
					timesheets =
						await firebaseApi.getTimesheetsByWeekSiteContractedStatus(
							startDateContext.date,
							endDateContext.date,
							userDetails.siteID,
							userDetails.companyID,
							statuses,
						);
					break;
				case accountTypes.seniorManagement:
					timesheets =
						await firebaseApi.getTimesheetsByWeekContractedStatus(
							startDateContext.date,
							endDateContext.date,
							userDetails.companyID,
							statuses,
						);
			}
			if (mounted) {
				setAllTimesheets(
					timesheets.map((timesheet) => ({
						employee: timesheet.employee.name,
						contractedTo: timesheet.contractedTo
							? timesheet.contractedTo.name
							: 'No Client',
						employer: timesheet.employer
							? timesheet.employer.name
							: 'No Employer',
						site: timesheet.site.name,
						weekEnding: formatSlashedDate(
							timesheet.weekEnding.toDate(),
						),
						hours: timesheet.hours.total.billable,
						timesheetStatus: timesheet.timesheetStatus,
					})),
				);
				setLoading(false);
				setHasUpdates(false);
				setFetchButtonText('Refresh');
				setTableHeader(
					`Timesheet Report from ${formatSlashedDate(
						startDateContext.date,
					)} - ${formatSlashedDate(endDateContext.date)}`,
				);
			}
		};
		if (hasUpdates) {
			setAllTimesheets([]);
			getTimesheets();
		}
		return (): void => {
			mounted = false;
		};
	}, [
		hasUpdates,
		userDetails.siteID,
		startDateContext.date,
		endDateContext.date,
		userDetails.contractedTo?.id,
		userDetails.accountType,
		userDetails.companyID,
		statuses,
		firebaseApi,
	]);

	const handleUpdate = (): void => {
		if (initialLoad) setInitialLoad(false);
		startDateContext.setDate(tempStartDate);
		endDateContext.setDate(tempEndDate);
		setHasUpdates(true);
	};

	const renderDateSelector: JSX.Element = (
		<DateRangeSelector
			startDateUseState={[tempStartDate, setTempStartDate]}
			endDateUseState={[tempEndDate, setTempEndDate]}
			textFieldProps={{
				size: 'small',
				fullWidth: true,
			}}
			maxDateRangeDays={365}
		/>
	);

	const renderStatusAutoComplete: JSX.Element = (
		<Autocomplete
			fullWidth
			multiple
			size="small"
			disableCloseOnSelect
			options={validStatuses}
			value={statuses}
			onChange={(_, statuses): void => setStatuses(statuses)}
			renderInput={(params): JSX.Element => (
				<TextField {...params} label="Status" />
			)}
			renderOption={(props, option, { selected }): JSX.Element => (
				<li {...props}>
					<Checkbox
						icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
						checkedIcon={<CheckBoxIcon fontSize="small" />}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{option}
				</li>
			)}
		/>
	);

	const renderReportHeader: JSX.Element = (
		<ReportConfigureHeader
			loading={loading}
			updateOnClick={handleUpdate}
			updateButtonText={fetchButtonText}
			disabled={statuses.length === 0}
		/>
	);

	return (
		<Box flex="1">
			<ReportsStyleWrapper
				title={renderReportHeader}
				componentList={[
					{
						gridSize: 5,
						component: renderDateSelector,
						id: 'dateSelector',
					},
					{
						gridSize: 7,
						component: renderStatusAutoComplete,
						id: 'statusAutoComplete',
					},
				]}
			/>
			<DataTable
				title={tableHeader}
				tableData={allTimesheets}
				columns={cols}
				customTableOptions={customTableOptions}
			/>
		</Box>
	);
};
