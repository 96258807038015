import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { TabContext } from '@mui/lab';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Stack,
	Step,
	StepLabel,
	StepLabelProps,
	Stepper,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import type { User } from '../../../firebase/firebase';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { useAbortController } from '../../../hooks/useAbortController';
import {
	IntegrationAtRest,
	IntegrationType,
} from '../../../models/Integrations/Integration';
import {
	IntegrationStatus,
	IntegrationStatuses,
} from '../../../models/Integrations/IntegrationStatus';
import { InvoicingType } from '../../../models/Integrations/InvoicingIntegration';
import { PayrollType } from '../../../models/Integrations/PayrollIntegration';
import {
	useUserAuthContext,
	useUserDetailsContext,
} from '../../../providers/UserProvider';
import CustomAlert from '../../CustomAlert/CustomAlert';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { EndIntegrationDialog } from '../IntegrationUIComponents/EndIntegrationDialog';
import { IntegrationTab } from './IntegrationTab';

type CompanyIntegrationMap = Partial<{
	invoicingIntegrated: InvoicingType;
	payrollIntegrated: PayrollType;
}>;

export type BaseIntegrationPageProps<T extends IntegrationType> = {
	integrationType: T;
	tabs: IntegrationTab<T>[];
	token: string | null;
	handleAuthenticate: () => void;
	handleCancelAuthenticate: () => void;
	renderAuthenticationContent: () => ReactNode;
	handleSubmitSuccess: () => void;
	handleSubmitError: () => void;
	fetchToken: (
		abortSignal: AbortSignal,
		user: User,
		code: string,
		type: T,
	) => Promise<boolean | undefined>;
	postEndIntegrationStep: (
		companyIntegrations: CompanyIntegrationMap,
	) => void;
	integrationSettings:
		| {
				companyIntegrationField: 'payrollIntegrated';
				integrationCollection: 'payrollIntegrations';
				secondaryFields: ['invoicingIntegrated'];
		  }
		| {
				companyIntegrationField: 'invoicingIntegrated';
				integrationCollection: 'invoicingIntegrations';
				secondaryFields: ['payrollIntegrated'];
		  };
	deleteIntegrationFunction?: (
		// currently optional until invoicing implementation
		abortSignal: AbortSignal,
		user: User,
	) => Promise<boolean | undefined>;

	endIntegrationHelperText?: string;
	updateIntegrationStatus: (
		companyID: string,
		status: IntegrationStatus,
	) => void;
	integrationSubscription: (
		companyID: string,
		onNext: (integration: IntegrationAtRest<T> | null) => void,
	) => () => void;
	firebaseApi: Pick<
		FirebaseApi,
		| 'companySubscription'
		| 'updateInvoicingIntegrationStatus'
		| 'invoicingIntegrationSubscription'
		| 'updatePayrollIntegrationStatus'
		| 'payrollIntegrationSubscription'
	>;
};

export type BaseInvoicingPageProps = Omit<
	BaseIntegrationPageProps<InvoicingType>,
	| 'integrationSettings'
	| 'updateIntegrationStatus'
	| 'integrationSubscription'
>;
export const BaseInvoicingPage = (
	props: BaseInvoicingPageProps,
): JSX.Element => (
	<BaseIntegrationPage
		{...props}
		integrationSettings={{
			companyIntegrationField: 'invoicingIntegrated',
			integrationCollection: 'invoicingIntegrations',
			secondaryFields: ['payrollIntegrated'],
		}}
		updateIntegrationStatus={
			props.firebaseApi.updateInvoicingIntegrationStatus
		}
		integrationSubscription={
			props.firebaseApi.invoicingIntegrationSubscription
		}
	/>
);

export type BasePayrollPageProps = Omit<
	BaseIntegrationPageProps<PayrollType>,
	| 'integrationSettings'
	| 'updateIntegrationStatus'
	| 'integrationSubscription'
>;

export const BasePayrollPage = (props: BasePayrollPageProps): JSX.Element => (
	<BaseIntegrationPage
		{...props}
		integrationSettings={{
			companyIntegrationField: 'payrollIntegrated',
			integrationCollection: 'payrollIntegrations',
			secondaryFields: ['invoicingIntegrated'],
		}}
		updateIntegrationStatus={
			props.firebaseApi.updatePayrollIntegrationStatus
		}
		integrationSubscription={
			props.firebaseApi.payrollIntegrationSubscription
		}
	/>
);

const BaseIntegrationPage = <T extends PayrollType | InvoicingType>({
	integrationType,
	tabs,
	token,
	handleAuthenticate,
	handleCancelAuthenticate,
	renderAuthenticationContent,
	handleSubmitSuccess,
	handleSubmitError,
	fetchToken,
	integrationSettings,
	postEndIntegrationStep,
	endIntegrationHelperText,
	updateIntegrationStatus,
	integrationSubscription,
	firebaseApi,
	deleteIntegrationFunction,
}: BaseIntegrationPageProps<T>): JSX.Element => {
	const user = useUserAuthContext();
	const userDetails = useUserDetailsContext();
	const abortSignal = useAbortController();

	const [currentTab, setCurrentTab] = useState(0);
	const [loading, setLoading] = useState(true);
	const [integration, setIntegration] = useState<IntegrationAtRest<T> | null>(
		null,
	);
	const [authenticated, setAuthenticated] = useState(false);

	const [publishing, setPublishing] = useState(false);
	const [showEndIntegrationModal, setShowEndIntegrationModal] =
		useState(false);

	const [otherIntegration, setOtherIntegration] = useState<
		Partial<{
			invoicingIntegrated: InvoicingType;
			payrollIntegrated: PayrollType;
		}>
	>({});

	const [showDeleteError, setShowDeleteError] = useState(false);

	const [deleted, setDeleted] = useState(false);

	const [showUnauthorisedWarning, setShowUnauthorisedWarning] =
		useState(false);

	const forceAuth = integration?.status === IntegrationStatuses.Unauthorised;

	const submitIntegrationKey = useCallback(
		async (code: string) => {
			if (user === null) return;
			setPublishing(true);
			const response = await fetchToken(
				abortSignal,
				user,
				code,
				integrationType,
			);
			setPublishing(false);
			if (response) {
				handleSubmitSuccess();
			} else {
				handleSubmitError();
			}
		},
		[
			user,
			fetchToken,
			abortSignal,
			integrationType,
			handleSubmitSuccess,
			handleSubmitError,
		],
	);

	const updateIntegrationStatusActive =
		useCallback(async (): Promise<void> => {
			if (userDetails === null || !authenticated) return;

			updateIntegrationStatus(
				userDetails.companyID,
				IntegrationStatuses.Active,
			);
		}, [userDetails, authenticated, updateIntegrationStatus]);

	useEffect(() => {
		if (userDetails === null) return;

		setLoading(true);
		const sub = firebaseApi.companySubscription(
			userDetails.companyID,
			(company) => {
				setLoading(false);

				if (
					company[integrationSettings.companyIntegrationField] ===
					integrationType
				) {
					setAuthenticated(true);
				} else if (
					company[integrationSettings.companyIntegrationField]
				) {
					// already integrated with another system
					handleCancelAuthenticate();
				} else {
					// no longer available
					setAuthenticated(false);
				}

				const secondaryIntegrations: CompanyIntegrationMap = {};

				for (const field of integrationSettings.secondaryFields) {
					const integration = company[field];
					if (integration) {
						if (field === 'invoicingIntegrated') {
							secondaryIntegrations[field] = company[field];
						} else {
							secondaryIntegrations[field] = company[field];
						}
					}
				}
				setOtherIntegration(secondaryIntegrations);
			},
		);

		return sub;
	}, [
		firebaseApi,
		handleCancelAuthenticate,
		integrationSettings.companyIntegrationField,
		integrationSettings.secondaryFields,
		integrationType,
		userDetails,
	]);

	useEffect(() => {
		if (userDetails === null || !authenticated) return;

		return integrationSubscription(userDetails.companyID, (integration) => {
			setIntegration(integration);
		});
	}, [
		authenticated,
		userDetails,
		integrationSettings.integrationCollection,
		integrationSubscription,
	]);

	const handleChange = (
		_: React.SyntheticEvent<Element, Event>,
		value: number,
	): void => {
		setCurrentTab(value);
	};

	const handlePublish = (): void => {
		if (!token) return; // bad token
		submitIntegrationKey(token);
	};

	const handleDeleteIntegrationConfirm = async (): Promise<void> => {
		if (user === null) return;
		setPublishing(true);
		try {
			await deleteIntegrationFunction?.(abortSignal, user);
			setDeleted(true);
			setAuthenticated(false);
			postEndIntegrationStep(otherIntegration);
			setShowEndIntegrationModal(false);
		} catch (err) {
			console.error(err);
			setShowDeleteError(true);
		}
		setPublishing(false);
	};

	const handleCancelWithForceAuth = (): void => {
		if (forceAuth) {
			setShowUnauthorisedWarning(true);
		} else {
			handleCancelAuthenticate();
		}
	};

	const setupModal = (): JSX.Element => (
		<Dialog
			open={
				token === null &&
				(!authenticated || (forceAuth && !showUnauthorisedWarning)) &&
				!deleted
			}>
			<DialogTitle>{`Authenticate with ${integrationType}`}</DialogTitle>
			<DialogContent>{renderAuthenticationContent()}</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleCancelWithForceAuth}>
					Cancel
				</Button>
				<Button variant="contained" onClick={handleAuthenticate}>
					Authenticate
				</Button>
			</DialogActions>
		</Dialog>
	);

	const publishDialog = (): JSX.Element => (
		<Dialog
			open={token !== null && (!authenticated || forceAuth) && !deleted}>
			<DialogTitle>Confirm</DialogTitle>
			{publishing ? (
				<DialogContent>
					<LoadingDots />
				</DialogContent>
			) : (
				<DialogContent>
					{`Confirm that you give Trade Legion permission to use your ${integrationType} connection.`}
				</DialogContent>
			)}
			<DialogActions>
				<Button
					variant="outlined"
					onClick={handleCancelAuthenticate}
					disabled={publishing}>
					Cancel
				</Button>
				<Button
					variant="contained"
					onClick={handlePublish}
					disabled={publishing}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);

	const renderEndIntegrationDialog = (): JSX.Element => (
		<EndIntegrationDialog
			showEndIntegrationModalState={[
				showEndIntegrationModal,
				setShowEndIntegrationModal,
			]}
			publishing={publishing}
			integrationType={integrationType}
			showDeleteError={showDeleteError}
			handleDeleteIntegrationConfirm={handleDeleteIntegrationConfirm}
			endIntegrationHelperText={endIntegrationHelperText ?? ''}
		/>
	);

	const stepperHeader = (): JSX.Element => {
		const handleNext = (): void => {
			if (currentTab === tabs.length - 1) {
				updateIntegrationStatusActive();
			} else {
				setCurrentTab((prevActiveStep) => prevActiveStep + 1);
			}
		};
		const handleBack = (): void => {
			setCurrentTab((prevActiveStep) => prevActiveStep - 1);
		};
		return (
			<Box py={1} width="100%" display="flex" flexDirection="row">
				<Stack justifyContent="center">
					<Button
						variant="contained"
						onClick={handleBack}
						disabled={currentTab === 0}
						startIcon={<ArrowBackIcon />}>
						Back
					</Button>
				</Stack>
				<Stepper
					activeStep={currentTab}
					alternativeLabel
					sx={{ flex: '1 1 auto' }}>
					{tabs.map((tab) => {
						const labelProps: StepLabelProps = tab.optional
							? {
									optional: (
										<Typography variant="caption">
											(Optional)
										</Typography>
									),
							  }
							: {};

						return (
							<Step key={tab.name}>
								<StepLabel
									{...labelProps}
									sx={{
										textAlign: 'center',
									}}>
									Setup {tab.name}
								</StepLabel>
							</Step>
						);
					})}
				</Stepper>
				<Stack justifyContent="center">
					<Button
						variant="contained"
						onClick={handleNext}
						disabled={
							integration
								? !tabs[currentTab]?.canMoveNext(integration)
								: true
						}
						endIcon={<ArrowForwardIcon />}>
						{currentTab === tabs.length - 1 ? 'Finish' : 'Next'}
					</Button>
				</Stack>
			</Box>
		);
	};

	const tabHeader = (): JSX.Element => (
		<Box width="100%">
			<Stack direction="row" justifyContent="space-between">
				<Tabs
					value={currentTab}
					onChange={handleChange}
					aria-label="integration-tabs">
					{tabs.map((tab) => (
						<Tab key={tab.name} label={tab.name} />
					))}
				</Tabs>
				{deleteIntegrationFunction !== undefined && (
					<Button
						sx={{ my: 1 }}
						endIcon={<CloseIcon />}
						size="small"
						variant="contained"
						onClick={(): void => setShowEndIntegrationModal(true)}>
						End Integration
					</Button>
				)}
			</Stack>
			<Divider />
		</Box>
	);

	const renderUnauthorisedWarning = (): JSX.Element => (
		<CustomAlert
			alertText="Integration requires reauthorising"
			actionOnClick={(): void => setShowUnauthorisedWarning(false)}
			actionText="Reauthorise"
			severity="error"
		/>
	);

	return (
		<Box>
			{loading || user === null || userDetails === null ? (
				<LoadingDots />
			) : (
				<>
					{setupModal()}
					{publishDialog()}
					{renderEndIntegrationDialog()}
					{authenticated &&
						(integration === null ||
						(forceAuth && !showUnauthorisedWarning) ? (
							<LoadingDots />
						) : (
							<>
								{integration.status ===
								IntegrationStatuses.Active
									? tabHeader()
									: showUnauthorisedWarning
									? renderUnauthorisedWarning()
									: integration && stepperHeader()}

								<TabContext value={currentTab.toString()}>
									{tabs.map((tab) => (
										<Box
											key={tab.name}
											role="integration-tabpanel"
											hidden={currentTab !== tab.index}
											id={`integration-tabpanel-${tab.index}`}
											aria-labelledby={`integration-${tab.index}`}>
											{currentTab === tab.index && (
												<Box py={3}>
													{tab.content({
														user,
														userDetails,
														integration,
														integrationCollection:
															integrationSettings.integrationCollection,
														fetchData:
															tab.fetchData,
													})}
												</Box>
											)}
										</Box>
									))}
								</TabContext>
							</>
						))}
				</>
			)}
		</Box>
	);
};
