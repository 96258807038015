import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type CreateCustomerPortalSessionResponse = {
	customerPortalSessionUrl: string;
};

type CreateCustomerPortalSessionBody = {
	returnUrl: string;
};

export const fetchCustomerPortal = async (
	abortSignal: AbortSignal,
	user: User,
	returnUrl: string,
): Promise<string | undefined> => {
	const response = await makeCloudFunctionRequestDeprecated<
		CreateCustomerPortalSessionResponse,
		CreateCustomerPortalSessionBody
	>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.StripeCreateCustomerPortalSession,
		services: defaultCloudFunctionServices,
		method: 'POST',
		body: { returnUrl },
	});

	return response?.customerPortalSessionUrl;
};
