import type { User } from '../firebase/firebase';
import { AllPayrollLeaveTypesResponse } from '../models/Integrations/IntegrationElements';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export const fetchPayrollLeaveIDs = async (
	abortSignal: AbortSignal,
	user: User,
): Promise<AllPayrollLeaveTypesResponse | undefined> => {
	return await makeCloudFunctionRequestDeprecated<AllPayrollLeaveTypesResponse>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.PayrollLeaveIDs,
			method: 'GET',
			services: defaultCloudFunctionServices,
		},
	);
};
