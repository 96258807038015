import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type DeleteAllNotesBody = {
	timesheetID: string;
};

export const deleteAllTimesheetNotes = async (
	abortSignal: AbortSignal,
	user: User,
	timesheetID: string,
): Promise<void> => {
	await makeCloudFunctionRequestReturnSuccessStatus<DeleteAllNotesBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.DeleteAllTimesheetNotes,
		method: 'DELETE',
		services: defaultCloudFunctionServices,
		body: {
			timesheetID,
		},
	});
};
