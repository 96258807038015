import { LoadingButton } from '@mui/lab';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material';
import { MouseEvent, useMemo, useReducer } from 'react';
import { CloudFunctionApi } from '../../../cloudfunctions';
import {
	accountUserInvitesMap,
	UserInvitesAccountType,
} from '../../../constants/Accounts';
import {
	AccountType,
	AccountTypeHumanName,
	Company,
	CompanyTypes,
	Site,
} from '../../../constants/Common';
import { UserInvite } from '../../../constants/UserInvite';
import type { User } from '../../../firebase/firebase';
import { SimpleCompanyAutocomplete } from '../../Autocomplete/SimpleCompanyAutocomplete';
import { SitesAutocomplete } from '../../Autocomplete/SitesAutocomplete';
import {
	PhoneNumberInput,
	validatePhoneNumber,
} from '../../PhoneNumberInput/PhoneNumberInput';
import { verifyEmail } from '../UserInvites/helpers';
import {
	updateAccountType,
	updateContractedTo,
	updateSite,
	updateFirstName,
	updateLastName,
	updateEmail,
	updateMobileNumber,
	setInitialState,
	updateCreateAnother,
	updateErrors,
	updateUserInviteStatus,
	CreateState,
} from './actions';
import {
	CreateUserInviteSnackBar,
	CreateUserInviteStatuses,
} from './CreateUserInviteSnackBar';
import {
	createInitialCreateUserDialogState,
	createUserDialogReducer,
} from './reducer';

export type CreateUserInviteDialogProps = {
	user: User | null;
	abortSignal: AbortSignal;
	userCompany: Company;
	userAccountType: UserInvitesAccountType;
	companies: Record<string, Company>;
	sites: Record<string, Site>;
	dialogOpen: boolean;
	closeDialog: () => void;
	cloudFunctionApi: Pick<CloudFunctionApi, 'createUserInvite'>;
};

export const CreateUserInviteDialog = ({
	user,
	abortSignal,
	userCompany,
	userAccountType,
	companies,
	sites,
	dialogOpen,
	closeDialog,
	cloudFunctionApi,
}: CreateUserInviteDialogProps): JSX.Element => {
	const [state, dispatch] = useReducer(
		createUserDialogReducer,
		{
			userAccountType: userAccountType,
		},
		createInitialCreateUserDialogState,
	);

	const selectableAccountTypes = accountUserInvitesMap[userAccountType];
	const loading =
		state.createUserInviteStatus === CreateUserInviteStatuses.Loading;
	const companyOptions = useMemo(() => {
		return state.site?.companyType === CompanyTypes.recruitment
			? {
					[userCompany.id]: userCompany,
					...companies,
			  }
			: companies;
	}, [companies, state.site?.companyType, userCompany]);

	const validate = (
		inputs: Pick<
			UserInvite,
			'firstName' | 'lastName' | 'email' | 'mobileNumber'
		>,
	): boolean => {
		const errors: Partial<CreateState['errors']> = {};

		if (!inputs.firstName) {
			errors.firstName = 'First name is required';
		}

		if (!inputs.lastName) {
			errors.lastName = 'Last name is required';
		}

		if (!inputs.email) {
			errors.email = 'Email is required';
		} else if (!verifyEmail(state.email)) {
			errors.email = 'Invalid email';
		}

		if (!inputs.mobileNumber) {
			errors.mobileNumber = 'Mobile number is required';
		} else if (!validatePhoneNumber(state.mobileNumber)) {
			errors.mobileNumber = 'Invalid mobile number';
		}

		// Check if there are any errors
		const hasErrors = Object.keys(errors).length > 0;

		if (hasErrors) {
			// Dispatch errors to the state
			dispatch(updateErrors(errors));
		} else {
			// Clear errors
			dispatch(updateErrors({}));
		}

		return !hasErrors;
	};

	const getTrimmedTextFieldValues = (): Pick<
		UserInvite,
		'firstName' | 'lastName' | 'email' | 'mobileNumber'
	> => ({
		firstName: state.firstName.trim(),
		lastName: state.lastName.trim(),
		email: state.email.trim(),
		mobileNumber: state.mobileNumber.trim(),
	});

	const handleSubmit = async (event: MouseEvent): Promise<void> => {
		const textFieldValues = getTrimmedTextFieldValues();
		if (!user || !validate(textFieldValues)) return;

		// Prevent the status snack bar from closing from the clickaway event
		event.stopPropagation();

		const UserInviteSite = state.site
			? {
					id: state.site.id,
					name: state.site.name,
					companyID: state.site.companyID,
					companyName: state.site.company,
			  }
			: null;

		dispatch(updateUserInviteStatus(CreateUserInviteStatuses.Loading));
		const success = await cloudFunctionApi.createUserInvite(
			abortSignal,
			user,
			{
				firstName: textFieldValues.firstName,
				lastName: textFieldValues.lastName,
				email: textFieldValues.email,
				mobileNumber: textFieldValues.mobileNumber,
				accountType: state.accountType,
				site: UserInviteSite,
				company: userCompany,
				contractedTo: state.contractedTo,
			},
		);
		if (success && state.createAnother) {
			dispatch(
				setInitialState({
					createUserInviteStatus: CreateUserInviteStatuses.Success,
					createAnother: true,
				}),
			);
		} else if (success) {
			dispatch(
				setInitialState({
					createUserInviteStatus: CreateUserInviteStatuses.Success,
				}),
			);
			closeDialog();
		} else {
			dispatch(updateUserInviteStatus(CreateUserInviteStatuses.Error));
		}
	};

	const handleCloseDialog = (): void => {
		dispatch(setInitialState());
		closeDialog();
	};

	return (
		<>
			<Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth>
				{state.createUserInviteStatus !==
					CreateUserInviteStatuses.None && (
					<CreateUserInviteSnackBar
						snackBarStatus={state.createUserInviteStatus}
						onClose={(): void => {
							dispatch(
								updateUserInviteStatus(
									CreateUserInviteStatuses.None,
								),
							);
						}}
					/>
				)}
				<DialogTitle>Invite New User</DialogTitle>
				<DialogContent>
					<Grid container spacing={2} pt={1}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="First Name"
								size="small"
								value={state.firstName}
								onChange={(event): void =>
									dispatch(
										updateFirstName(event.target.value),
									)
								}
								disabled={loading}
								error={!!state.errors.firstName}
								helperText={state.errors.firstName}
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Last Name"
								size="small"
								value={state.lastName}
								onChange={(event): void =>
									dispatch(updateLastName(event.target.value))
								}
								disabled={loading}
								error={!!state.errors.lastName}
								helperText={state.errors.lastName}
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Email"
								type="email"
								size="small"
								value={state.email}
								onChange={(event): void =>
									dispatch(updateEmail(event.target.value))
								}
								disabled={loading}
								error={!!state.errors.email}
								helperText={state.errors.email}
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<PhoneNumberInput
								value={state.mobileNumber}
								required
								onChange={(event): void => {
									dispatch(
										updateMobileNumber(event.toString()),
									);
								}}
								label="Moblie Number"
								checkError={!!state.errors.mobileNumber}
								helperText={state.errors.mobileNumber}
								disabled={loading}
								size="small"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								select
								fullWidth
								label="Account Type"
								size="small"
								value={state.accountType}
								disabled={loading}
								required
								onChange={(event): void =>
									dispatch(
										updateAccountType(
											event.target.value as AccountType,
										),
									)
								}>
								{selectableAccountTypes.map((option) => (
									<MenuItem key={option} value={option}>
										{AccountTypeHumanName[option]}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={12}>
							<SitesAutocomplete
								label="Site"
								value={state.site?.id ?? null}
								sites={sites}
								size="small"
								onChange={(value): void => {
									if (value) {
										dispatch(updateSite(sites[value]));
									}
								}}
								disabled={loading}
							/>
						</Grid>
						<Grid item xs={12}>
							<SimpleCompanyAutocomplete
								label="Contracted To"
								value={state.contractedTo?.id ?? null}
								size="small"
								companies={companyOptions}
								onChange={(contractedTo): void =>
									dispatch(updateContractedTo(contractedTo))
								}
								disabled={
									loading ||
									state.site?.companyType ===
										CompanyTypes.recruitment
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							px={2}
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="space-between">
							<Typography>Create Another?</Typography>
							<Checkbox
								edge="end"
								onChange={(): void =>
									dispatch(
										updateCreateAnother(
											!state.createAnother,
										),
									)
								}
								checked={state.createAnother}
								disabled={loading}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleCloseDialog}
						variant="outlined"
						disabled={loading}>
						Cancel
					</Button>
					<LoadingButton
						onClick={handleSubmit}
						variant="contained"
						loading={loading}>
						Submit
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
};
