import { Dispatch } from 'react';
import { Company, Site, UserDetails } from '../../../constants/Common';
import { Action } from '../../../constants/TypescriptUtilities';
import { UserInvite } from '../../../constants/UserInvite';
import { sortObjectByField } from '../../helpers/sortHelpers';

export type AccountsState = {
	userCompany: Company | null;
	users: Record<string, UserDetails>;
	unapprovedUsers: Record<string, UserDetails>;
	sites: Record<string, Site>;
	companySites: Record<string, Site>;
	companies: Record<string, Company>;
	userInvites: Record<string, UserInvite>;
	loadingUsers: boolean;
	loadingNewAccounts: boolean;
	loadingUserInvites: boolean;
};

export type AccountsOutletContext = {
	context: AccountsState;
	dispatch: Dispatch<AccountsActions>;
};

type AccountsAction<
	T extends AccountsActionTypes,
	P extends object | void = void,
> = Action<T, P>;

export const enum AccountsActionTypes {
	SET_USER_COMPANY = 'SET_USER_COMPANY',
	SET_USERS = 'SET_USERS',
	SET_USER_INVITES = 'SET_USER_INVITES',
	SET_UNAPPROVED_USERS = 'SET_UNAPPROVED_USERS',
	SET_SITES = 'SET_SITES',
	SET_COMPANY_SITES = 'SET_COMPANY_SITES',
	SET_COMPANIES = 'SET_COMPANIES',
	SET_LOADING_USERS = 'SET_LOADING_USERS',
	SET_LOADING_USER_INVITES = 'SET_LOADING_USER_INVITES',
	SET_LOADING_NEW_ACCOUNTS = 'SET_LOADING_NEW_ACCOUNTS',
}

type SetUserCompanyAction = AccountsAction<
	AccountsActionTypes.SET_USER_COMPANY,
	{
		company: Company | null;
	}
>;

type SetUsersAction = AccountsAction<
	AccountsActionTypes.SET_USERS,
	{
		users: AccountsState['users'];
	}
>;

type SetUserInvitesAction = AccountsAction<
	AccountsActionTypes.SET_USER_INVITES,
	{
		userInvites: AccountsState['userInvites'];
	}
>;

type SetUnapprovedUsersAction = AccountsAction<
	AccountsActionTypes.SET_UNAPPROVED_USERS,
	{
		unapprovedUsers: AccountsState['unapprovedUsers'];
	}
>;

type SetSitesAction = AccountsAction<
	AccountsActionTypes.SET_SITES,
	{
		sites: AccountsState['sites'];
	}
>;

type SetCompanySitesAction = AccountsAction<
	AccountsActionTypes.SET_COMPANY_SITES,
	{
		companySites: AccountsState['companySites'];
	}
>;

type SetCompaniesAction = AccountsAction<
	AccountsActionTypes.SET_COMPANIES,
	{
		companies: AccountsState['companies'];
	}
>;

type SetLoadingUsersAction = AccountsAction<
	AccountsActionTypes.SET_LOADING_USERS,
	{
		loadingUsers: AccountsState['loadingUsers'];
	}
>;

type SetLoadingUserInvitesAction = AccountsAction<
	AccountsActionTypes.SET_LOADING_USER_INVITES,
	{
		loadingUserInvites: AccountsState['loadingUserInvites'];
	}
>;

type SetLoadingNewAccountsAction = AccountsAction<
	AccountsActionTypes.SET_LOADING_NEW_ACCOUNTS,
	{
		loadingNewAccounts: AccountsState['loadingNewAccounts'];
	}
>;

export type AccountsActions =
	| SetUserCompanyAction
	| SetUsersAction
	| SetUserInvitesAction
	| SetUnapprovedUsersAction
	| SetSitesAction
	| SetCompanySitesAction
	| SetCompaniesAction
	| SetLoadingUsersAction
	| SetLoadingNewAccountsAction
	| SetLoadingUserInvitesAction;

export const setUserCompany = (
	company: Company | null,
): SetUserCompanyAction => ({
	type: AccountsActionTypes.SET_USER_COMPANY,
	payload: { company },
});

export const setUsers = (users: AccountsState['users']): SetUsersAction => ({
	type: AccountsActionTypes.SET_USERS,
	payload: { users: sortObjectByField(users, 'displayName') },
});

export const setUserInvites = (
	userInvites: AccountsState['userInvites'],
): SetUserInvitesAction => ({
	type: AccountsActionTypes.SET_USER_INVITES,
	payload: { userInvites },
});

export const setUnapprovedUsers = (
	unapprovedUsers: AccountsState['unapprovedUsers'],
): SetUnapprovedUsersAction => ({
	type: AccountsActionTypes.SET_UNAPPROVED_USERS,
	payload: {
		unapprovedUsers: sortObjectByField(unapprovedUsers, 'displayName'),
	},
});

export const setSites = (sites: AccountsState['sites']): SetSitesAction => ({
	type: AccountsActionTypes.SET_SITES,
	payload: { sites: sortObjectByField(sites, 'name') },
});

export const setCompanySites = (
	companySites: AccountsState['companySites'],
): SetCompanySitesAction => ({
	type: AccountsActionTypes.SET_COMPANY_SITES,
	payload: { companySites: sortObjectByField(companySites, 'name') },
});

export const setCompanies = (
	companies: AccountsState['companies'],
): SetCompaniesAction => ({
	type: AccountsActionTypes.SET_COMPANIES,
	payload: { companies: sortObjectByField(companies, 'name') },
});

export const setLoadingUsers = (
	loadingUsers: AccountsState['loadingUsers'],
): SetLoadingUsersAction => ({
	type: AccountsActionTypes.SET_LOADING_USERS,
	payload: { loadingUsers },
});

export const setLoadingUserInvites = (
	loadingUserInvites: AccountsState['loadingUserInvites'],
): SetLoadingUserInvitesAction => ({
	type: AccountsActionTypes.SET_LOADING_USER_INVITES,
	payload: { loadingUserInvites },
});

export const setLoadingNewAccounts = (
	loadingNewAccounts: AccountsState['loadingNewAccounts'],
): SetLoadingNewAccountsAction => ({
	type: AccountsActionTypes.SET_LOADING_NEW_ACCOUNTS,
	payload: { loadingNewAccounts },
});
