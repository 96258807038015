import SummarizeIcon from '@mui/icons-material/Summarize';
import {
	Checkbox,
	Divider,
	Grid,
	MenuItem,
	Stack,
	TextField,
	TextFieldProps,
	Typography,
} from '@mui/material';
import firebase from 'firebase';
import { cloneDeep } from 'lodash';
import React, {
	ForwardedRef,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import {
	License,
	LicenseHumanName,
	Residency,
	ResidencyHumanName,
	UserDetails,
	UserProfile,
} from '../../constants/Common';
import { Timestamp } from '../../firebase/firebase';
import firebaseApi from '../../firebase/firebaseApi';
import { DatePicker } from '../../providers/LocalizationProvider';
import { capitaliseWords } from '../helpers/stringHelpers';
import { UploadImageButton } from '../Image/UploadImageButton';
import { UploadFile } from '../UploadFile/UploadFile';
import { HandlerEmployeeProfileActivityTimes } from './HandlerEmployeeProfileActivityTimes';

const isResidency = (residency: string): residency is Residency =>
	residency in ResidencyHumanName;
const isLicense = (license: string): license is License =>
	license in LicenseHumanName;
type EmployeeErrorType = Partial<
	Record<
		keyof Pick<UserProfile, 'location' | 'displayName' | 'dateOfBirth'>,
		string
	>
>;

export type HandlerEmployeeEditHandle = {
	save: () => Promise<void>;
};

type HandlerEmployeeEditProps = {
	employee: UserProfile;
	children: React.ReactNode;
	onFinish: () => void;
};

const HandlerEmployeeEditWithRef = (
	{ employee, children, onFinish }: HandlerEmployeeEditProps,
	ref: ForwardedRef<HandlerEmployeeEditHandle>,
): React.ReactElement => {
	const [updateEmployee, setUpdateEmployee] = useState(employee);
	const [imageUpload, setImageUpload] = useState<Blob | null>(null);
	const [cvUpload, setCvUpload] = useState<Blob | null>(null);
	const [previewURL, setPreviewURL] = useState<string>('');
	const [employeeError, setEmployeeError] = useState<EmployeeErrorType>({});

	useEffect(() => {
		if (imageUpload) {
			setPreviewURL(URL.createObjectURL(imageUpload));
		}
	}, [imageUpload]);

	const saveUpdateEmployee = async (): Promise<void> => {
		/** If file selected then upload it to storage and get a new download url - noop otherwise */
		const updateFile =
			(
				blob: Blob | null,
				path: string,
			): (() => Promise<string | undefined>) =>
			async () => {
				if (blob) {
					const storageRef = firebase
						.storage()
						.ref()
						.child(`${path}/${updateEmployee.id}`);

					await storageRef.put(blob);

					return storageRef.getDownloadURL();
				} else {
					return undefined;
				}
			};

		const updatePhoto = updateFile(imageUpload, 'profilepictures');
		const updateCV = updateFile(cvUpload, 'cvs');

		/** Delete CV if necessary */
		const deleteCV = async (): Promise<void> => {
			if (employee.cv && !updateEmployee.cv) {
				await firebaseApi.deleteUserProfileCV(updateEmployee.id);
			}
		};

		const updateUserProfiles = async (
			profileImg?: string,
			cv?: string,
		): Promise<void> => {
			const update: Partial<UserProfile> = cloneDeep(updateEmployee);
			if (updateEmployee.displayName) {
				update.displayName = capitaliseWords(
					updateEmployee.displayName,
				);
			}
			if (profileImg) update.profileImg = profileImg;
			if (cv) update.cv = cv;

			await firebaseApi.updateUserProfile(updateEmployee.id, update);
		};

		const updateUserDetails = async (photoURL?: string): Promise<void> => {
			// I'm choosing not to update the individual first/last name fields as that is both fraught with danger
			// and a display name is potentially a distinct concept from legal first and last names
			const update: {
				displayName: UserDetails['displayName'];
				photoURL?: UserDetails['photoURL'];
			} = {
				displayName: updateEmployee.displayName,
				photoURL,
			};
			await firebaseApi.updateUserDisplayDetails(
				updateEmployee.id,
				update,
			);
		};

		/** Return true if all inputs valid
		 *  Note that dateOfBirth validation errors are handled by the component, not here
		 */
		const validateInput = (): boolean => {
			const error: EmployeeErrorType = {};
			// We can only check if people are setting an already set location to an empty string, as we do not have location by default
			if (employee.location && updateEmployee.location === '') {
				error.location = 'Location cannot be blank';
			}
			if (updateEmployee.displayName === '') {
				error.displayName = 'Name cannot be blank';
			}
			setEmployeeError(error);
			return Object.keys(error).length === 0;
		};

		if (!validateInput()) {
			return;
		}

		const photoUrl = await updatePhoto();
		const cvUrl = await updateCV();
		await Promise.all([
			deleteCV(),
			updateUserProfiles(photoUrl, cvUrl),
			updateUserDetails(photoUrl),
		]);
		onFinish();
	};

	useImperativeHandle(ref, () => ({
		save: saveUpdateEmployee,
	}));

	return (
		<Grid container spacing={2} alignItems="flex-start" pb={1}>
			<Grid item xs={8}>
				<TextField
					fullWidth
					error={!!employeeError.displayName}
					helperText={employeeError.displayName}
					value={updateEmployee.displayName}
					onChange={(event): void =>
						setUpdateEmployee((updateEmployee) => ({
							...updateEmployee,
							displayName: event.target.value,
						}))
					}
				/>
			</Grid>
			<Grid item xs={4} height="100%" alignSelf="center">
				{children}
			</Grid>
			<Grid container item xs={8} spacing={2} alignItems="center">
				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={6}>
					<Typography>Location</Typography>
				</Grid>
				<Grid item xs={6}>
					<TextField
						fullWidth
						error={!!employeeError.location}
						helperText={employeeError.location}
						value={updateEmployee.location ?? ''}
						onChange={(event): void =>
							setUpdateEmployee((updateEmployee) => ({
								...updateEmployee,
								location: event.target.value,
							}))
						}
					/>
				</Grid>

				<Grid item xs={6}>
					<Typography>Residency</Typography>
				</Grid>
				<Grid item xs={6}>
					<TextField
						select
						fullWidth
						value={updateEmployee.residency ?? ''}
						onChange={(event): void => {
							const value = event.target.value;
							if (isResidency(value))
								setUpdateEmployee((updateEmployee) => ({
									...updateEmployee,
									residency: value,
								}));
						}}>
						{Object.entries(ResidencyHumanName).map(
							([key, name]) => (
								<MenuItem value={key} key={key}>
									{name}
								</MenuItem>
							),
						)}
					</TextField>
				</Grid>

				<Grid item xs={6}>
					<Typography>License</Typography>
				</Grid>
				<Grid item xs={6}>
					<TextField
						select
						fullWidth
						value={updateEmployee.license ?? ''}
						onChange={(event): void => {
							const value = event.target.value;
							if (isLicense(value))
								setUpdateEmployee((updateEmployee) => ({
									...updateEmployee,
									license: value,
								}));
						}}>
						{Object.entries(LicenseHumanName).map(([key, name]) => (
							<MenuItem value={key} key={key}>
								{name}
							</MenuItem>
						))}
					</TextField>
				</Grid>

				<Grid item xs={6}>
					<Typography>Date of Birth</Typography>
				</Grid>
				<Grid item xs={6}>
					<DatePicker
						maxDate={new Date()}
						onError={(error): void => {
							setEmployeeError((prev) => ({
								...prev,
								dateOfBirth: error
									? 'Date of birth is in an incorrect format'
									: undefined,
							}));
						}}
						inputFormat="dd/MM/yyyy"
						value={updateEmployee.dateOfBirth?.toDate() ?? null}
						onChange={(date): void => {
							setUpdateEmployee((updateEmployee) => ({
								...updateEmployee,
								dateOfBirth: date
									? Timestamp.fromDate(date)
									: undefined,
							}));
						}}
						views={['year', 'month', 'day']}
						openTo="year"
						renderInput={(params: TextFieldProps): JSX.Element => (
							<TextField
								{...params}
								helperText={employeeError.dateOfBirth}
								fullWidth
							/>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<Typography>Transport</Typography>
				</Grid>
				<Grid item xs={6}>
					<Checkbox
						checked={updateEmployee.transport}
						onChange={(event): void =>
							setUpdateEmployee((updateEmployee) => ({
								...updateEmployee,
								transport: event.target.checked,
							}))
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<HandlerEmployeeProfileActivityTimes
					employee={employee}
					isEditing={true}
				/>
			</Grid>
			<Grid item xs={4} textAlign="center">
				<Stack spacing={1}>
					<UploadImageButton
						imageProps={{
							src: previewURL || employee.profileImg,
							alt: employee.displayName,
						}}
						setBlob={setImageUpload}
					/>
					<UploadFile
						label="CV"
						icon={<SummarizeIcon />}
						storageUrl={updateEmployee.cv}
						onUpload={(cv): void => {
							if (updateEmployee.cv) {
								// / remove cv from object
								setUpdateEmployee((prev) => {
									const { cv: _, ...newEmployee } = prev;
									return newEmployee;
								});
							} else {
								setCvUpload(cv);
							}
						}}
					/>
				</Stack>
			</Grid>
		</Grid>
	);
};

export const HandlerEmployeeEdit = forwardRef<
	HandlerEmployeeEditHandle,
	HandlerEmployeeEditProps
>(HandlerEmployeeEditWithRef);
