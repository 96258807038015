import { StripeGetCustomerOutstandingPaymentsResponse } from '../constants/Common';
import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export const fetchCustomerOutstandingPayments = async (
	abortSignal: AbortSignal,
	user: User,
): Promise<StripeGetCustomerOutstandingPaymentsResponse | undefined> => {
	const response =
		await makeCloudFunctionRequestDeprecated<StripeGetCustomerOutstandingPaymentsResponse>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.StripeGetCustomerOutstandingPayments,
				method: 'GET',
				services: defaultCloudFunctionServices,
			},
		);

	return response;
};
