import { Box, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Contract } from '../../../../constants/Contract';
import firebaseApi from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import {
	BaseIntegrationLink,
	InvoicingContractElement,
} from '../../../../models/Integrations/IntegrationElements';
import { ErrorFooter } from '../ActivityAndLeaveMapping/ErrorFooter';
import { ContractIntegrationTabProps } from '../IntegrationTab';
import { ContractMappingTable } from './ContractMappingTable';

export const ContractsMappingTab = ({
	user,
	userDetails,
	integration,
	integrationCollection,
	fetchData,
}: ContractIntegrationTabProps): JSX.Element => {
	const abortSignal = useAbortController();
	const [allLoading, setAllLoading] = useState({
		loadingInvoicingContracts: true,
		loadingUserInvoicingContracts: true,
	});
	const [error, setError] = useState<string | null>(null);

	const [contractsMapping, setContractsMapping] = useState<
		Record<string, Contract>
	>({});
	const [userInvoicingContracts, setUserInvoicingContracts] = useState<
		Record<string, BaseIntegrationLink>
	>({});
	const [invoicingContracts, setInvoicingContracts] = useState<
		InvoicingContractElement[]
	>([]);

	const getInvoicingContractIDs = useCallback(
		async (abortSignal: AbortSignal) => {
			const invoicingContractIDs = await fetchData(abortSignal, user);
			setAllLoading((prev) => ({
				...prev,
				loadingInvoicingContracts: true,
			}));
			if (invoicingContractIDs) {
				setInvoicingContracts(invoicingContractIDs.contracts);
				setAllLoading((prev) => ({
					...prev,
					loadingInvoicingContracts: false,
				}));
				if (invoicingContractIDs.contracts.length === 0) {
					setError(
						`No Placements available from ${integration.type}. Please ensure you have your invoicing system set up.`,
					);
				} else {
					setError(null);
				}
			} else {
				if (!abortSignal.aborted) {
					setError(
						`Unable to get Placements from ${integration.type}. Please try again.`,
					);
					setInvoicingContracts([]);
					setAllLoading((prev) => ({
						...prev,
						loadingInvoicingContracts: false,
					}));
				}
			}
		},
		[fetchData, integration.type, user],
	);

	useEffect(() => {
		const contractsSub = firebaseApi.contractsBySupplierCompanySubscription(
			userDetails.companyID,
			(contracts) => {
				setContractsMapping(contracts);
			},
		);

		const invoicingintegrationsSub =
			firebaseApi.integrationPlacementLinksSubscription(
				integrationCollection,
				integration.companyID,
				(invoicingContracts) => {
					setUserInvoicingContracts(invoicingContracts);
					setAllLoading((prev) => ({
						...prev,
						loadingUserInvoicingContracts: false,
					}));
				},
			);

		getInvoicingContractIDs(abortSignal);

		return () => {
			contractsSub();
			invoicingintegrationsSub();
		};
	}, [
		getInvoicingContractIDs,
		integration.companyID,
		userDetails.companyID,
		abortSignal,
		integrationCollection,
	]);

	const loading = Object.values(allLoading).some((loading) => loading);

	return (
		<Box py={2}>
			<Paper id="boxList" elevation={1}>
				<ContractMappingTable
					headerTitle={{
						left: 'Contract',
						right: 'Invoicing Placement',
					}}
					dropdownLabel="Placement"
					dropdownHelperText={`Missing from ${integration.type}. Please re-map this Placement.`}
					mapping={contractsMapping}
					userInvoicingEntries={userInvoicingContracts}
					invoicingContractElements={invoicingContracts}
					invoicingIntegrationCompanyID={integration.companyID}
					fetchInvoicingIDs={getInvoicingContractIDs}
					loading={loading}
				/>
				{error !== null && <ErrorFooter error={error} />}
			</Paper>
		</Box>
	);
};
