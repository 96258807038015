import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
	Box,
	Button,
	Card,
	CardActionArea,
	Collapse,
	Grid,
	List,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { PPEItem } from './PPECard';
import { CartItem } from './PPECartItem';

export const PPECart = ({
	items,
	removeItem,
	updateItem,
	openModal,
	itemLimit,
}: {
	items: PPEItem[];
	removeItem: (index: number | null) => void;
	updateItem: (
		update:
			| [number, 'quantity', number]
			| [number, 'size', string | null]
			| null,
	) => void;
	openModal: () => void;
	itemLimit: number;
}): JSX.Element => {
	const [total, setTotal] = useState(0);
	const [expanded, setExpanded] = useState(true);

	useEffect(() => {
		const sum = items.reduce(
			(prev, item) => prev + item.price * item.quantity,
			0,
		);
		setTotal(sum);
	}, [items, setTotal]);

	const GSTPercent = 0.15;
	const GSTInclusive = 1.15;
	const cartFooter = useCallback(
		() => (
			<Grid container style={styles.listViewFooter}>
				<Box style={styles.footerRow}>
					<Box style={styles.footerItem}>Subtotal: </Box>
					<Box style={styles.footerItem}>${total.toFixed(2)}</Box>
				</Box>
				<Box style={styles.footerRow}>
					<Box style={styles.footerItem}>GST:</Box>
					<Box style={styles.footerItem}>
						${(total * GSTPercent).toFixed(2)}
					</Box>
				</Box>
				<Box style={styles.footerRow}>
					<Box style={styles.footerTotal}>Total:</Box>{' '}
					<Box style={styles.footerTotal}>
						${(total * GSTInclusive).toFixed(2)}
					</Box>
				</Box>
				<Box style={styles.footerItem}>
					<Button
						variant="contained"
						disableElevation
						onClick={openModal}
						style={{
							width: '100%',
							backgroundColor: '#d65900',
							color: 'white',
						}}>
						Purchase
					</Button>
				</Box>
			</Grid>
		),
		[openModal, total],
	);

	return (
		<Card>
			<Grid container>
				<Grid item xs={12}>
					<CardActionArea
						onClick={(): void => setExpanded(!expanded)}>
						<Grid container style={styles.cartHeader}>
							<Grid item>Cart</Grid>
							<Grid item>
								{expanded ? (
									<KeyboardArrowDownIcon />
								) : (
									<KeyboardArrowLeftIcon />
								)}
							</Grid>
						</Grid>
					</CardActionArea>
				</Grid>
				<Grid item xs={12}>
					<Collapse in={expanded} timeout="auto">
						<List style={styles.cartBox}>
							{items.map((item, index) => (
								<CartItem
									ppeItem={item}
									key={index}
									index={index}
									removeItem={removeItem}
									updateItem={updateItem}
									itemLimit={itemLimit}
								/>
							))}
						</List>
					</Collapse>
					{cartFooter()}
				</Grid>
			</Grid>
		</Card>
	);
};

export const styles: Record<string, React.CSSProperties> = {
	listViewFooter: {
		padding: '5px',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
	},
	cartHeader: {
		fontSize: 26,
		padding: '10px',
		justifyContent: 'space-between',
	},
	cartBox: {
		maxHeight: 'calc(100vh - 355px)',
		overflowY: 'scroll',
		padding: '0',
	},
	footerRow: {
		display: 'flex',
		width: '75%',
	},
	footerTotal: {
		fontSize: 22,
		borderTop: '1px solid rgb(63,63,63)',
		padding: '5px 0',
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	footerItem: {
		fontSize: 18,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
	},
};
