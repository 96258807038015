import {
	Divider,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	Activity,
	DayString,
	dayStrings,
	Site,
} from '../../../../../constants/Common';
import { TimesheetSiteLog } from '../../../../../constants/Timesheet/Timesheet';
import { CreateState, NewActivity } from '../reducer';
import { BreaksCell } from './BreaksCell';
import { CreateColumnTitle } from './CreateColumnTitle';
import { CreateRow } from './CreateRow';
import { NotesCell } from './NotesCell';
import { SiteLogCell } from './SiteLogCell';
import { TimesheetHoursCell } from './TimesheetHoursCell';
import { TimesheetSectionNames } from './TimesheetSectionNames';

export type CreateWeekListProps = {
	activityOptions: Site['timesheetActivitiesV2'] | null;
	siteLogs: Record<
		DayString,
		{
			In: TimesheetSiteLog | null;
			Out: TimesheetSiteLog | null;
			formattedLogs: string | null;
		}
	>;
	activities: CreateState['activities'];
	addActivity: (day: DayString) => void;
	updateActivity: (activity: NewActivity) => void;
	deleteActivity: (day: DayString, id: string) => void;
	breaks: CreateState['breaks'];
	updateBreak: (day: DayString, breakHours: number) => void;
	notes: CreateState['notes'];
	updateNote: (day: DayString, note: string) => void;
};

export const CreateWeekList = ({
	activityOptions,
	siteLogs,
	activities,
	addActivity,
	updateActivity,
	deleteActivity,
	breaks,
	updateBreak,
	notes,
	updateNote,
}: CreateWeekListProps): JSX.Element => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const onUpdateActivity = (
		id: string,
		day: DayString,
		activity: Activity['activity'],
		hours: number,
	): void => {
		const activityUpdate = activity.id === '' ? null : activity;

		updateActivity({
			id,
			day,
			activity: activityUpdate,
			hours,
			hasError: false,
		});
	};
	return (
		<Stack
			data-testid="create-week-list"
			overflow="auto"
			height="100%"
			gap={1}
			pb={1}
			divider={
				<Divider
					color={theme.palette.neutral.light}
					sx={{ opacity: 0.5 }}
				/>
			}>
			{!isSmallScreen && (
				<CreateRow
					key="create-timesheet-column-title-row"
					customContainerProps={{
						'data-testid': 'create-timesheet-column-title-row',
					}}
					day={
						<CreateColumnTitle title={TimesheetSectionNames.DAY} />
					}
					sitelog={
						<CreateColumnTitle
							centerText
							title={TimesheetSectionNames.SITE_LOG_HOURS}
						/>
					}
					timesheetHours={
						<CreateColumnTitle
							centerText
							title={TimesheetSectionNames.TIMESHEET_HOURS}
						/>
					}
					breaks={
						<CreateColumnTitle
							centerText
							title={TimesheetSectionNames.UNPAID_BREAKS}
						/>
					}
					notes={
						<CreateColumnTitle
							centerText
							title={TimesheetSectionNames.NOTES}
						/>
					}
				/>
			)}
			{dayStrings.map((day) => (
				<CreateRow
					key={day}
					customContainerProps={{
						'data-testid': `create-timesheet-row-${day}`,
					}}
					day={<Typography fontWeight="inherit">{day}</Typography>}
					sitelog={<SiteLogCell siteLog={siteLogs[day]} />}
					timesheetHours={
						<TimesheetHoursCell
							disabled={activityOptions === null} // Todo: Implement disabled on save TL-2592
							siteActivities={activityOptions ?? []}
							activities={Object.values(activities[day])}
							addActivity={(): void => addActivity(day)}
							updateActivity={(
								id: string,
								activity: Activity['activity'],
								hours: number,
							): void =>
								onUpdateActivity(id, day, activity, hours)
							}
							deleteActivity={(id: string): void =>
								deleteActivity(day, id)
							}
						/>
					}
					breaks={
						<BreaksCell
							disabled={false} // Todo: Implement disabled on save TL-2592
							breakHours={breaks[day]}
							updateBreaks={(breakHours: number): void =>
								updateBreak(day, breakHours)
							}
						/>
					}
					notes={
						<NotesCell
							disabled={false} // Todo: Implement disabled on save TL-2592
							note={notes[day]}
							updateNote={(note: string): void =>
								updateNote(day, note)
							}
						/>
					}
				/>
			))}
		</Stack>
	);
};
