import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined';
import { Chip, SxProps, Theme, Tooltip } from '@mui/material';
import { UserInviteStatus } from '../../../constants/UserInvite';

export type UserInviteStatusChipProps = { status: UserInviteStatus };

export const UserInviteStatusChip = ({
	status,
}: UserInviteStatusChipProps): JSX.Element => {
	const chipStyle: SxProps<Theme> = {
		textAlign: 'right',
		minWidth: '90px',
	};

	switch (status) {
		case UserInviteStatus.Pending:
			return (
				<Tooltip title="Pending">
					<Chip
						icon={<OutboundOutlinedIcon color="secondary" />}
						label="Pending"
						color="secondary"
						sx={chipStyle}
						variant="filled"
						size="small"
					/>
				</Tooltip>
			);
		case UserInviteStatus.Accepted:
			return (
				<Tooltip title={status}>
					<Chip
						icon={<CheckCircleIcon color="success" />}
						label={status}
						color="success"
						sx={chipStyle}
						variant="filled"
						size="small"
					/>
				</Tooltip>
			);
		case UserInviteStatus.Expired:
			return (
				<Tooltip title={status}>
					<Chip
						icon={<HighlightOffIcon />}
						label={status}
						color="default"
						sx={chipStyle}
						variant="filled"
						size="small"
					/>
				</Tooltip>
			);
	}
};
