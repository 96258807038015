import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type NewStripeCustomerBody = {
	id: string;
	name: string;
	email: string | null;
};

export const fetchNewStripeCustomer = async (
	abortSignal: AbortSignal,
	user: User,
	companyID: string,
	companyName: string,
): Promise<boolean | undefined> => {
	return await makeCloudFunctionRequestReturnSuccessStatus<NewStripeCustomerBody>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.StripeCreateCustomer,
			method: 'POST',
			services: defaultCloudFunctionServices,
			body: { id: companyID, name: companyName, email: user.email },
		},
	);
};
