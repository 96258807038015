import ClearIcon from '@mui/icons-material/Clear';
import {
	AlertColor,
	Autocomplete,
	Button,
	FormControlLabel,
	Grid,
	IconButton,
	MenuItem,
	Paper,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { endOfWeek } from 'date-fns';
import { useEffect, useState } from 'react';
import cloudFunctionApi from '../../../../cloudfunctions';
import type {
	HandlerRequest,
	ManagementRequest,
	MultiTimesheetRequestBody,
	SeniorManagementRequest,
} from '../../../../cloudfunctions/fetchMultiTimesheet';
import {
	assertAccountType,
	Company,
	Minimal,
	Site,
	UserDetails,
} from '../../../../constants/Common';
import {
	SplitOptions,
	TempTimesheet,
	Timesheet,
	timesheetAccountType,
	TimesheetAccountType,
	ValidStatus,
} from '../../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../../constants/Timesheet/TimesheetStatus';
import type { User } from '../../../../firebase/firebase';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import { isManualIntegrationType } from '../../../../models/Integrations/Integration';
import { PayrollType } from '../../../../models/Integrations/PayrollIntegration';
import DateWeekSelector from '../../../DateWeekSelector/DateWeekSelector';
import { ExportStatuses } from '../../../helpers/fileDownloads';
import { MenuButton } from '../../../Menu/MenuButton';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';

const humanName: Record<SplitOptions, string> = {
	employer: 'Employer',
	site: 'Site',
};

const validStatuses: readonly ValidStatus[] = [
	TimesheetStatus.Approved,
	TimesheetStatus.Archived,
	TimesheetStatus.Submitted,
];

export type ExportFirebaseCalls =
	| 'getPayrollIntegration'
	| 'downloadStorageFile'
	| 'deleteStorageFile';

/** Typescript can be fairly stupid when it comes to constructing union types, so we must be explicit */
const createBody = (
	accountType: TimesheetAccountType,
	weekEnding: Date,
	siteID: string | null,
	statuses: ValidStatus[],
	filterBy?: Minimal<Company>[],
): MultiTimesheetRequestBody => {
	const filterExportBy =
		filterBy && filterBy.length > 0
			? filterBy.map((company) => company.id)
			: undefined;
	switch (accountType) {
		case 'seniorManagement': {
			const request: SeniorManagementRequest = {
				accountType: 'seniorManagement',
				splitBy: 'employer',
				week: weekEnding.getTime(),
				statuses,
				siteID,
				filterBy: filterExportBy,
				includeSiteLogs: true,
			};
			return request;
		}
		case 'management': {
			const request: ManagementRequest = {
				accountType: 'management',
				splitBy: 'employer',
				week: weekEnding.getTime(),
				statuses,
				filterBy: filterExportBy,
				includeSiteLogs: true,
			};
			return request;
		}
		case 'handler': {
			const request: HandlerRequest = {
				accountType: 'handler',
				splitBy: 'site',
				week: weekEnding.getTime(),
				statuses,
				filterBy: filterExportBy,
				includeSiteLogs: true,
			};
			return request;
		}
		default:
			throw new Error(`Unexpected accountType: ${accountType}`);
	}
};

type ExportProps = {
	user: User;
	allTimesheets: (TempTimesheet | Timesheet)[];
	userDetails: UserDetails;
	sites: Minimal<Site>[];
	companies: Record<string, Company>;
	weekEnding: Date;
	setWeekEnding: (date: Date) => void;
	firebaseApi: Pick<FirebaseApi, ExportFirebaseCalls>;
	downloadFile: (fileURL: string, fileName: string) => void;
};

export const Export = ({
	allTimesheets,
	user,
	userDetails,
	sites,
	companies,
	weekEnding,
	setWeekEnding,
	firebaseApi,
	downloadFile,
}: ExportProps): JSX.Element => {
	assertAccountType<TimesheetAccountType>(
		userDetails.accountType,
		timesheetAccountType,
	);
	const abortSignal = useAbortController();

	const [payrollIntegrationType, setPayrollIntegrationType] =
		useState<PayrollType>();
	const [exportStatus, setExportStatus] = useState<ExportStatuses>('none');
	const [statuses, setStatuses] = useState<ValidStatus[]>([
		TimesheetStatus.Approved,
		TimesheetStatus.Submitted,
	]);
	const [statusError, setStatusError] = useState<string>();
	const [filters, setFilters] = useState<Minimal<Company>[]>([]);
	const [requestBody, setRequestBody] = useState<MultiTimesheetRequestBody>(
		createBody(
			userDetails.accountType,
			weekEnding,
			userDetails.siteID,
			statuses,
		),
	);
	const [siteID, setSiteID] = useState<string>('');

	const validFilters = Object.values(companies);
	const allowedPayrollIntegration =
		userDetails.accountType === 'handler' ||
		userDetails.accountType === 'seniorManagement';
	const canFilterOnSites = 'siteID' in requestBody;

	useEffect(() => {
		assertAccountType<TimesheetAccountType>(
			userDetails.accountType,
			timesheetAccountType,
		);
		setRequestBody(
			createBody(
				userDetails.accountType,
				weekEnding,
				siteID || null,
				statuses,
				filters,
			),
		);
	}, [userDetails.accountType, weekEnding, siteID, filters, statuses]);

	useEffect(() => {
		const getPayrollIntegration = async (): Promise<void> => {
			if (!allowedPayrollIntegration) {
				return;
			}

			const companyIntegration = await firebaseApi.getPayrollIntegration(
				userDetails.companyID,
			);
			setPayrollIntegrationType(
				companyIntegration ? companyIntegration.type : undefined,
			);
		};
		getPayrollIntegration();
	}, [allowedPayrollIntegration, firebaseApi, userDetails.companyID]);

	const handleCSVDownload = async (): Promise<void> => {
		if (allTimesheets.length > 0 && user) {
			try {
				setExportStatus('loading');
				const response = await cloudFunctionApi.payrollTimesheetExport(
					abortSignal,
					user,
					weekEnding,
				);
				const fileName = response?.fileName;
				if (fileName) {
					await firebaseApi.downloadStorageFile(
						fileName,
						`payrollTimesheetExports/${userDetails.companyID}`,
					);
					await firebaseApi.deleteStorageFile(
						`payrollTimesheetExports/${userDetails.companyID}/${fileName}`,
					);
					setExportStatus('done');
				} else {
					setExportStatus('error');
				}
			} catch (err) {
				setExportStatus('error');
			}
		} else {
			setExportStatus('zipEmpty');
		}
		return Promise.resolve();
	};

	const submitRequest = async (): Promise<void> => {
		if (user === null) {
			return;
		} else if (!requestBody.statuses || requestBody.statuses.length === 0) {
			setStatusError('You must select at least one status');
			return;
		} else if (requestBody.statuses.length > 10) {
			// Not relevant atm, but you never know...
			setStatusError('You cannot select more than 10 statuses');
			return;
		}
		setStatusError(undefined);

		setExportStatus('loading');
		const response = await cloudFunctionApi.fetchMultiTimesheet(
			abortSignal,
			user,
			requestBody,
		);
		if (response === undefined || response.size < 100) {
			// no way any valid pdf is gonna be less than 100 bytes
			if (!abortSignal.aborted) {
				setExportStatus('zipEmpty');
			}
			return;
		}
		// we do these replacements to prevent issues with special characters in filesystems
		const modifiedDateString = new Date()
			.toLocaleString()
			.replace(/:/g, '.')
			.replace(/[/]/g, '-');

		downloadFile(
			response.blobUrl,
			`Timesheet export - ${modifiedDateString}.zip`,
		);
		setExportStatus('done');
	};

	const snackBarContent: Record<
		ExportStatuses,
		{ text: string; severity: AlertColor }
	> = {
		none: { text: '', severity: 'info' },
		zipEmpty: {
			severity: 'error',
			text: 'No timesheets were found that matched these filters',
		},
		error: {
			severity: 'error',
			text: 'Could not download requested timesheets',
		},
		done: {
			severity: 'success',
			text: 'Timesheets exported and downloaded',
		},
		loading: {
			severity: 'info',
			text: 'Downloading your Timesheets. This may take 10-60 seconds',
		},
		warning: {
			severity: 'warning',
			text: '', // currently unsued here,
		},
	};

	return (
		<>
			<Paper sx={{ mb: 1, padding: 2 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h5">
							Export Multiple Timesheets
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">
							{`Export all timesheets for a given week. You may filter which timesheets will be included by their status. These will be downloaded as a zip, with a file for each ${humanName[
								requestBody.splitBy
							].toLowerCase()}.`}
						</Typography>
					</Grid>
					<Grid item xs={canFilterOnSites && sites.length ? 6 : 12}>
						<Autocomplete
							fullWidth
							multiple
							disableCloseOnSelect
							options={validStatuses}
							value={statuses}
							onChange={(_, statuses): void =>
								setStatuses(statuses)
							}
							renderInput={(params): JSX.Element => (
								<TextField
									{...params}
									label="Status"
									error={!!statusError}
									helperText={statusError}
								/>
							)}
						/>
					</Grid>
					{canFilterOnSites && (
						<Grid item xs={6}>
							<TextField
								fullWidth
								select
								label="Site (Optional)"
								variant="outlined"
								value={siteID}
								onChange={(event): void =>
									setSiteID(event.target.value)
								}
								InputProps={{
									endAdornment: (
										<IconButton
											sx={{
												display: siteID
													? 'inherit'
													: 'none',
											}}
											onClick={(): void => setSiteID('')}>
											<ClearIcon />
										</IconButton>
									),
								}}>
								{sites.map((site) => (
									<MenuItem key={site.id} value={site.id}>
										{site.name}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					)}
					{'filterBy' in requestBody && (
						<Grid item xs={12}>
							<Autocomplete
								fullWidth
								multiple
								disableCloseOnSelect
								options={validFilters}
								value={filters}
								onChange={(_, change): void => {
									setFilters(change);
								}}
								getOptionLabel={(filterOption): string =>
									filterOption.name
								}
								isOptionEqualToValue={(
									option,
									value,
								): boolean => option.id === value.id}
								renderOption={(
									props,
									filterOption,
								): JSX.Element => (
									<MenuItem
										{...props}
										key={filterOption.id}
										value={filterOption.id}>
										{filterOption.name}
									</MenuItem>
								)}
								renderInput={(params): JSX.Element => (
									<TextField
										{...params}
										label="Filter to company (optional)"
									/>
								)}
							/>
						</Grid>
					)}
					<Grid item xs={6}>
						<DateWeekSelector
							date={weekEnding}
							onChange={(date: Date | null): void => {
								setWeekEnding(date ?? endOfWeek(new Date()));
							}}
							textFieldProps={{
								// don't know why, but it needs to be offset by this much
								sx: { mt: '8px' },
							}}
							allowFuture={true}
							weekEnding
						/>
					</Grid>
					<Grid item xs={6} container justifyContent="center">
						<FormControlLabel
							labelPlacement="start"
							label="Include Site Logs?"
							sx={{ height: '100%', textAlign: 'center' }}
							control={
								<Switch
									checked={requestBody.includeSiteLogs}
									onChange={(event): void =>
										setRequestBody((body) => ({
											...body,
											includeSiteLogs:
												event.target.checked,
										}))
									}
								/>
							}
						/>
					</Grid>
					{payrollIntegrationType &&
					isManualIntegrationType(payrollIntegrationType) ? (
						<Grid item xs={12}>
							<MenuButton
								buttonText="Download"
								options={[
									{
										name: 'Zip Download',
										onClick: submitRequest,
									},
									{
										name: `${payrollIntegrationType} CSV Download`,
										onClick: handleCSVDownload,
									},
								]}
								buttonProps={{
									sx: {
										height: '85%',
										width: '100%',
										mt: '8px',
									},
									variant: 'outlined',
								}}
							/>
						</Grid>
					) : (
						<Grid item xs={12}>
							<Button
								sx={{
									height: '85%',
									width: '100%',
									mt: '8px',
								}}
								variant="outlined"
								onClick={submitRequest}>
								Download
							</Button>
						</Grid>
					)}
				</Grid>
			</Paper>

			<CustomSnackBar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={exportStatus !== 'none'}
				onClose={(): void => setExportStatus('none')}
				snackBarText={snackBarContent[exportStatus].text}
				severity={snackBarContent[exportStatus].severity}
				loading={exportStatus === 'loading'}
			/>
		</>
	);
};
