import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type InvoicingTriggerBody = {
	timesheetID: string;
};

type InvoicingTriggerReturn = boolean;

export const invoicingIntegrationsTrigger = async (
	abortSignal: AbortSignal,
	user: User,
	timesheetID: string,
): Promise<InvoicingTriggerReturn> =>
	await makeCloudFunctionRequestReturnSuccessStatus<InvoicingTriggerBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.InvoicingIntegrationsTrigger,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: { timesheetID },
	});
