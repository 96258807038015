import cloudFunctionApi from '../../../cloudfunctions';
import { UserProps } from '../../../constants/Common';
import { useAccountsContext } from '../AccountsWrapper';
import { UserInvites } from './UserInvites';

export const UserInvitesWrapper = ({
	userDetails,
	user,
}: Required<UserProps>): JSX.Element => {
	const { context } = useAccountsContext();

	return (
		<UserInvites
			user={user}
			userDetails={userDetails}
			userCompany={context.userCompany}
			userInvites={context.userInvites}
			companies={context.companies}
			sites={{ ...context.sites, ...context.companySites }}
			loading={context.loadingUserInvites}
			cloudFunctionApi={cloudFunctionApi}
		/>
	);
};
