import { AccountsActions, AccountsActionTypes, AccountsState } from './actions';

export const createInitialAccountsState = (): AccountsState => ({
	userCompany: null,
	users: {},
	userInvites: {},
	unapprovedUsers: {},
	sites: {},
	companySites: {},
	companies: {},
	loadingUsers: true,
	loadingUserInvites: true,
	loadingNewAccounts: true,
});

export const accountsReducer = (
	state: AccountsState,
	action: AccountsActions,
): AccountsState => {
	switch (action.type) {
		case AccountsActionTypes.SET_USER_COMPANY:
			return {
				...state,
				userCompany: action.payload.company,
			};

		case AccountsActionTypes.SET_USERS:
			return {
				...state,
				users: action.payload.users,
			};

		case AccountsActionTypes.SET_USER_INVITES:
			return {
				...state,
				userInvites: action.payload.userInvites,
			};

		case AccountsActionTypes.SET_UNAPPROVED_USERS:
			return {
				...state,
				unapprovedUsers: action.payload.unapprovedUsers,
			};

		case AccountsActionTypes.SET_SITES: {
			return {
				...state,
				sites: action.payload.sites,
			};
		}

		case AccountsActionTypes.SET_COMPANY_SITES: {
			return {
				...state,
				companySites: action.payload.companySites,
			};
		}

		case AccountsActionTypes.SET_COMPANIES:
			return {
				...state,
				companies: action.payload.companies,
			};

		case AccountsActionTypes.SET_LOADING_USERS:
			return {
				...state,
				loadingUsers: action.payload.loadingUsers,
			};

		case AccountsActionTypes.SET_LOADING_USER_INVITES:
			return {
				...state,
				loadingUserInvites: action.payload.loadingUserInvites,
			};

		case AccountsActionTypes.SET_LOADING_NEW_ACCOUNTS:
			return {
				...state,
				loadingNewAccounts: action.payload.loadingNewAccounts,
			};

		default:
			return state;
	}
};
