import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type InductionStepCreateRequest = {
	siteID: string;
	title: string;
	body: string;
	imageBlob: string; // base64 encoded
	position: number;
};

export const inductionStepCreate = async (
	abortSignal: AbortSignal,
	user: User,
	siteID: string,
	title: string,
	body: string,
	imageBlob: string,
	position: number,
): Promise<boolean | undefined> => {
	const bodyData: InductionStepCreateRequest = {
		siteID,
		title,
		body,
		position,
		imageBlob,
	};

	return await makeCloudFunctionRequestReturnSuccessStatus<InductionStepCreateRequest>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.CreateInductionStep,
			method: 'POST',
			services: defaultCloudFunctionServices,
			body: bodyData,
		},
	);
};
