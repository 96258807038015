import type { Timestamp } from '../firebase/firebase';

export enum SafetyCourseType {
	SiteSafe = 'SiteSafe',
	ConstructSafe = 'ConstructSafe',
}
export const safetyCourseTypes = [
	SafetyCourseType.SiteSafe,
	SafetyCourseType.ConstructSafe,
] as const;

export const safetyCourseTypeNames: Record<SafetyCourseType, string> = {
	SiteSafe: 'Site Safe',
	ConstructSafe: 'Construct Safe',
};

export enum WorkerTypes {
	Employee = 'Employee',
}

type Course =
	| {
			id: string;
			name: string;
	  } & (
			| {
					type: SafetyCourseType.SiteSafe;
					expiryDate: Timestamp;
			  }
			| {
					type: SafetyCourseType.ConstructSafe;
					expiryDate: null;
			  }
	  );

type Worker = {
	type: WorkerTypes.Employee;
	id: string;
	name: string;
	companyID: string;
	company: string;
};

export type SafetyCourse = {
	id: string;
	dateCreated: Timestamp;
	course: Course;
	worker: Worker;
	createdBy: {
		id: string;
		name: string;
		companyID: string;
		company: string;
	};
};

export const safetyCourseStatus = {
	Valid: 'Valid',
	Expired: 'Expired',
	NotFound: 'NotFound',
} as const;
export type SafetyCourseStatus =
	(typeof safetyCourseStatus)[keyof typeof safetyCourseStatus];

export const safetyCourseStatusNames: Record<SafetyCourseStatus, string> = {
	Valid: 'Valid',
	Expired: 'Expired',
	NotFound: 'Not Found',
};

export type SafetyCourseValidationRequest = {
	id?: string;
	safetyCourseType: SafetyCourseType;
	safetyCourseID: string;
};

export type SafetyCourseValidationResponse = {
	id: string;
	name: string;
} & (
	| {
			type: SafetyCourseType.SiteSafe;
			expiryDate: Date;
	  }
	| {
			type: SafetyCourseType.ConstructSafe;
			expiryDate: null;
	  }
);

export enum SafetyCourseSelectOption {
	Existing = 'Existing',
	New = 'New',
}

export const safetyCourseSelectOptionValues = {
	[SafetyCourseSelectOption.Existing]: 'Use Existing',
	[SafetyCourseSelectOption.New]: 'Create New',
} as const;

// Extending the SafetyCourseStatus with additional status
export const SafetyCourseValidationStatus = {
	...safetyCourseStatus,
	Pending: 'Pending',
} as const;

export type SafetyCourseValidationStatus =
	(typeof SafetyCourseValidationStatus)[keyof typeof SafetyCourseValidationStatus];
