import { Activity, UserDetails } from '../../constants/Common';
import { Timesheet } from '../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../constants/Timesheet/TimesheetStatus';
import { Timestamp } from '../../firebase/firebase';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { AllowDateOrTimestamp } from '../helpers/dateUtilities';

export type TimesheetActionOnClick = (
	timesheet: Timesheet,
	activities: Record<string, Activity>,
) => Promise<void>;

export type TimesheetAction = {
	onClick: TimesheetActionOnClick;
	buttonText: string;
	disabled?: boolean;
};

export type HydratedTimesheetAction = Omit<TimesheetAction, 'onClick'> & {
	onClick: () => ReturnType<TimesheetAction['onClick']>;
};

type GetTimesheetAction = (
	timesheetStatus: TimesheetStatus.Active | TimesheetStatus.Submitted,
	preApproval: boolean,
	isCurrent?: boolean,
) => TimesheetAction;

type FirebaseUpdateTimesheetActivities = Pick<
	FirebaseApi,
	'updateTimesheetActivities' | 'preApproveTimesheet'
>;

const approve =
	(
		userDetails: UserDetails,
		firebaseApi: FirebaseUpdateTimesheetActivities,
	): TimesheetActionOnClick =>
	async (timesheet: Timesheet, activities: Record<string, Activity>) => {
		const updatedTimesheet: Pick<
			Timesheet,
			| 'id'
			| 'timesheetStatus'
			| 'lastEditedBy'
			| 'reviewedAt'
			| 'reviewer'
		> = {
			id: timesheet.id,
			timesheetStatus: TimesheetStatus.Approved,
			lastEditedBy: {
				name: userDetails.displayName,
				id: userDetails.userID,
			},
			reviewer: {
				name: userDetails.displayName,
				id: userDetails.userID,
			},
			reviewedAt: Timestamp.now(),
		};
		const updatedActivities: Pick<
			AllowDateOrTimestamp<Activity>,
			'id' | 'status' | 'finalReviewBy' | 'finalReviewAt'
		>[] = Object.values(activities).map((activity: Activity) => ({
			id: activity.id,
			status: TimesheetStatus.Approved,
			finalReviewBy: userDetails.displayName,
			finalReviewAt: new Date(),
		}));

		await firebaseApi.updateTimesheetActivities(
			updatedTimesheet,
			updatedActivities,
		);
	};

const preApprove =
	(
		userDetails: UserDetails,
		firebaseApi: FirebaseUpdateTimesheetActivities,
	): TimesheetActionOnClick =>
	async (timesheet: Timesheet) => {
		const updatedTimesheet: Pick<
			Timesheet,
			'id' | 'preApproval' | 'lastEditedBy'
		> = {
			id: timesheet.id,
			lastEditedBy: {
				name: userDetails.displayName,
				id: userDetails.userID,
			},
			preApproval: {
				reviewer: {
					name: userDetails.displayName,
					id: userDetails.userID,
				},
				reviewedAt: Timestamp.now(),
				weekEnding: timesheet.weekEnding,
				hours: timesheet.hours,
			},
		};

		await firebaseApi.preApproveTimesheet(updatedTimesheet);
	};

const submit =
	(
		userDetails: UserDetails,
		firebaseApi: FirebaseUpdateTimesheetActivities,
	): TimesheetActionOnClick =>
	async (timesheet: Timesheet, activities: Record<string, Activity>) => {
		const updatedTimesheet: Pick<
			Timesheet,
			'id' | 'timesheetStatus' | 'dateSubmitted' | 'lastEditedBy'
		> = {
			id: timesheet.id,
			timesheetStatus: TimesheetStatus.Submitted,
			dateSubmitted: Timestamp.now(),
			lastEditedBy: {
				name: userDetails.displayName,
				id: userDetails.userID,
			},
		};
		const updatedActivities: Pick<
			AllowDateOrTimestamp<Activity>,
			'id' | 'status'
		>[] = Object.values(activities).map((activity: Activity) => ({
			id: activity.id,
			status: TimesheetStatus.Submitted,
		}));

		await firebaseApi.updateTimesheetActivities(
			updatedTimesheet,
			updatedActivities,
		);
	};

const unsubmit =
	(
		userDetails: UserDetails,
		firebaseApi: FirebaseUpdateTimesheetActivities,
	): TimesheetActionOnClick =>
	async (timesheet: Timesheet, activities: Record<string, Activity>) => {
		const updatedTimesheet: Pick<
			Timesheet,
			'id' | 'timesheetStatus' | 'dateSubmitted' | 'lastEditedBy'
		> = {
			id: timesheet.id,
			timesheetStatus: TimesheetStatus.Active,
			dateSubmitted: Timestamp.now(),
			lastEditedBy: {
				name: userDetails.displayName,
				id: userDetails.userID,
			},
		};
		const updatedActivities: Pick<
			AllowDateOrTimestamp<Activity>,
			'id' | 'status'
		>[] = Object.values(activities).map((activity: Activity) => ({
			id: activity.id,
			status: TimesheetStatus.Active,
		}));

		await firebaseApi.updateTimesheetActivities(
			updatedTimesheet,
			updatedActivities,
		);
	};

export const getTimesheetActions = (
	userDetails: UserDetails,
	firebaseApi: FirebaseUpdateTimesheetActivities,
): GetTimesheetAction => {
	const approveAction = approve(userDetails, firebaseApi);
	const preApproveAction = preApprove(userDetails, firebaseApi);
	const submitAction = submit(userDetails, firebaseApi);
	const unsubmitAction = unsubmit(userDetails, firebaseApi);

	return (
		timesheetStatus: TimesheetStatus.Active | TimesheetStatus.Submitted,
		preApproval: boolean,
		isCurrent = false,
	): TimesheetAction => {
		const submitted = ((): TimesheetAction => {
			if (isCurrent) {
				return {
					onClick: unsubmitAction,
					buttonText: 'Unsubmit',
				};
			} else if (userDetails.disableTimesheetApproval) {
				return {
					onClick: preApproveAction,
					buttonText: 'Pre Approve',
					disabled: !!preApproval,
				};
			} else {
				return {
					onClick: approveAction,
					buttonText: 'Approve',
				};
			}
		})();

		const actions: Record<
			TimesheetStatus.Active | TimesheetStatus.Submitted,
			TimesheetAction
		> = {
			Submitted: submitted,
			Active: {
				onClick: isCurrent ? submitAction : unsubmitAction,
				buttonText: isCurrent ? 'Submit' : 'Unsubmit',
			},
		};
		return actions[timesheetStatus];
	};
};
