import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type PayrollTimesheetExportBody = {
	week: string;
};

type PayrollTimesheetExportResponse = {
	fileName: string;
};

export const payrollTimesheetExport = async (
	abortSignal: AbortSignal,
	user: User,
	week: Date,
): Promise<PayrollTimesheetExportResponse | undefined> => {
	return await makeCloudFunctionRequestDeprecated<
		PayrollTimesheetExportResponse,
		PayrollTimesheetExportBody
	>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.PayrollTimesheetExport,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: { week: week.toISOString() },
	});
};
