import { Box, Grid, Typography, useTheme } from '@mui/material';
import { endOfWeek } from 'date-fns';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { ExplicitAny, FixMeLater } from '../../../constants/AnyTypes';
import {
	SiteLog,
	SiteLogType,
	UserProps,
	accountTypes,
} from '../../../constants/Common';
import type { FirebaseApi } from '../../../firebase/firebaseApi';
import { DataTable } from '../../DataTable/DataTable';
import DateRangeSelector from '../../DateRangeSelector/DateRangeSelector';
import {
	formatDayOfWeek,
	formatHoursMinutes,
	formatSlashedDate,
} from '../../helpers/dateFormatters';
import { ShortDay, shortDays } from '../../helpers/dateUtilities';
import { weekCustomTableSort } from '../../helpers/muiDataTableCustomSorts';
import { sortObjectBySubField } from '../../helpers/sortHelpers';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { ReportConfigureHeader } from '../ReportConfigureHeader';
import { reportsCustomTableFilter } from '../ReportsCustomTableFilter';
import { ReportsStyleWrapper } from '../ReportsStyleWrapper';
import {
	FetchButtonState,
	SiteLogReportFirebaseCalls,
} from '../StateManagement/models';
import {
	DaySiteLogEntry,
	TypeSiteLogEntry,
	siteLogCustomSearch,
	siteLogReportCSVBody,
} from './siteLogReportCSVBody';

type SiteLogTableEntryDetails = {
	logs: DaySiteLogEntry;
	takenBy: { name: string };
	site: string;
	company: string;
	weekEnding: string;
};

export type SiteLogReportProps = UserProps & {
	startDateContext: { date: Date; setDate: Dispatch<SetStateAction<Date>> };
	endDateContext: { date: Date; setDate: Dispatch<SetStateAction<Date>> };
	firebaseApi: Pick<FirebaseApi, SiteLogReportFirebaseCalls>;
};

export const SiteLogReport = ({
	userDetails,
	startDateContext,
	endDateContext,
	firebaseApi,
}: SiteLogReportProps): JSX.Element => {
	const theme = useTheme();
	const [loading, setLoading] = useState<boolean>(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [tableHeader, setTableHeader] = useState('Site Log Report');
	const [fetchButtonText, setFetchButtonText] =
		useState<FetchButtonState>('Update');
	const [tempStartDate, setTempStartDate] = useState(startDateContext.date);
	const [tempEndDate, setTempEndDate] = useState(endDateContext.date);
	const [shouldUpdate, setShouldUpdate] = useState(false);
	const [siteLogTableData, setSiteLogTableData] = useState<
		Record<string, SiteLogTableEntryDetails>
	>({});
	const displayClient = userDetails.accountType === accountTypes.handler;
	const noMatchTableText = initialLoad ? (
		'Configure report information above and press update'
	) : loading ? (
		<LoadingDots />
	) : (
		'Sorry, no matching site logs found'
	);

	const tableData = Object.values(siteLogTableData);

	const customTableOptions: Partial<MUIDataTableOptions> = {
		filter: true,
		tableBodyHeight: 'calc(100vh - 424px)',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
		responsive: 'standard',
		isRowSelectable: () => false,
		onDownload: (buildHead, buildBody, columns, data) => {
			const modifiedData = siteLogReportCSVBody(data);

			return '\uFEFF' + buildHead(columns) + buildBody(modifiedData);
		},
		downloadOptions: {
			filename: tableHeader
				.replace(new RegExp('/', 'g'), '-')
				.replace(/\s/g, '_'),
		},
		customSearch: (searchString, currentRow) =>
			siteLogCustomSearch(searchString, currentRow as ExplicitAny),
	};

	const cols: MUIDataTableColumnDef[] = [
		{
			label: 'Employee',
			name: 'takenBy',
			options: {
				customBodyRender: (
					takenBy: SiteLogTableEntryDetails['takenBy'],
				): JSX.Element => (
					<>
						<Typography>{takenBy.name}</Typography>
					</>
				),
				filterType: 'custom',
				setCellProps: () => ({
					style: {
						padding: 8,
					},
				}),
				filterOptions: {
					names: [
						...new Set(tableData.map((data) => data.takenBy.name)),
					],
					logic: ({ name }: FixMeLater, filters, _): boolean =>
						filters.length ? !filters.includes(name) : false,
					display: reportsCustomTableFilter,
				},
			},
		},
		{
			label: displayClient ? 'Client' : 'Employer',
			name: 'company',
			options: {
				filterType: 'custom',
				setCellProps: () => ({
					style: {
						padding: 8,
					},
				}),
				filterOptions: {
					logic: (name, filters, _): boolean =>
						filters.length ? !filters.includes(name) : false,
					display: reportsCustomTableFilter,
				},
			},
		},
		{
			label: 'Site',
			name: 'site',
			options: {
				filterType: 'custom',
				setCellProps: () => ({
					style: {
						padding: 8,
					},
				}),
				filterOptions: {
					logic: (name, filters, _): boolean =>
						filters.length ? !filters.includes(name) : false,
					display: reportsCustomTableFilter,
				},
			},
		},
		{
			label: 'Week Ending',
			name: 'weekEnding',
			options: {
				filter: false,
				sortCompare: weekCustomTableSort,
				setCellProps: () => ({
					style: {
						padding: 8,
					},
				}),
			},
		},
		...shortDays.map((dayString, index) => ({
			name: 'logs',
			options: {
				setCellProps: () => ({
					style: {
						minWidth: '100px',
						verticalAlign: 'top',
						padding: 8,
						backgroundColor:
							index % 2 === 0 ? theme.palette.neutral.light : '',
					},
				}),
				label: dayString,
				customHeadLabelRender: () => renderWeekDaySiteLog(dayString),
				customBodyRender: (
					weeksLogs: SiteLogTableEntryDetails['logs'],
				): JSX.Element => {
					return (
						<Box width="100%">
							{renderDaySiteLog(weeksLogs[dayString])}
						</Box>
					);
				},
				fullWidth: true,
				filter: false,
				sort: false,
			},
		})),
	];

	const getSiteLogs = useCallback(async (): Promise<SiteLog[]> => {
		if (!userDetails.companyID) return [];
		switch (userDetails.accountType) {
			case accountTypes.handler:
				return await firebaseApi.getSiteLogsByCompanyDateRange(
					userDetails.companyID,
					startDateContext.date,
					endDateContext.date,
				);
			case accountTypes.management:
				if (!userDetails.siteID) return [];
				return await firebaseApi.getSiteLogsBySiteDateRange(
					userDetails.siteID,
					startDateContext.date,
					endDateContext.date,
				);
			case accountTypes.seniorManagement:
				return await firebaseApi.getSiteLogsBySiteCompanyDateRange(
					userDetails.companyID,
					startDateContext.date,
					endDateContext.date,
				);
			default:
				return [];
		}
	}, [
		userDetails.siteID,
		userDetails.accountType,
		userDetails.companyID,
		startDateContext.date,
		endDateContext.date,
		firebaseApi,
	]);

	useEffect(() => {
		setTempStartDate(startDateContext.date);
	}, [startDateContext.date]);

	useEffect(() => {
		setTempEndDate(endDateContext.date);
	}, [endDateContext.date]);

	useEffect(() => {
		setFetchButtonText('Update');
	}, [tempStartDate, tempEndDate]);

	useEffect(() => {
		if (!shouldUpdate) return;
		let mounted = true;

		const setSiteLogData = async (): Promise<void> => {
			setLoading(true);
			const siteLogs = await getSiteLogs();
			if (siteLogs.length < 1) {
				setLoading(false);
				setSiteLogTableData({});
				return;
			}
			const siteLogObject: Record<string, SiteLogTableEntryDetails> = {};
			for (const siteLog of siteLogs) {
				const logDayKey = formatDayOfWeek(
					siteLog.datetime.toDate(),
				) as ShortDay;
				const weekString = formatSlashedDate(
					endOfWeek(siteLog.datetime.toDate()),
				);
				const entryKey = weekString + siteLog.takenBy;
				if (!siteLogObject[entryKey]) {
					siteLogObject[entryKey] = {
						company: displayClient
							? siteLog.contractedTo?.name ?? 'Client Not Found'
							: siteLog.company,
						site: siteLog.site,
						takenBy: {
							name: siteLog.takenBy,
						},
						weekEnding: weekString,
						logs: {},
					};
				}

				const tableEntry = siteLogObject[entryKey];
				const tableDayEntry = tableEntry.logs[logDayKey];
				if (!tableDayEntry) {
					const newEntry: TypeSiteLogEntry = {
						[SiteLogType.In]: [],
						[SiteLogType.Out]: [],
					};
					newEntry[siteLog.type].push(siteLog);
					tableEntry.logs[logDayKey] = newEntry;
				} else {
					tableDayEntry[siteLog.type].push(siteLog);
				}
			}

			const sortedSiteLogObject = sortObjectBySubField(
				siteLogObject,
				'takenBy',
				'name',
			);
			if (mounted) {
				setSiteLogTableData(sortedSiteLogObject);
				setTableHeader(
					`Site Log Report from ${formatSlashedDate(
						startDateContext.date,
					)} - ${formatSlashedDate(endDateContext.date)}`,
				);
				setShouldUpdate(false);
				setLoading(false);
			}
		};
		setSiteLogData();
		return (): void => {
			mounted = false;
		};
	}, [
		userDetails.accountType,
		getSiteLogs,
		startDateContext.date,
		endDateContext.date,
		shouldUpdate,
		displayClient,
		firebaseApi,
	]);

	const handleUpdateOnClick = (): void => {
		if (fetchButtonText === 'Refresh') {
			setShouldUpdate(true);
		} else {
			if (initialLoad) setInitialLoad(false);
			setFetchButtonText('Refresh');
			startDateContext.setDate(tempStartDate);
			endDateContext.setDate(tempEndDate);
			setShouldUpdate(true);
		}
	};

	const renderDaySiteLog = (
		daysLogs: TypeSiteLogEntry | undefined,
	): JSX.Element => (
		<Grid container display="flex">
			<Grid
				item
				xs={6}
				display="flex"
				flexDirection="column"
				alignItems="flex-start">
				{daysLogs?.In &&
					daysLogs.In.map((log) => (
						<Typography
							key={log.datetime.toDate().getTime()}
							variant="body2">
							{formatHoursMinutes(log.datetime.toDate())}
						</Typography>
					))}
			</Grid>
			<Grid
				item
				xs={6}
				display="flex"
				flexDirection="column"
				alignItems="flex-end">
				{daysLogs?.Out &&
					daysLogs.Out.map((log) => (
						<Typography
							key={log.datetime.toDate().getTime()}
							variant="body2">
							{formatHoursMinutes(log.datetime.toDate())}
						</Typography>
					))}
			</Grid>
		</Grid>
	);

	const renderWeekDaySiteLog = (dayString: string): JSX.Element => (
		<Grid container p={0}>
			<Grid item xs={12} display="flex" justifyContent="center">
				{dayString}
			</Grid>
			<Grid item xs={6}>
				In
			</Grid>
			<Grid item xs={6} display="flex" justifyContent="flex-end">
				Out
			</Grid>
		</Grid>
	);

	const renderReportHeader = (
		<ReportConfigureHeader
			loading={loading}
			updateOnClick={handleUpdateOnClick}
			updateButtonText={fetchButtonText}
		/>
	);
	const renderDateSelector = (
		<DateRangeSelector
			startDateUseState={[tempStartDate, setTempStartDate]}
			endDateUseState={[tempEndDate, setTempEndDate]}
			textFieldProps={{
				size: 'small',
				fullWidth: true,
			}}
		/>
	);

	return (
		<Box flex="1">
			<ReportsStyleWrapper
				title={renderReportHeader}
				componentList={[
					{
						gridSize: 12,
						component: renderDateSelector,
						id: 'dateSelector',
					},
				]}
			/>
			<DataTable
				title={tableHeader}
				tableData={tableData}
				columns={cols}
				customTableOptions={customTableOptions}
			/>
		</Box>
	);
};
