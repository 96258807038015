import { UserInvite } from '../../constants/UserInvite';
import { Firestore, FirestoreDataConverter } from '../firebase';

const COLLECTION = 'userInvites';

const userInvitesConverter: FirestoreDataConverter<UserInvite> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) => snapshot.data() as UserInvite,
};

const subscribeUserInvitesByCompany = (
	companyID: string,
	callback: (userProfiles: UserInvite[]) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.where('company.id', '==', companyID)
		.withConverter(userInvitesConverter)
		.onSnapshot((snapshot) =>
			callback(snapshot.docs.map((doc) => doc.data())),
		);

const userInvitesFirebaseApi = {
	subscribeUserInvitesByCompany,
};

export default userInvitesFirebaseApi;
