import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type InductionStepUpdateRequest = {
	id: string;
	siteID: string;
	title?: string;
	body?: string;
	imageBlob?: string; // base64 encoded
	position?: number;
};

export const inductionStepUpdate = async (
	abortSignal: AbortSignal,
	user: User,
	id: string,
	siteID: string,
	title?: string,
	body?: string,
	imageBlob?: string,
	position?: number,
): Promise<boolean | undefined> => {
	const bodyData: InductionStepUpdateRequest = {
		id,
		siteID,
		title,
		body,
		imageBlob,
		position,
	};

	return await makeCloudFunctionRequestReturnSuccessStatus<InductionStepUpdateRequest>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.UpdateInductionStep,
			method: 'POST',
			services: defaultCloudFunctionServices,
			body: bodyData,
		},
	);
};
