import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type TriggerLeaveIntegrationBody = {
	leaveID: string;
};

type TriggerLeaveIntegrationResponse = boolean;

export const payrollTriggerLeaveIntegration = async (
	abortSignal: AbortSignal,
	user: User,
	leaveID: string,
): Promise<TriggerLeaveIntegrationResponse> =>
	await makeCloudFunctionRequestReturnSuccessStatus<TriggerLeaveIntegrationBody>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.PayrollTriggerLeaveIntegration,
			method: 'POST',
			services: defaultCloudFunctionServices,
			body: { leaveID },
		},
	);
