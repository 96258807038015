import { Box, Button, Stack } from '@mui/material';
import type {
	MUIDataTableColumnDef,
	MUIDataTableOptions,
} from 'mui-datatables';
import { useState } from 'react';
import type { CloudFunctionApi } from '../../../cloudfunctions';
import type { Template } from '../../../constants/Template';
import { TemplateStatuses } from '../../../constants/TemplateStatuses';
import type { User } from '../../../firebase/firebase';
import { useAbortController } from '../../../hooks/useAbortController';
import { DateDataTableWithID } from '../../DataTable/DateDataTableWithID';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { TemplatesTableTheme } from '../TableWrappers/TemplatesTableWrapper';
import { DeleteTemplateModal } from './DeleteTemplateModal';
import { TemplateSnackbar } from './TemplateSnackbar';
import { ViewTemplateDialog } from './ViewTemplateDialog';

export type TemplatesCloudFunctionCalls = 'deleteTemplate';

export type TemplatesProps = {
	user: User;
	cloudFunctionApi: Pick<CloudFunctionApi, TemplatesCloudFunctionCalls>;
	templates: Template[];
	selected: string | null;
	setSelected: (selected: string) => void;
	loading: boolean;
};

export const Templates = ({
	templates,
	selected,
	setSelected,
	loading,
	cloudFunctionApi,
	user,
}: TemplatesProps): JSX.Element => {
	const abortSignal = useAbortController();
	const [currentTemplateID, setCurrentTemplateID] = useState<string | null>(
		null,
	);
	const [deleteTemplateOpen, setDeleteTemplateOpen] = useState(false);
	const [templateStatus, setTemplateStatus] = useState<TemplateStatuses>(
		TemplateStatuses.None,
	);

	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		'Sorry, no documents found'
	);

	const currentTemplate = templates.find(
		(template) => template.id === currentTemplateID,
	);

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'name',
			label: 'Template',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '75%',
					},
				}),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				filter: false,
				setCellHeaderProps: () => ({
					style: {
						width: '25%',
					},
				}),
				customBodyRender: (id): JSX.Element => (
					<Stack direction="row" spacing={1} width="100%" pr={1}>
						<Box minWidth="50%">
							<Button
								fullWidth
								disabled={
									templateStatus === TemplateStatuses.Loading
								}
								variant="outlined"
								onClick={async (): Promise<void> => {
									setSelected(id);
									setDeleteTemplateOpen(true);
								}}>
								delete
							</Button>
						</Box>
						<Box minWidth="50%">
							<Button
								fullWidth
								disabled={
									templateStatus === TemplateStatuses.Loading
								}
								variant="contained"
								onClick={(): void => openOnClick(id)}>
								open
							</Button>
						</Box>
					</Stack>
				),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 302px)',
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
	};

	const openOnClick = (id: string): void => {
		setCurrentTemplateID(id);
	};

	const onClose = (): void => {
		setCurrentTemplateID(null);
	};

	const handleDeleteTemplate = async (id: string): Promise<void> => {
		try {
			setTemplateStatus(TemplateStatuses.Loading);
			await cloudFunctionApi.deleteTemplate(abortSignal, user, id);
			setTemplateStatus(TemplateStatuses.Done);
		} catch (err) {
			setTemplateStatus(TemplateStatuses.Error);
		}
	};

	return (
		<>
			<TemplateSnackbar
				event={templateStatus}
				open={
					templateStatus !== TemplateStatuses.None &&
					templateStatus !== TemplateStatuses.Loading
				}
				onClose={(): void => setTemplateStatus(TemplateStatuses.None)}
			/>
			{currentTemplate && (
				<ViewTemplateDialog
					open={!!currentTemplateID}
					template={currentTemplate}
					handleOnClose={onClose}
				/>
			)}
			<TemplatesTableTheme>
				<DateDataTableWithID
					tableData={templates}
					columns={columns}
					title="Templates"
					customTableOptions={tableOptions}
					selection={[selected, setSelected]}
				/>
			</TemplatesTableTheme>
			<DeleteTemplateModal
				loading={templateStatus === TemplateStatuses.Loading}
				open={deleteTemplateOpen}
				onClose={(): void => setDeleteTemplateOpen(false)}
				deleteTemplate={async (): Promise<void> => {
					if (selected) {
						await handleDeleteTemplate(selected);
					}
				}}
			/>
		</>
	);
};
