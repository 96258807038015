import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type TriggerTimesheetIntegrationBody = {
	timesheetID: string;
};

type TriggerTimesheetIntegrationReturn = boolean;

export const payrollTriggerTimesheetIntegration = async (
	abortSignal: AbortSignal,
	user: User,
	timesheetID: string,
): Promise<TriggerTimesheetIntegrationReturn> =>
	await makeCloudFunctionRequestReturnSuccessStatus<TriggerTimesheetIntegrationBody>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.PayrollTriggerTimesheetIntegration,
			method: 'POST',
			services: defaultCloudFunctionServices,
			body: { timesheetID },
		},
	);
