import {
	SafetyCourse,
	SafetyCourseType,
	WorkerTypes,
} from '../constants/SafetyCourse';
import { convertSafetyCourseCFResponse } from '../constants/SafetyCourseUtilities';
import { TimestampToString } from '../constants/TypescriptUtilities';
import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type CreateSafetyCourseRequest = {
	workerID: string;
	workerType: WorkerTypes.Employee;
	safetyCourseType: SafetyCourseType;
	safetyCourseID: string;
};

const createSafetyCourse = async (
	abortSignal: AbortSignal,
	user: User,
	request: CreateSafetyCourseRequest,
): Promise<SafetyCourse | undefined> => {
	const safetyCourseResponse = await makeCloudFunctionRequestDeprecated<
		TimestampToString<SafetyCourse>,
		CreateSafetyCourseRequest
	>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.CreateSafetyCourse,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: request,
	});

	if (!safetyCourseResponse) return;

	const safetyCourse = convertSafetyCourseCFResponse(safetyCourseResponse);
	return safetyCourse;
};

type UpdateSafetyCourseRequest = {
	workerID: string;
	workerType: WorkerTypes.Employee;
	safetyCourseType: SafetyCourseType;
	safetyCourseID: string;
};

const updateSafetyCourse = async (
	abortSignal: AbortSignal,
	user: User,
	request: UpdateSafetyCourseRequest,
): Promise<SafetyCourse | undefined> => {
	const safetyCourseResponse = await makeCloudFunctionRequestDeprecated<
		TimestampToString<SafetyCourse>,
		UpdateSafetyCourseRequest
	>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.UpdateSafetyCourse,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: request,
	});
	if (!safetyCourseResponse) return;

	const safetyCourse = convertSafetyCourseCFResponse(safetyCourseResponse);
	return safetyCourse;
};

export const safetyCourseFirestore = { createSafetyCourse, updateSafetyCourse };
