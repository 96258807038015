import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { ReactNode } from 'react';
import { FixMeLater } from '../../constants/AnyTypes';
import { Site } from '../../constants/Common';
import { useUserDetailsContext } from '../../providers/UserProvider';

enum SiteGroup {
	Recent = 'Recent',
	Other = 'Other',
}

type SiteWithGroup = Site & {
	group: SiteGroup;
};

export type SitesAutocompleteProps = {
	onChange: (value: string) => void;
	value: string | null;
	label: string;
	sites: Record<string, Site>;
	disabled?: boolean;
	error?: boolean;
	helperText?: string;
	size?: 'small' | 'medium';
	required?: boolean;
};

export const SitesAutocomplete = ({
	onChange,
	value,
	label,
	sites,
	disabled,
	error,
	helperText,
	size = 'medium',
	required,
}: SitesAutocompleteProps): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const sitesWithGroup: Record<string, SiteWithGroup> = Object.fromEntries(
		Object.entries(sites).map(([key, value]) => [
			key,
			{
				...value,
				group: userDetails?.recentSites
					? userDetails?.recentSites.some((site) => site.id === key)
						? SiteGroup.Recent
						: SiteGroup.Other
					: SiteGroup.Other,
			},
		]),
	);
	const sortedKeys = Object.keys(sitesWithGroup).sort((siteIdA, siteIdB) => {
		const siteA = sitesWithGroup[siteIdA];
		const siteB = sitesWithGroup[siteIdB];
		return siteB.group.localeCompare(siteA.group);
	});
	const options = [...sortedKeys];

	return (
		<Autocomplete
			fullWidth
			data-testid="site-autocomplete"
			options={options}
			value={value}
			disabled={disabled}
			onChange={(_, newValue): void => onChange(newValue ?? '')}
			getOptionLabel={(option: string): string =>
				sites[option]?.name ?? ''
			}
			groupBy={(option: string): string => sitesWithGroup[option]?.group}
			isOptionEqualToValue={(option, value): boolean => option === value}
			renderOption={(props: FixMeLater, option: string): JSX.Element => (
				<MenuItem
					{...props}
					label={sites[option]?.name ?? ''}
					variant="standard"
					key={option}>
					{sites[option]?.name}
				</MenuItem>
			)}
			renderInput={(params): ReactNode => (
				<TextField
					{...params}
					label={label}
					inputProps={{
						...params.inputProps,
					}}
					error={error}
					helperText={helperText}
					size={size}
					required={required}
				/>
			)}
		/>
	);
};
