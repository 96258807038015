import type { User } from '../firebase/firebase';
import { InvoicingType } from '../models/Integrations/InvoicingIntegration';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type InvoicingTokensRequestBody = {
	invoicingType: InvoicingType;
	token: string;
};

export const fetchInvoicingTokens = async (
	abortSignal: AbortSignal,
	user: User,
	code: string,
	type: InvoicingType,
): Promise<boolean | undefined> => {
	const response =
		await makeCloudFunctionRequestReturnSuccessStatus<InvoicingTokensRequestBody>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.InvoicingTokens,
				method: 'POST',
				services: defaultCloudFunctionServices,
				body: { token: code, invoicingType: type },
			},
		);

	return response;
};
