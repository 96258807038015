import { Box, Card } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import { Site, UserDetails } from '../../../../constants/Common';
import type { User } from '../../../../firebase/firebase';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import {
	ProjectTrackingLinks,
	SiteLink,
} from '../../../../models/Integrations/ProjectTrackingIntegration';
import { linkTableSort } from '../../../helpers/muiDataTableCustomSorts';
import { IntegrationLinkButton } from '../../../Integrations/Components/IntegrationLinkButton/IntegrationLinkButton';
import {
	IntegrationLinkStatus,
	IntegrationStatusChip,
} from '../../../Integrations/Components/IntegrationStatusChip/IntegrationStatusChip';
import { IntegrationTable } from '../../../Integrations/Components/IntegrationTable/IntegrationTable';
import { IntegrationTextLinkCell } from '../../../Integrations/Components/IntegrationTextLinkCell';
import { siteIntegrationTableRowSearch } from '../../Common/Helpers/siteIntegrationTableRowSearch';
import {
	SiteIntegrationTableRow,
	TempSiteLink,
	isSiteLink,
} from '../../Common/Models/sitesTab';
import { getLinkStatus } from '../CATProjectsConstants';

export type SitesTabProps = {
	firebaseApi: Pick<
		FirebaseApi,
		| 'siteSubscription'
		| 'activeSitesByCompanySubscription'
		| 'projectTrackingIntegrationSingleSiteLinkSubscription'
		| 'projectTrackingIntegrationSiteLinksSubscription'
	>;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		'createProjectTrackingSiteLink' | 'deleteProjectTrackingSiteLink'
	>;
	userDetails: UserDetails;
	user: User;
};

export const SitesTab = ({
	firebaseApi,
	cloudFunctionApi,
	user,
	userDetails,
}: SitesTabProps): JSX.Element => {
	const abortSignal = useAbortController();
	const [searchParams] = useSearchParams();

	const [selected, setSelected] = useState<number | null>(null);

	const [tableData, setTableData] = useState<SiteIntegrationTableRow[]>([]);
	const [sites, setSites] = useState<Record<string, Site>>({});
	const [siteLinks, setSiteLinks] = useState<Record<string, SiteLink>>({});
	const [newLinks, setNewLinks] = useState<Record<string, SiteLink>>({});
	const [loading, setLoading] = useState<
		Record<'siteLinks' | 'sites', boolean>
	>({
		siteLinks: true,
		sites: true,
	});
	const [linkingRecords, setLinkingRecords] = useState<
		Record<string, boolean>
	>({});

	const singleSite = userDetails.accountType === 'management';

	const numCells = 4;
	const cellWidth = { width: `${100 / numCells}%` };
	const cellWidthExtra = `${(100 / numCells) * 1.25}%`;
	const cellWidthLess = `${(100 / numCells) * 0.75}%`;

	useEffect(() => {
		if (
			userDetails.companyID === '' ||
			(singleSite && userDetails.siteID === '')
		) {
			return;
		}
		setLoading((prev) => ({ ...prev, sites: true }));

		if (singleSite) {
			return firebaseApi.siteSubscription(userDetails.siteID, (site) => {
				setSites({ [site.id]: site });
				setLoading((prev) => ({ ...prev, sites: false }));
			});
		} else {
			return firebaseApi.activeSitesByCompanySubscription(
				userDetails.companyID,
				(sites) => {
					setSites(sites);
					setLoading((prev) => ({ ...prev, sites: false }));
				},
			);
		}
	}, [firebaseApi, singleSite, userDetails]);

	useEffect(() => {
		if (
			userDetails.companyID === '' ||
			(singleSite && userDetails.siteID === '')
		) {
			return;
		}
		setLoading((prev) => ({ ...prev, siteLinks: true }));

		if (singleSite) {
			return firebaseApi.projectTrackingIntegrationSingleSiteLinkSubscription(
				userDetails.companyID,
				userDetails.siteID,
				(link) => {
					if (link !== null) {
						setSiteLinks({ [link.id]: link });
					}
					setLoading((prev) => ({ ...prev, siteLinks: false }));
				},
			);
		} else {
			return firebaseApi.projectTrackingIntegrationSiteLinksSubscription(
				userDetails.companyID,
				(links) => {
					setSiteLinks(links);
					setLoading((prev) => ({ ...prev, siteLinks: false }));
				},
			);
		}
	}, [firebaseApi, singleSite, userDetails]);

	useEffect(() => {
		const tableData = Object.values(sites).map((site) =>
			mapTableData(site, siteLinks, newLinks),
		);

		setTableData(tableData);
	}, [newLinks, siteLinks, sites]);

	useEffect(() => {
		const name = searchParams.get('name');
		const index = tableData.findIndex((row) => row.name === name);
		setSelected(index === -1 ? null : index);
	}, [tableData, searchParams]);

	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});

	const mapTableData = (
		site: Site,
		links: Record<string, SiteLink>,
		newLinks: Record<string, SiteLink>,
	): SiteIntegrationTableRow => {
		const link: SiteLink | null = links[site.id] ?? null;

		const status = getLinkStatus(link);

		return {
			id: site.id,
			name: site.name,
			status: status,
			job: link ?? newLinks[site.id] ?? { id: site.id },
		};
	};

	const createLink = async (link: SiteLink): Promise<void> => {
		await cloudFunctionApi.createProjectTrackingSiteLink(
			abortSignal,
			user,
			link,
		);
	};

	const deleteLink = async (link: SiteLink): Promise<void> => {
		await cloudFunctionApi.deleteProjectTrackingSiteLink(
			abortSignal,
			user,
			link,
		);
	};

	const onLinkCellChange =
		(siteLink: TempSiteLink, site: Site) =>
		(integrationID?: string): void => {
			if (integrationID === undefined) {
				setNewLinks((prev) => {
					const { [siteLink.id]: _, ...remaining } = prev;
					return remaining;
				});
				return;
			}

			setNewLinks((prev) => ({
				...prev,
				[siteLink.id]: {
					id: siteLink.id,
					name: site.name,
					integrationID: integrationID,
					integrationName: integrationID,
					type: ProjectTrackingLinks.Site,
				},
			}));
		};

	const renderJob = (siteLink: TempSiteLink): JSX.Element => {
		const trueLink = siteLinks[siteLink.id];
		const site = sites[siteLink.id];

		const onChange = onLinkCellChange(siteLink, site);
		const linking = !!linkingRecords[siteLink.id];

		return (
			<IntegrationTextLinkCell
				link={trueLink}
				linkIntegrationID={
					isSiteLink(siteLink) ? siteLink.integrationID : undefined
				}
				onChange={onChange}
				disabled={linking}
			/>
		);
	};

	const renderOptionButton = (siteID: string): JSX.Element => {
		const link: SiteLink | undefined = siteLinks[siteID];
		const newLink: SiteLink | undefined = newLinks[siteID];

		const handleCreateLink = async (): Promise<void> => {
			setLinkingRecords((prev) => ({ ...prev, [siteID]: true }));
			await createLink(newLink);
			setNewLinks((prev) => {
				const { [siteID]: _, ...remaining } = prev;
				return remaining;
			});
			setLinkingRecords((prev) => {
				const { [siteID]: _, ...remaining } = prev;
				return remaining;
			});
		};
		const handleDeleteLink = async (): Promise<void> => {
			setLinkingRecords((prev) => ({ ...prev, [siteID]: true }));
			await deleteLink(link);
			setLinkingRecords((prev) => {
				const { [siteID]: _, ...remaining } = prev;
				return remaining;
			});
		};

		const disabled = !link && newLink === undefined;

		return (
			<IntegrationLinkButton
				link={link}
				disabled={disabled}
				handleLinkClick={handleCreateLink}
				handleUnlinkClick={handleDeleteLink}
			/>
		);
	};

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'name',
			label: 'Name',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthExtra,
					},
				}),
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthLess,
					},
				}),
				customBodyRender: (value: IntegrationLinkStatus) => (
					<Box display="flex" justifyContent="center">
						<IntegrationStatusChip status={value} />
					</Box>
				),
			},
		},
		{
			name: 'job',
			label: 'CATProjects Job',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthExtra,
					},
				}),
				sortCompare: linkTableSort,
				customBodyRender: (siteLink: SiteLink) => (
					<Box display="flex" justifyContent="center">
						{renderJob(siteLink)}
					</Box>
				),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthLess,
					},
				}),
				customBodyRender: renderOptionButton,
			},
		},
	];

	return (
		<Box flex="1">
			<Card>
				<IntegrationTable
					title="Link Sites"
					data={tableData}
					columns={columns}
					loading={loading.siteLinks || loading.sites}
					missingDataLabel="Sorry, no sites found"
					centeredColumns={[2, 3, 4]}
					customSearch={siteIntegrationTableRowSearch}
					selection={[selected, setSelected]}
				/>
			</Card>
		</Box>
	);
};
