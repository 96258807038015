import type { Guest } from '../../constants/Common';
import { Firestore, FirestoreDataConverter, DocumentQuery } from '../firebase';
import { getFirestoreDocsByID } from '../firebaseHelpers';

const GUEST_COLLECTION = 'guests';
type GuestCallback = (guests: Record<string, Guest>) => void;
export class GuestNotFoundError extends Error {
	constructor(guestID: string) {
		super(`Could not find guest <${guestID}>`);
		this.name = 'GuestNotFoundError';
	}
}

const guestConverter: FirestoreDataConverter<Guest> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) => snapshot.data() as Guest,
};
const guestSnapshot = (
	query: DocumentQuery,
	callback: GuestCallback,
): (() => void) =>
	query
		.withConverter(guestConverter)
		.onSnapshot((snapshot) =>
			callback(
				Object.fromEntries(
					snapshot.docs.map((doc) => [doc.id, doc.data()]),
				),
			),
		);

const deleteGuest = async (guestID: string): Promise<void> =>
	await Firestore.collection(GUEST_COLLECTION).doc(guestID).delete();

const subscribeToGuestsWithLimit = (
	siteID: string,
	limit: number,
	callback: GuestCallback,
): (() => void) =>
	guestSnapshot(
		Firestore.collection(GUEST_COLLECTION)
			.where('siteID', '==', siteID)
			.orderBy('displayName', 'asc')
			.withConverter(guestConverter)
			.limit(limit),
		callback,
	);

const subscribeGuestsBySite = (
	siteID: string,
	callback: GuestCallback,
): (() => void) =>
	guestSnapshot(
		Firestore.collection(GUEST_COLLECTION)
			.where('siteID', '==', siteID)
			.orderBy('displayName', 'asc')
			.withConverter(guestConverter),
		callback,
	);

const subscribeToAllGuests = (callback: GuestCallback): (() => void) =>
	guestSnapshot(
		Firestore.collection(GUEST_COLLECTION).orderBy('displayName', 'asc'),
		callback,
	);

const createGuest = async (guest: Omit<Guest, 'id'>): Promise<string> => {
	try {
		const docRef = Firestore.collection(GUEST_COLLECTION).doc();
		await docRef.set({ ...guest, id: docRef.id });

		return docRef.id;
	} catch (error) {
		console.error('Error creating guest:', error);
		throw new Error('Failed to create guest');
	}
};

const getGuest = async (guestID: string): Promise<Guest> => {
	const guestDoc = await Firestore.collection(GUEST_COLLECTION)
		.doc(guestID)
		.withConverter(guestConverter)
		.get();

	if (guestDoc.exists) {
		const guest = guestDoc.data();
		if (guest) {
			return guest;
		}
	}
	throw new GuestNotFoundError(guestID);
};

const getGuestsByIDs = async (
	guestIDs: string[],
): Promise<Record<string, Guest>> => {
	const guests = await getFirestoreDocsByID<'id', Guest>(
		guestIDs,
		GUEST_COLLECTION,
		'id',
	);
	return guests;
};

const guestFirebaseApi = {
	createGuest,
	deleteGuest,
	getGuest,
	getGuestsByIDs,
	subscribeGuestsBySite,
	subscribeToAllGuests,
	subscribeToGuestsWithLimit,
};

export default guestFirebaseApi;
