import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type SendTimesheetReminderBody = {
	employeeID: string;
	startDate: string;
	endDate: string;
};

export const sendTimesheetReminderNotification = async (
	abortSignal: AbortSignal,
	user: User,
	employeeID: string,
	startDate: Date,
	endDate: Date,
): Promise<boolean | undefined> =>
	await makeCloudFunctionRequestReturnSuccessStatus<SendTimesheetReminderBody>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.SendTimesheetReminderNotification,
			method: 'POST',
			services: defaultCloudFunctionServices,
			body: {
				employeeID: employeeID,
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
			},
		},
	);
