import { Document } from '../constants/Document';
import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type CreateDocumentBody = {
	documentName: string;
	employeeID: string;
	documentFields: Record<string, string>;
	templateID: string;
};

export const createDocument = async (
	abortSignal: AbortSignal,
	user: User,
	documentName: Document['name'],
	employeeID: Document['employee']['id'],
	documentFields: Document['documentFields'],
	templateID: Document['template']['id'],
): Promise<void> => {
	await makeCloudFunctionRequestReturnSuccessStatus<CreateDocumentBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.CreateDocument,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: {
			documentName,
			employeeID,
			documentFields,
			templateID,
		},
	});
};
