import type { Timestamp } from '../firebase/firebase';
import { UserDetails } from './Common';

export type UserInvite = {
	id: string;
	firstName: UserDetails['firstname'];
	lastName: UserDetails['lastname'];
	accountType: UserDetails['accountType'];
	company: {
		id: UserDetails['companyID'];
		name: UserDetails['company'];
	};
	createdAt: Timestamp;
	createdBy: {
		id: UserDetails['userID'];
		name: UserDetails['displayName'];
	};
	email: UserDetails['email'];
	mobileNumber: UserDetails['mobileNumber'];
	readAt: Timestamp | null;
	site: {
		id: UserDetails['siteID'];
		name: UserDetails['site'];
		companyID: UserDetails['siteCompanyID'];
		companyName: UserDetails['siteCompany'];
	} | null;
	contractedTo: UserDetails['contractedTo'] | null;
	status: UserInviteStatus;
	statusUpdatedAt: Timestamp;
};

export enum UserInviteStatus {
	Pending = 'Pending',
	Accepted = 'Accepted',
	Expired = 'Expired',
}
