import { formatSlashedDate } from '../../helpers/dateFormatters';
import {
	isUserInfoObject,
	isSentOnObject,
	getSentOnStatusMessage,
	isPlacementObject,
	UserInvitesTableData,
} from './helpers';

export const userInvitesTableRowSearch = (
	searchQuery: string,
	currentRow: (
		| UserInvitesTableData['userInfo']
		| UserInvitesTableData['accountType']
		| UserInvitesTableData['placement']
		| UserInvitesTableData['sentOn']
	)[],
): boolean => {
	const lowerCaseQuery = searchQuery.toLowerCase();

	return currentRow.some((cell): boolean => {
		if (typeof cell === 'string') {
			return cell.toLowerCase().includes(lowerCaseQuery);
		}

		if (isUserInfoObject(cell)) {
			return (
				cell.name.toLowerCase().includes(lowerCaseQuery) ||
				cell.email.toLowerCase().includes(lowerCaseQuery) ||
				cell.mobileNumber.toLowerCase().includes(lowerCaseQuery)
			);
		}

		if (isSentOnObject(cell)) {
			const statusMessage = getSentOnStatusMessage(cell, new Date());

			return (
				statusMessage.toLowerCase().includes(lowerCaseQuery) ||
				(cell.createdAt &&
					formatSlashedDate(cell.createdAt.toDate()).includes(
						lowerCaseQuery,
					)) ||
				(cell.statusUpdatedAt &&
					formatSlashedDate(cell.statusUpdatedAt.toDate()).includes(
						lowerCaseQuery,
					))
			);
		}

		if (isPlacementObject(cell)) {
			return (
				(cell.contractedTo &&
					cell.contractedTo.name
						.toLowerCase()
						.includes(lowerCaseQuery)) ||
				(!!cell.site &&
					cell.site.name.toLowerCase().includes(lowerCaseQuery))
			);
		}

		return false;
	});
};
