import { Location } from 'react-router';
import {
	Company,
	getAccountTypes,
	SubscriptionStatuses,
	UserDetails,
} from '../constants/Common';
import {
	isDefaultPagePath,
	isSignUpFlowPath,
	SignUpFlowPaths,
} from '../constants/PagePaths';
import type { User } from '../firebase/firebase';
import { CompanySubscription } from '../providers/CompanySubscriptionProvider';
import { UserLoaded } from '../providers/UserProvider';

const activeSubscriptionStates: string[] = [
	SubscriptionStatuses.Active,
	SubscriptionStatuses.Trialing,
];

/**
 * This function handles the initial navigation of the user upon loading into the web.
 * Is used to also directly navigate the user when the 'Go to Dashboard' button is pressed
 * @returns string path or null to indicate no navigation needed
 */
export const getNavigationPath = (
	userDetails: UserDetails | null,
	userAuth: User | null,
	companySub: CompanySubscription,
	flagsLoaded: boolean,
	userLoaded: UserLoaded,
	returnUrl: string,
	setReturnUrl: (url: string) => void,
	location: Location,
	direct = false,
): string | null => {
	if (!userLoaded.userAuthLoaded || !userLoaded.userDetailsLoaded) {
		return null;
	} else if (!userAuth && !isDefaultPagePath(location.pathname)) {
		return '/sign-in';
	} else if (!userAuth) {
		return null;
	} else if (!userDetails || userDetails?.accountType === '' || direct) {
		if (
			(isSignUpFlowPath(location.pathname) ||
				!isDefaultPagePath(location.pathname) ||
				direct) &&
			!userLoaded.initialNavigateComplete
		) {
			userLoaded.setInitialNavigateComplete(true);
			return getSignUpNavigationPath(
				userAuth,
				userDetails,
				location,
				companySub.company,
			);
		}
	} else if (
		(location.pathname !== '/sign-in' ||
			(location.pathname === '/sign-in' && userDetails && userAuth)) &&
		location.pathname !== '/subscription-control' &&
		companySub.isLoaded &&
		!companySub.isSubscribed &&
		flagsLoaded
	) {
		setReturnUrl(window.location.href);
		return '/subscription-control';
	} else if (
		(companySub.isSubscribed &&
			flagsLoaded &&
			(location.pathname === '/subscription-control' ||
				location.pathname === '/subscription-setup' ||
				(!userLoaded.initialNavigateComplete &&
					location.pathname === '/sign-in'))) ||
		isSignUpFlowPath(location.pathname)
	) {
		if (
			(!userLoaded.initialNavigateComplete &&
				location.pathname === '/sign-in') ||
			isSignUpFlowPath(location.pathname)
		) {
			userLoaded.setInitialNavigateComplete(true);
		}

		if (returnUrl) {
			// we need to return a relative url
			return returnUrl.replace(window.location.origin, '');
		} else {
			return getAccountTypes(userDetails, companySub.company).redirect;
		}
	}

	return null;
};

const allowedOnScreen: Record<
	SignUpFlowPaths,
	(
		userAuth: User | null,
		userDetails: UserDetails | null,
		company: Company | null,
	) => boolean
> = {
	'/sign-in': () => false,
	'/sign-up': () => true,
	'/verify-email': (userAuth: User | null) => {
		return userAuth !== null;
	},
	'/account-information': (userAuth: User | null) => {
		return userAuth !== null && userAuth.emailVerified;
	},
	'/company-information': (
		userAuth: User | null,
		userDetails: UserDetails | null,
	) => {
		return (
			userAuth !== null && userAuth.emailVerified && userDetails !== null
		);
	},
	'/awaiting-approval': (
		userAuth: User | null,
		userDetails: UserDetails | null,
	) => {
		return (
			userAuth !== null &&
			userAuth.emailVerified &&
			userDetails !== null &&
			userDetails.accountType !== ''
		);
	},
	'/subscription-setup': (
		userAuth: User | null,
		userDetails: UserDetails | null,
		company: Company | null,
	) => {
		return (
			userAuth !== null &&
			userAuth.emailVerified &&
			userDetails !== null &&
			userDetails.accountType !== '' &&
			company !== null &&
			company.subscriptionStatus === undefined
		);
	},
};

/**
 * Handles the navigation for sign in/up flow.
 * @returns string path or null to indicate no navigation needed
 */
export const getSignUpNavigationPath = (
	userAuth: User | null,
	userDetails: UserDetails | null,
	location: Location,
	company: Company | null = null,
): string | null => {
	const alreadySignedUp =
		userAuth !== null &&
		userDetails !== null &&
		userDetails.accountType !== '' &&
		activeSubscriptionStates.includes(company?.subscriptionStatus || '');

	if (
		isSignUpFlowPath(location.pathname) &&
		allowedOnScreen[location.pathname](userAuth, userDetails, company) &&
		!alreadySignedUp
	)
		return location.pathname;

	if (location.pathname === '/sign-in' && userAuth === null) {
		return null;
	}

	let pathName: string;

	if (userAuth === null) {
		pathName = '/sign-up';
	} else if (!userAuth.emailVerified) {
		pathName = '/verify-email';
	} else if (userDetails === null) {
		pathName = '/account-information';
	} else if (userDetails.companyID === '') {
		pathName = '/company-information';
	} else if (userDetails.accountType === '') {
		pathName = '/awaiting-approval';
	} else if (
		!activeSubscriptionStates.includes(company?.subscriptionStatus || '')
	) {
		pathName = '/subscription-setup';
	} else {
		pathName = getAccountTypes(userDetails, company).redirect;
	}

	return pathName;
};
