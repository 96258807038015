import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CloudFunctionApi } from '../../../cloudfunctions';
import { UserDetails } from '../../../constants/Common';
import {
	replaceHyphenWithSpace,
	replaceSpaceWithHyphen,
} from '../../../constants/stringUtilities';
import type { User } from '../../../firebase/firebase';
import { useAbortController } from '../../../hooks/useAbortController';
import {
	IntegrationStatus,
	ProjectTrackingIntegration,
} from '../../../models/Integrations/ProjectTrackingIntegration';
import { StepperHeader } from '../../StepperHeader/StepperHeader';
import {
	CATProjectsAccountType,
	CATProjectsConfig,
	isCATProjectsAccountType,
} from './CATProjectsConstants';

export type CATProjectsTabsProps = {
	userDetails: UserDetails;
	user: User;
	integration: ProjectTrackingIntegration;
	currentTab: string;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		'updateProjectTrackingIntegrationStatus'
	>;
};

export const CATProjectsTabs = ({
	user,
	userDetails,
	integration,
	currentTab,
	cloudFunctionApi,
}: CATProjectsTabsProps): JSX.Element => {
	if (!isCATProjectsAccountType(userDetails.accountType)) {
		throw new Error(
			`Unauthorised. Account type ${userDetails.accountType} cannot access catprojects.`,
		);
	}

	const abortSignal = useAbortController();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const titles = CATProjectsConfig[userDetails.accountType].titles;

	const currentTabIndex = titles.indexOf(replaceHyphenWithSpace(currentTab));

	const stepperHeader = (
		accountType: CATProjectsAccountType,
	): JSX.Element => {
		const stepperTabs = CATProjectsConfig[accountType].setupStepperTabs();

		const handleNext = (): void => {
			navigate(replaceSpaceWithHyphen(titles[currentTabIndex + 1]));
		};

		const handleBack = (): void => {
			navigate(replaceSpaceWithHyphen(titles[currentTabIndex - 1]));
		};

		const handleFinalize = async (): Promise<void> => {
			setLoading(true);
			await cloudFunctionApi.updateProjectTrackingIntegrationStatus(
				abortSignal,
				user,
				IntegrationStatus.Active,
			);
			setLoading(false);
		};

		return (
			<StepperHeader
				currentTab={currentTab}
				tabs={stepperTabs}
				next={handleNext}
				back={handleBack}
				finalize={handleFinalize}
				loading={loading}
			/>
		);
	};

	return integration.status === IntegrationStatus.Incomplete ? (
		stepperHeader(userDetails.accountType)
	) : (
		<Box
			sx={{
				borderBottom: 1,
				borderColor: 'divider',
			}}>
			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				value={replaceHyphenWithSpace(currentTab)}>
				{titles.map((title, index) => (
					<Tab
						label={title}
						value={title}
						key={index}
						component={Link}
						to={replaceSpaceWithHyphen(title)}
					/>
				))}
			</Tabs>
		</Box>
	);
};
