import { capitaliseWords } from '../../components/helpers/stringHelpers';
import type { UserOptionsAccountUpdateFields } from '../../constants/Accounts';
import { AccountType, accountTypes, UserDetails } from '../../constants/Common';
import {
	DocumentQuery,
	FieldValue,
	Firestore,
	FirestoreDataConverter,
	FirestoreError,
	Query,
} from '../firebase';
import { getFirestoreDocsByID } from '../firebaseHelpers';

const USER_COLLECTION = 'users';
const TIMESHEETACCOUNTTYPES = [
	accountTypes.thirdPartyWorker,
	accountTypes.worker,
];
const NOTIMESHEETACCOUNTTYPES = [
	'',
	'kiosk',
	accountTypes.cx,
	accountTypes.handler,
	accountTypes.seniorManagement,
	accountTypes.management,
];
const NOTUSERACCOUNTTYPES = ['', 'kiosk', accountTypes.cx] as const;

const NOT_CX_ACCOUNT_TYPES = [
	...TIMESHEETACCOUNTTYPES,
	'',
	'kiosk',
	accountTypes.handler,
	accountTypes.seniorManagement,
	accountTypes.management,
];

type UserCallback = (users: Record<string, UserDetails>) => void;
type UserListCallback = (users: UserDetails[]) => void;

export type FirestoreUserAccountUpdate<T extends Partial<UserDetails>> = {
	[K in keyof T]: K extends keyof Pick<
		UserDetails,
		'disabledLeave' | 'disableTimesheetApproval'
	>
		? T[K] | FieldValue
		: T[K];
};

export class UserNotFoundError extends Error {
	constructor(userID: string) {
		super(`Could not find user <${userID}>`);
		this.name = 'UserNotFoundError';
	}
}

const userConverter: FirestoreDataConverter<UserDetails> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) =>
		({ ...snapshot.data(), userID: snapshot.id } as UserDetails),
};

const userSnapshot = (
	query: DocumentQuery,
	callback: UserCallback,
): (() => void) =>
	query
		.withConverter(userConverter)
		.onSnapshot((snapshot) =>
			callback(
				Object.fromEntries(
					snapshot.docs.map((doc) => [doc.id, doc.data()]),
				),
			),
		);

const userListSnapshot = (
	query: DocumentQuery,
	callback: UserListCallback,
): (() => void) =>
	query
		.withConverter(userConverter)
		.onSnapshot((snapshot) =>
			callback(snapshot.docs.map((doc) => doc.data())),
		);

const createUser = async (
	userID: string,
	userDetails: Omit<UserDetails, 'userID'>,
): Promise<void> =>
	await Firestore.collection(USER_COLLECTION)
		.doc(userID)
		.set(userDetails, { merge: true });

const getUser = async (userID: string): Promise<UserDetails> => {
	const doc = await Firestore.collection(USER_COLLECTION)
		.doc(userID)
		.withConverter(userConverter)
		.get();

	const user = doc.data();
	if (user) {
		return user;
	} else {
		throw new UserNotFoundError(userID);
	}
};

const getUsersByIDs = async (
	userIDs: string[],
): Promise<Record<string, UserDetails>> => {
	const users = await getFirestoreDocsByID<'userID', UserDetails>(
		userIDs,
		USER_COLLECTION,
		'userID',
	);
	return users;
};

const customerSupportQueryUsers = async (
	filterByUserName: string,
	filterByCompanyName: string,
	filterBySiteName: string,
): Promise<UserDetails[]> => {
	const collectionRef = Firestore.collection(USER_COLLECTION);
	let query: Query;
	if (
		filterByUserName !== '' &&
		filterByCompanyName !== '' &&
		filterBySiteName !== ''
	) {
		query = collectionRef
			.where('displayName', '>=', filterByUserName)
			.where('displayName', '<=', filterByUserName + '\uf8ff')
			.where('company', '==', filterByCompanyName)
			.where('site', '==', filterBySiteName);
	} else if (filterByCompanyName !== '' && filterByUserName !== '') {
		query = collectionRef
			.where('displayName', '>=', filterByUserName)
			.where('displayName', '<=', filterByUserName + '\uf8ff')
			.where('company', '==', filterByCompanyName);
	} else if (filterByUserName !== '' && filterBySiteName !== '') {
		query = collectionRef
			.where('displayName', '>=', filterByUserName)
			.where('displayName', '<=', filterByUserName + '\uf8ff')
			.where('site', '==', filterBySiteName);
	} else if (filterByCompanyName !== '') {
		query = collectionRef.where('company', '==', filterByCompanyName);
	} else if (filterBySiteName !== '') {
		query = collectionRef.where('site', '==', filterBySiteName);
	} else {
		query = collectionRef
			.where('displayName', '>=', filterByUserName)
			.where('displayName', '<=', filterByUserName + '\uf8ff');
	}

	const querySnapshot = await query
		.where('accountType', 'in', NOT_CX_ACCOUNT_TYPES)
		.withConverter(userConverter)
		.get();
	const users = querySnapshot.docs.map((doc) => doc.data());
	return users;
};

const subscribeSingleUser = (
	userID: string,
	callback: (user: UserDetails | null) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) =>
	Firestore.collection(USER_COLLECTION)
		.doc(userID)
		.withConverter(userConverter)
		.onSnapshot((snapshot) => {
			const user = snapshot.data();
			callback(user ?? null);
		}, onError);

const subscribeWorkerUsersByContractedSite = (
	companyID: string,
	siteID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('contractedTo.id', '==', companyID)
			.where('siteID', '==', siteID)
			.where('accountType', 'in', TIMESHEETACCOUNTTYPES),
		callback,
	);

const subscribeUsersByContractedSite = (
	companyID: string,
	siteID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('contractedTo.id', '==', companyID)
			.where('siteID', '==', siteID)
			.where('accountType', 'not-in', NOTIMESHEETACCOUNTTYPES),
		callback,
	);

const subscribeWorkerUsersByContracted = (
	companyID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('contractedTo.id', '==', companyID)
			.where('accountType', 'in', TIMESHEETACCOUNTTYPES),
		callback,
	);

const subscribeTimesheetUsersByContracted = (
	companyID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('contractedTo.id', '==', companyID)
			.where('accountType', 'not-in', NOTIMESHEETACCOUNTTYPES),
		callback,
	);

const subscribeWorkerUsersByCompany = (
	companyID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('companyID', '==', companyID)
			.where('accountType', 'in', TIMESHEETACCOUNTTYPES),
		callback,
	);

const subscribeUsersByCompany = (
	companyID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('companyID', '==', companyID)
			.where('accountType', 'not-in', NOTUSERACCOUNTTYPES),
		callback,
	);

const subscribeKiosksByCompany = (
	companyID: string,
	callback: UserListCallback,
): (() => void) =>
	userListSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('accountType', '==', 'kiosk')
			.where('companyID', '==', companyID),
		callback,
	);

const subscribeUsersBySite = (
	siteID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('siteID', '==', siteID)
			.where('accountType', 'not-in', NOTUSERACCOUNTTYPES),
		callback,
	);

const subscribeKiosksBySite = (
	siteID: string,
	callback: UserListCallback,
): (() => void) =>
	userListSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('accountType', '==', 'kiosk')
			.where('siteID', '==', siteID),
		callback,
	);

const subscribeUsersByContractedTo = (
	companyID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('contractedTo.id', '==', companyID)
			.where('accountType', 'not-in', NOTUSERACCOUNTTYPES),
		callback,
	);

const subscribeTimesheetUsersByCompany = (
	companyID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('companyID', '==', companyID)
			.where('accountType', 'not-in', NOTIMESHEETACCOUNTTYPES),
		callback,
	);

const subscribeUserIDsByCompany = (
	companyID: string,
	callback: (userIDs: string[]) => void,
): (() => void) =>
	Firestore.collection(USER_COLLECTION)
		.where('companyID', '==', companyID)
		.where('accountType', 'not-in', NOTUSERACCOUNTTYPES)
		.onSnapshot((snapshot) => callback(snapshot.docs.map((doc) => doc.id)));

const subscribeWorkerUsersBySite = (
	siteID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('siteID', '==', siteID)
			.where('accountType', 'in', TIMESHEETACCOUNTTYPES),
		callback,
	);

const subscribeWorkerUsersBySiteCompany = (
	companyID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('siteCompanyID', '==', companyID)
			.where('accountType', 'in', TIMESHEETACCOUNTTYPES),
		callback,
	);

type MenuPreferences = 'hiddenMenuItemsPreferences';
const updatedUserDetailsMenuPreferences = async (
	userID: string,
	updatedUserHiddenMenuItemsPreferences: Pick<UserDetails, MenuPreferences>,
): Promise<void> => {
	const update:
		| Pick<UserDetails, MenuPreferences>
		| Record<MenuPreferences, FieldValue> =
		updatedUserHiddenMenuItemsPreferences.hiddenMenuItemsPreferences ===
		undefined
			? {
					hiddenMenuItemsPreferences: FieldValue.delete(),
			  }
			: updatedUserHiddenMenuItemsPreferences;
	await Firestore.collection(USER_COLLECTION).doc(userID).update(update);
};

type DisabledNotifications = 'disabledNotifications';
const updatedUserDetailsDisabledNotifications = async (
	userID: string,
	updatedUserDisabledNotifications: Pick<UserDetails, DisabledNotifications>,
): Promise<void> => {
	const update:
		| Pick<UserDetails, DisabledNotifications>
		| Record<DisabledNotifications, FieldValue> =
		updatedUserDisabledNotifications.disabledNotifications === undefined
			? {
					disabledNotifications: FieldValue.delete(),
			  }
			: updatedUserDisabledNotifications;
	await Firestore.collection(USER_COLLECTION).doc(userID).update(update);
};

const updateUserDetailsSiteInfo = async (
	userID: string,
	updatedUserSiteDetails: Pick<
		UserDetails,
		'site' | 'siteID' | 'siteCompany' | 'siteCompanyID'
	>,
): Promise<void> =>
	await Firestore.collection(USER_COLLECTION)
		.doc(userID)
		.update(updatedUserSiteDetails);

const changeUserSite = async (
	userID: string,
	updatedUser: Pick<
		UserDetails,
		'site' | 'siteID' | 'siteCompany' | 'siteCompanyID' | 'contractedTo'
	>,
): Promise<void> => {
	await Firestore.collection(USER_COLLECTION).doc(userID).update(updatedUser);
};

const updateUserDetailsCompanyInfo = async (
	userID: string,
	updatedUser: Pick<
		UserDetails,
		| 'site'
		| 'siteID'
		| 'siteCompany'
		| 'siteCompanyID'
		| 'company'
		| 'companyID'
		| 'contractedTo'
		| 'workerType'
		| 'accountType'
	>,
): Promise<void> =>
	await Firestore.collection(USER_COLLECTION).doc(userID).update(updatedUser);

type FirebaseUpdateUserDetails = Pick<
	UserDetails,
	| 'firstname'
	| 'lastname'
	| 'accountType'
	| 'mobileNumber'
	| 'company'
	| 'companyID'
	| 'site'
	| 'siteID'
	| 'siteCompany'
	| 'siteCompanyID'
	| 'workerType'
	| 'contractedTo'
>;

const updateUserDetails = async (
	userID: string,
	updatedUser: FirebaseUpdateUserDetails,
): Promise<void> => {
	const displayName = capitaliseWords(
		`${updatedUser.firstname.trim()} ${updatedUser.lastname.trim()}`,
	);
	const update: FirebaseUpdateUserDetails & Pick<UserDetails, 'displayName'> =
		{
			firstname: updatedUser.firstname.trim(),
			lastname: updatedUser.lastname.trim(),
			accountType: updatedUser.accountType,
			mobileNumber: updatedUser.mobileNumber.trim(),
			company: updatedUser.company,
			companyID: updatedUser.companyID,
			site: updatedUser.site,
			siteID: updatedUser.siteID,
			siteCompany: updatedUser.siteCompany,
			siteCompanyID: updatedUser.siteCompanyID,
			workerType: updatedUser.workerType,
			displayName: displayName,
			contractedTo: updatedUser.contractedTo,
		};
	await Firestore.collection(USER_COLLECTION).doc(userID).update(update);
};

const updateUserDetailsAccountInfo = async (
	userID: string,
	update: Pick<
		UserDetails,
		'firstname' | 'lastname' | 'displayName' | 'mobileNumber'
	>,
): Promise<void> =>
	await Firestore.collection(USER_COLLECTION).doc(userID).update(update);

const updateUserDetailsPhotoURL = async (
	userID: string,
	updatedUser: Pick<UserDetails, 'photoURL'>,
): Promise<void> =>
	await Firestore.collection(USER_COLLECTION).doc(userID).update(updatedUser);

const userByAccountType = async (
	companyID: string,
	accountType: AccountType,
): Promise<UserDetails[]> => {
	const userDetails = await Firestore.collection(USER_COLLECTION)
		.where('companyID', '==', companyID)
		.where('accountType', '==', accountType)
		.withConverter(userConverter)
		.get();
	return userDetails.docs.map((doc) => doc.data());
};

const updateUserDisplayDetails = async (
	userID: string,
	updatedUser: {
		displayName: UserDetails['displayName'];
		photoURL?: UserDetails['photoURL'];
	},
): Promise<void> => {
	const updatedDisplayDetails:
		| Pick<UserDetails, 'displayName'>
		| Pick<UserDetails, 'displayName' | 'photoURL'> = updatedUser.photoURL
		? {
				displayName: capitaliseWords(updatedUser.displayName),
				photoURL: updatedUser.photoURL,
		  }
		: {
				displayName: capitaliseWords(updatedUser.displayName),
		  };
	return await Firestore.collection(USER_COLLECTION)
		.doc(userID)
		.update(updatedDisplayDetails);
};

const updateSiteContractedToAccountWorker = async (
	userID: string,
	updatedUser: Pick<
		UserDetails,
		| 'site'
		| 'siteID'
		| 'siteCompany'
		| 'siteCompanyID'
		| 'contractedTo'
		| 'workerType'
		| 'accountType'
	>,
): Promise<void> =>
	await Firestore.collection(USER_COLLECTION).doc(userID).update(updatedUser);

const updateSiteContractedToCompany = async (
	userID: string,
	updatedUser: Pick<
		UserDetails,
		| 'site'
		| 'siteID'
		| 'siteCompany'
		| 'siteCompanyID'
		| 'contractedTo'
		| 'company'
		| 'companyID'
	>,
): Promise<void> =>
	await Firestore.collection(USER_COLLECTION).doc(userID).update(updatedUser);

const approveUserAccount = async (
	userID: string,
	updatedUser: Pick<
		UserDetails,
		| 'accountType'
		| 'workerType'
		| 'site'
		| 'siteID'
		| 'siteCompany'
		| 'siteCompanyID'
		| 'contractedTo'
		| 'company'
		| 'companyID'
	>,
): Promise<void> =>
	await Firestore.collection(USER_COLLECTION).doc(userID).update(updatedUser);

const updateUserAccountDetails = async (
	userID: string,
	updatedUser: Partial<UserOptionsAccountUpdateFields>,
): Promise<void> => {
	const docRef = Firestore.collection(USER_COLLECTION).doc(userID);

	const updateObject: FirestoreUserAccountUpdate<
		Partial<UserOptionsAccountUpdateFields>
	> = {
		...updatedUser,
	};

	// If the disabledLeave field is set to false, delete it
	if (updateObject.disabledLeave === false) {
		updateObject.disabledLeave = FieldValue.delete();
	}

	// If the disableTimesheetApproval field is set to false, delete it
	if (updateObject.disableTimesheetApproval === false) {
		updateObject.disableTimesheetApproval = FieldValue.delete();
	}

	if (Object.keys(updateObject).length > 0) {
		await docRef.update(updateObject);
	}
};

const updateKioskDetails = async (
	userID: string,
	updatedUser: Pick<UserDetails, 'site' | 'siteID' | 'pin'> & {
		pin: NonNullable<UserDetails['pin']>;
	},
): Promise<void> =>
	await Firestore.collection(USER_COLLECTION).doc(userID).update(updatedUser);

const adminUpdateUser = async (
	userID: string,
	updatedUser: Pick<
		UserDetails,
		| 'accountType'
		| 'site'
		| 'siteID'
		| 'siteCompany'
		| 'siteCompanyID'
		| 'company'
		| 'companyID'
		| 'contractedTo'
	>,
): Promise<void> =>
	await Firestore.collection(USER_COLLECTION).doc(userID).update(updatedUser);

const subscribeUnapprovedUsersByCompany = (
	companyID: string,
	callback: UserCallback,
): (() => void) =>
	userSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('companyID', '==', companyID)
			.where('accountType', '==', ''),
		callback,
	);

const subscribeNonKioskUsersByCompany = (
	companyID: string,
	callback: UserListCallback,
): (() => void) =>
	userListSnapshot(
		Firestore.collection(USER_COLLECTION)
			.where('companyID', '==', companyID)
			.where('accountType', 'not-in', NOTUSERACCOUNTTYPES),
		callback,
	);

const subscribeUnapprovedUsers = (callback: UserListCallback): (() => void) =>
	userListSnapshot(
		Firestore.collection(USER_COLLECTION).where('accountType', '==', ''),
		callback,
	);

const subscribeNonKioskUsers = (callback: UserListCallback): (() => void) =>
	userListSnapshot(
		Firestore.collection(USER_COLLECTION).where(
			'accountType',
			'not-in',
			NOTUSERACCOUNTTYPES,
		),
		callback,
	);

const usersFirebaseApi = {
	adminUpdateUser,
	approveUserAccount,
	changeUserSite,
	createUser,
	customerSupportQueryUsers,
	getUser,
	getUsersByIDs,
	subscribeNonKioskUsers,
	subscribeNonKioskUsersByCompany,
	subscribeTimesheetUsersByCompany,
	subscribeTimesheetUsersByContracted,
	subscribeUnapprovedUsers,
	subscribeUnapprovedUsersByCompany,
	subscribeKiosksByCompany,
	subscribeKiosksBySite,
	subscribeSingleUser,
	subscribeUsersByCompany,
	subscribeUsersByContractedSite,
	subscribeUsersByContractedTo,
	subscribeUsersBySite,
	subscribeUserIDsByCompany,
	subscribeWorkerUsersByCompany,
	subscribeWorkerUsersByContracted,
	subscribeWorkerUsersByContractedSite,
	subscribeWorkerUsersBySite,
	subscribeWorkerUsersBySiteCompany,
	updatedUserDetailsDisabledNotifications,
	updatedUserDetailsMenuPreferences,
	updateKioskDetails,
	updateSiteContractedToAccountWorker,
	updateSiteContractedToCompany,
	updateUserAccountDetails,
	updateUserDetails,
	updateUserDetailsAccountInfo,
	updateUserDetailsCompanyInfo,
	updateUserDetailsPhotoURL,
	updateUserDetailsSiteInfo,
	updateUserDisplayDetails,
	userByAccountType,
};

export default usersFirebaseApi;
