import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Stack, Typography } from '@mui/material';

export type NoteHeadingProps = {
	name: string;
};

export const NoteHeading = ({ name }: NoteHeadingProps): JSX.Element => {
	return (
		<Stack direction="row" alignItems="center">
			<CommentOutlinedIcon
				sx={{ mr: 0.5, fontSize: '1rem', color: 'text.secondary' }}
			/>
			<Typography
				mr={1}
				variant="h7Bold"
				color="text.secondary"
				overflow="hidden"
				textOverflow="ellipsis">
				{name}
			</Typography>
		</Stack>
	);
};
