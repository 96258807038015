import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Grid,
	MenuItem,
	TextField,
} from '@mui/material';
import { useState } from 'react';
import { ExplicitAny } from '../../constants/AnyTypes';
import { PPEStoreQtyFormat } from './PPENumberFormats';

export type PPEItem = {
	id: string;
	name: string;
	quantity: number;
	price: number;
	size: string | null;
	sizes?: string[];
};

export type PPECardInfo = {
	id: string;
	name: string;
	price: number;
	sizes?: string[];
	imgSrc: ExplicitAny;
};

type PPEcardProps = {
	card: PPECardInfo;
	addItem: (item: PPEItem) => void;
	itemLimit: number;
};

export const PPECard = ({
	card,
	addItem,
	itemLimit,
}: PPEcardProps): JSX.Element => {
	const [size, setSize] = useState(card.sizes ? card.sizes[2] ?? null : null);
	const [quantity, setQuantity] = useState(1);

	const handleSizeChange: React.ChangeEventHandler<HTMLInputElement> = (
		event,
	) => {
		setSize(event.target.value);
	};

	const handleQtyChange: React.ChangeEventHandler<HTMLInputElement> = (
		event,
	) => {
		setQuantity(parseInt(event.target.value));
	};

	return (
		<Card style={styles.card}>
			<CardActionArea style={styles.cardActionArea}>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
					onClick={(): void => {
						addItem({
							name: card.name,
							price: card.price,
							quantity,
							size: card.sizes ? size : null,
							sizes: card.sizes,
							id: card.id,
						});
					}}>
					<CardMedia
						image={card.imgSrc}
						title={card.name}
						component="img"
						style={{
							maxWidth: '200px',
						}}
					/>
				</Box>
			</CardActionArea>
			<CardContent style={{ width: '75%' }}>
				<Grid container>
					<Grid item xs={6}>
						{card.name}
					</Grid>
					<Grid
						item
						xs={6}
						style={{ display: 'flex', justifyContent: 'flex-end' }}>
						${card.price}
					</Grid>
				</Grid>
				<Grid item container spacing={card.sizes ? 2 : 1}>
					<Grid item xs={card.sizes ? 6 : 12}>
						<TextField
							size="small"
							label="Quantity"
							value={quantity}
							onChange={handleQtyChange}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							margin="dense"
							InputProps={{
								inputComponent: PPEStoreQtyFormat,
							}}
							inputProps={{
								style: { textAlign: 'center' },
								itemlimit: itemLimit,
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						{card.sizes && (
							<TextField
								size="small"
								value={size}
								onChange={handleSizeChange}
								label="Size"
								variant="outlined"
								margin="dense"
								select
								fullWidth
								InputProps={{ style: { textAlign: 'center' } }}>
								{card.sizes.map((size, index) => (
									<MenuItem key={index} value={size}>
										{size}
									</MenuItem>
								))}
							</TextField>
						)}
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							disableElevation
							fullWidth
							onClick={(): void => {
								addItem({
									name: card.name,
									price: card.price,
									quantity,
									size: card.sizes ? size : null,
									sizes: card.sizes,
									id: card.id,
								});
							}}>
							Add
						</Button>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export const styles: Record<string, React.CSSProperties> = {
	card: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	cardActionArea: {
		flex: '1',
		justifyContent: 'center',
		alignItems: 'center',
	},
};
