import AddIcon from '@mui/icons-material/Add';
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	Grid,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { CloudFunctionApi } from '../../../cloudfunctions';
import { UserDetails } from '../../../constants/Common';
import {
	InductionConfig,
	InductionStep,
} from '../../../constants/InductionStep';
import { PartialWithID } from '../../../constants/TypescriptUtilities';
import type { User } from '../../../firebase/firebase';
import type { FirebaseApi } from '../../../firebase/firebaseApi';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { InductionStepCard } from './InductionStepCard';
import { InductionStepDialog } from './InductionStepDialog';

export type ConfigureInductionsProps = {
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'siteInductionConfigSubscription'
		| 'siteInductionStepsSubscription'
		| 'getDownloadUrl'
	>;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		'inductionStepCreate' | 'inductionStepUpdate' | 'inductionStepDelete'
	>;
	abortSignal: AbortSignal;
	user: User;
};

export const ConfigureInductions = ({
	userDetails,
	firebaseApi,
	cloudFunctionApi,
	abortSignal,
	user,
}: ConfigureInductionsProps): JSX.Element => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [inductionConfig, setInductionConfig] =
		useState<InductionConfig | null>(null);
	const [steps, setSteps] = useState<Record<string, InductionStep>>({});
	const [imageUrls, setImageUrls] = useState<Record<string, string>>({});
	const [currentStep, setCurrentStep] = useState<InductionStep | null>(null);
	const [loadingSteps, setLoadingSteps] = useState(true);

	useEffect(() => {
		if (!userDetails.siteID) return;
		firebaseApi.siteInductionConfigSubscription(
			userDetails.siteID,
			(config) => {
				setInductionConfig(config);
			},
		);
	}, [firebaseApi, userDetails.siteID]);

	useEffect(() => {
		if (!userDetails.siteID) {
			setLoadingSteps(false);
			return;
		}
		firebaseApi.siteInductionStepsSubscription(
			userDetails.siteID,
			(steps) => {
				setSteps(steps);
				setLoadingSteps(false);
			},
		);
	}, [firebaseApi, userDetails.siteID]);

	useEffect(() => {
		const getImageUrls = async (): Promise<void> => {
			const imageUrlMap = await Object.values(steps).reduce(
				async (imageMapPromise, { id, imageLocation }) => {
					const imageMap = await imageMapPromise;
					let url = '';
					if (imageLocation) {
						try {
							url = await firebaseApi.getDownloadUrl(
								imageLocation,
							);
						} catch (error) {
							console.error(
								`Failed to get download URL for ${imageLocation}:`,
								error,
							);
						}
					}
					return { ...imageMap, [id]: url };
				},
				Promise.resolve({}),
			);

			setImageUrls(imageUrlMap);
		};

		getImageUrls();
	}, [firebaseApi, steps]);

	const handleOpenDialog = (step: InductionStep | null): void => {
		setCurrentStep(step);
		setDialogOpen(true);
	};

	const handleUpdateStep = async (
		step: PartialWithID<InductionStep>,
		imageBlob: string,
		position: number,
	): Promise<boolean | undefined> => {
		if (!inductionConfig) return;
		const base64String = imageBlob.split(',')[1];
		try {
			const success = await cloudFunctionApi.inductionStepUpdate(
				abortSignal,
				user,
				step.id,
				userDetails.siteID,
				step.title,
				step.body,
				base64String,
				position,
			);
			setImageUrls({ ...imageUrls, [step.id]: imageBlob });
			return success;
		} catch (error) {
			console.error('Error updating step:', error);
		}
	};

	const handleCreateStep = async (
		step: Omit<InductionStep, 'id' | 'imageLocation'>,
		imageBlob: string,
		position: number,
	): Promise<boolean | undefined> => {
		const base64String = imageBlob.split(',')[1];
		try {
			return await cloudFunctionApi.inductionStepCreate(
				abortSignal,
				user,
				userDetails.siteID,
				step.title,
				step.body,
				base64String,
				position,
			);
		} catch (error) {
			console.error('Error creating step:', error);
		}
	};

	const handleDeleteStep = async (
		step: InductionStep,
	): Promise<boolean | undefined> => {
		if (!inductionConfig) return;
		try {
			return await cloudFunctionApi.inductionStepDelete(
				abortSignal,
				user,
				step.id,
				userDetails.siteID,
			);
		} catch (error) {
			console.error('Error deleting step:', error);
		}
	};

	const addStepCard = (
		<Card sx={{ height: '500px', width: '300px' }}>
			<CardActionArea
				onClick={(): void => handleOpenDialog(null)}
				sx={{ padding: 0, height: '100%' }}>
				<CardContent
					sx={{
						display: 'flex',
						height: '100%',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
					}}>
					<AddIcon sx={{ color: 'primary.main' }} />
					<Typography sx={{ color: 'primary.main' }}>
						Add Step
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);

	return (
		<>
			{dialogOpen && (
				<InductionStepDialog
					open={dialogOpen}
					onClose={(): void => setDialogOpen(false)}
					onCreate={handleCreateStep}
					onUpdate={handleUpdateStep}
					steps={Object.values(steps)}
					currentStep={currentStep}
					onDelete={handleDeleteStep}
					imgUrl={imageUrls[currentStep?.id ?? '']}
					stepPosition={
						inductionConfig && currentStep
							? inductionConfig.stepOrder.indexOf(
									currentStep.id,
							  ) + 1
							: undefined
					}
				/>
			)}
			{loadingSteps ? (
				<LoadingDots />
			) : userDetails.siteID ? (
				<Grid
					height="100%"
					container
					spacing={2}
					pb={4}
					display="flex"
					justifyContent={{
						xs: 'center',
						sm: 'left',
					}}>
					{inductionConfig?.stepOrder.map(
						(stepID, position) =>
							steps[stepID] && (
								<Grid key={stepID} item>
									<InductionStepCard
										step={steps[stepID]}
										position={position + 1}
										totalSteps={Object.keys(steps).length}
										onClick={(): void =>
											handleOpenDialog(steps[stepID])
										}
										imgUrl={imageUrls[stepID]}
									/>
								</Grid>
							),
					)}
					<Grid item>{addStepCard}</Grid>
				</Grid>
			) : (
				<Box width="100%" display="flex" justifyContent="center">
					<Typography>
						Select a site to choose induction steps
					</Typography>
				</Box>
			)}
		</>
	);
};
