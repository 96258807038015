import { addDays, formatDistance } from 'date-fns';
import { UserInvite, UserInviteStatus } from '../../../constants/UserInvite';

export type UserInvitesTableData = {
	id: UserInvite['id'];
	userInfo: {
		name: UserInvite['firstName'] & UserInvite['lastName'];
		email: UserInvite['email'];
		mobileNumber: UserInvite['mobileNumber'];
	};
	accountType: UserInvite['accountType'];
	placement: {
		contractedTo: UserInvite['contractedTo'];
		site: UserInvite['site'];
	};
	sentOn: {
		createdAt: UserInvite['createdAt'];
		statusUpdatedAt: UserInvite['statusUpdatedAt'];
		status: UserInvite['status'];
	};
	readAt: UserInvite['readAt'];
	status: UserInvite['status'];
};

// Type guard for 'userInfo' field
export const isUserInfoObject = (
	cell: unknown,
): cell is UserInvitesTableData['userInfo'] => {
	return (
		typeof cell === 'object' &&
		cell !== null &&
		'name' in cell &&
		typeof cell.name === 'string' &&
		'email' in cell &&
		typeof cell.email === 'string' &&
		'mobileNumber' in cell &&
		typeof cell.mobileNumber === 'string'
	);
};

// Type guard for 'sentOn' field
export const isSentOnObject = (
	cell: unknown,
): cell is UserInvitesTableData['sentOn'] => {
	return (
		typeof cell === 'object' &&
		cell !== null &&
		'status' in cell &&
		'createdAt' in cell &&
		'statusUpdatedAt' in cell
	);
};

// Type guard for 'placement' field
export const isPlacementObject = (
	cell: unknown,
): cell is UserInvitesTableData['placement'] => {
	return (
		typeof cell === 'object' &&
		cell !== null &&
		'contractedTo' in cell &&
		'site' in cell
	);
};

export const getSentOnStatusMessage = (
	sentOn: UserInvitesTableData['sentOn'],
	now: Date,
): string => {
	const lastUpdated = sentOn.statusUpdatedAt.toDate();
	const inviteExpirationDaysLength = 7;

	let message: string;

	switch (sentOn.status) {
		case UserInviteStatus.Pending: {
			const expirationDate = addDays(
				lastUpdated,
				inviteExpirationDaysLength,
			);
			const daysTillExpiration = formatDistance(expirationDate, now, {
				addSuffix: true,
			});
			message = `Expires ${daysTillExpiration}`;
			break;
		}
		case UserInviteStatus.Expired: {
			const daysSinceExpiration = formatDistance(lastUpdated, now, {
				addSuffix: true,
			});
			message = `Expired ${daysSinceExpiration}`;
			break;
		}
		case UserInviteStatus.Accepted: {
			const daysSinceAcceptance = formatDistance(lastUpdated, now, {
				addSuffix: true,
			});
			message = `Accepted ${daysSinceAcceptance}`;
			break;
		}
	}

	return message;
};

export const verifyEmail = (email: string): boolean => {
	if (!email) {
		return false;
	}
	const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	return emailPattern.test(email);
};
