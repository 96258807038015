import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
	Box,
	Button,
	Collapse,
	Grid,
	Icon,
	Stack,
	TableCell,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import MUIDataTable, {
	MUIDataTableColumn,
	MUIDataTableOptions,
} from 'mui-datatables';
import { Fragment, useEffect, useState } from 'react';
import { FixMeLater } from '../../constants/AnyTypes';
import { UserDetails, assertAccountType } from '../../constants/Common';
import {
	AccountInfo,
	Contract,
	ContractStatus,
	ContractsAccountType,
	UpdatableContract,
	contractsAccountType,
} from '../../constants/Contract';
import { onDownload } from '../../constants/CsvExport';
import { SafetyCourseValidationStatus } from '../../constants/SafetyCourse';
import { Storage, Timestamp } from '../../firebase/firebase';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { ConfirmationDialog } from '../Dialogs/ConfirmationDialog';
import { formatDecimalToPercent } from '../helpers/numberFormats';
import { sortMinimal, sortObjectBySubField } from '../helpers/sortHelpers';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { FilterChip } from '../Timesheets/Timesheets/Overview/FilterChip';
import { ContractDialog } from './ContractDialog';
import { ContractStatusChip } from './ContractStatusChip';

type DialogInfo = {
	id: string;
	dataIndex: number;
	supplierName: string;
};

export type ContractFilter =
	| 'All'
	| ContractStatus.Active
	| ContractStatus.Accepted
	| ContractStatus.Archived;

export type ContractRatesProps = {
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'contractsByStatusCompany'
		| 'createContractDocRef'
		| 'contractDocRef'
		| 'acceptContract'
		| 'archiveContract'
		| 'companyEmployeeRatesByID'
		| 'companiesSubscriptionByType'
		| 'subscribeWorkerUsersByCompany'
		| 'activeSitesSubscription'
		| 'getLimitedContractsByEmployeeSiteSupplierAccepterStatus'
		| 'findDuplicateContract'
	>;
};

export const ContractRates = ({
	userDetails,
	firebaseApi,
}: ContractRatesProps): JSX.Element => {
	const [contracts, setContracts] = useState<Record<string, Contract>>({});
	const [tableData, setTableData] = useState<Contract[]>([]);
	const [contractsFilter, setContractsFilter] =
		useState<ContractFilter>('All');
	const [contractsStatusCount, setContractsStatusCount] = useState<
		Record<ContractFilter, number>
	>({
		All: 0,
		Active: 0,
		Accepted: 0,
		Archived: 0,
	});

	const [confirmArchive, setConfirmArchive] = useState<DialogInfo | null>(
		null,
	);
	const [confirmAccept, setConfirmAccept] = useState<DialogInfo | null>(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [editModalID, setEditModalID] = useState<string>();
	const [expandedIDs, setExpandedIDs] = useState<Set<number>>(new Set());
	/** Expanding and collapsing the contracts table resets the MUIDataTableOptions, which means we lose state and all values get set to their defaults.
	 * rowsPerPage is the only place where this is visible that we've seen
	 * If there are other places discovered, then we may have to chuck the entire objects array into state somehow (disgusting) */
	const [rowsPerPage, setRowsPerPage] = useState(100);
	const [loading, setLoading] = useState(true);

	const showAddContractBtn = userDetails?.accountType === 'handler';
	const ContractsDisplayFilters: ContractFilter[] = [
		'All',
		ContractStatus.Active,
		ContractStatus.Accepted,
		ContractStatus.Archived,
	];

	const CONTRACT_FILE_PATH = 'contractDocs';

	assertAccountType<ContractsAccountType>(
		userDetails?.accountType,
		contractsAccountType,
	);
	const userAccountInfo = AccountInfo[userDetails.accountType];

	const numCells = 7;
	const cellWidthCalc = (ratio: number): string =>
		`${(100 / numCells) * ratio}%`;
	const columns: MUIDataTableColumn[] = [
		{
			label: 'Name',
			name: 'employee',
			options: {
				// no explicit column width means that this column will soak up excess space if an account type can see fewer fields
				sortCompare: sortMinimal,
				customBodyRender: (employee: Contract['employee']) =>
					employee?.name ?? 'INVALID EMPLOYEE NAME',
			},
		},
		{
			label: 'Supplier',
			name: 'supplierCompany',
			options: {
				sortCompare: sortMinimal,
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(1) },
				}),
				customBodyRender: (
					supplierCompany: Contract['supplierCompany'],
				) => supplierCompany.name,
			},
		},
		{
			label: 'Client',
			name: 'accepterCompany',
			options: {
				sortCompare: sortMinimal,
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(1) },
				}),
				customBodyRender: (
					accepterCompany: Contract['accepterCompany'],
				) => accepterCompany?.name ?? '-',
			},
		},
		{
			label: 'Site',
			name: 'site',
			options: {
				sortCompare: sortMinimal,
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(1) },
				}),
				customBodyRender: (site: Contract['site']) =>
					site?.name ?? 'All Sites',
			},
		},
		{
			label: 'Charge Out Rate',
			name: 'chargeOutRate',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(0.5) },
				}),
				customBodyRender: (chargeOutRate: Contract['chargeOutRate']) =>
					`$${chargeOutRate.toFixed(2)}`,
			},
		},
		{
			label: 'Pay Rate',
			name: 'payRate',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(0.5) },
				}),
				customBodyRender: (payRate: Contract['payRate']) =>
					`$${payRate.toFixed(2)}`,
			},
		},
		{
			label: 'On Cost',
			name: 'onCost',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(0.5) },
				}),
				customBodyRender: (onCost: Contract['onCost']) =>
					`${!isNaN(onCost) ? formatDecimalToPercent(onCost) : 0}%`,
			},
		},
		{
			label: 'Margin',
			name: 'margin',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(0.5) },
				}),
				customBodyRender: (margin: Contract['margin']) =>
					`$${margin?.toFixed(2)}`,
			},
		},
		{
			label: 'Status',
			name: 'status',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(0.5) },
				}),
				customBodyRender: (status: Contract['status']) => (
					<Box width="100%" textAlign="center" display="contents">
						<ContractStatusChip status={status} />
					</Box>
				),
			},
		},
	].filter(
		(column) =>
			!userAccountInfo.hiddenFields.has(column.name as keyof Contract),
	);

	useEffect(() => {
		setLoading(true);
		return firebaseApi.contractsByStatusCompany(
			[
				ContractStatus.Active,
				ContractStatus.Accepted,
				ContractStatus.Archived,
			],
			userDetails.companyID,
			userAccountInfo.companyFilter,
			(contracts) => {
				setContracts(
					sortObjectBySubField(contracts, 'employee', 'name'),
				);
				setLoading(false);
			},
		);
	}, [firebaseApi, userAccountInfo.companyFilter, userDetails.companyID]);

	useEffect(() => {
		const contractsList = Object.values(contracts);
		if (contractsList.length === 0) {
			setContractsStatusCount({
				All: 0,
				Active: 0,
				Accepted: 0,
				Archived: 0,
			});
		} else {
			const active = contractsList.filter(
				(contract) => contract.status === ContractStatus.Active,
			).length;
			const accepted = contractsList.filter(
				(contract) => contract.status === ContractStatus.Accepted,
			).length;
			const archived = contractsList.filter(
				(contract) => contract.status === ContractStatus.Archived,
			).length;
			setContractsStatusCount({
				All: active + accepted + archived,
				Active: active,
				Accepted: accepted,
				Archived: archived,
			});
		}
	}, [contracts]);

	useEffect(() => {
		const contractsList = Object.values(contracts);

		if (contractsFilter === 'All') {
			setTableData(contractsList);
		} else {
			setTableData(
				contractsList.filter(
					(contract) => contract.status === contractsFilter,
				),
			);
		}
	}, [contracts, contractsFilter]);

	const AddContractBtn = (): JSX.Element => (
		<Button
			endIcon={<AddIcon />}
			variant="contained"
			onClick={(): void => setModalOpen(true)}
			disableElevation
			sx={{ ml: 1 }}>
			New Rate
		</Button>
	);

	const editContract = async (
		contract: UpdatableContract,
		id: string,
	): Promise<void> => {
		const {
			site,
			employee,
			accepterCompany,
			payRate,
			onCost,
			chargeOutRate,
			notes,
			document,
			margin,
		} = contract;
		const docRef = firebaseApi.contractDocRef(id);
		let documentURL: string | null;

		if (document && typeof document !== 'string') {
			// Document changed, we need to upload
			const storageRef = Storage.ref().child(
				`${CONTRACT_FILE_PATH}/${id}`,
			);

			await storageRef.put(document);

			documentURL = await storageRef.getDownloadURL();
		} else if (!document && contracts[id].documentURL) {
			// we previously had a document that now needs deletion

			const storageRef = Storage.ref().child(
				`${CONTRACT_FILE_PATH}/${id}`,
			);

			await storageRef.delete();
			documentURL = null;
		} else {
			documentURL = contracts[id].documentURL;
		}

		const updatedContract: Pick<
			Contract,
			| 'accepterCompany'
			| 'employee'
			| 'payRate'
			| 'chargeOutRate'
			| 'onCost'
			| 'margin'
			| 'notes'
			| 'site'
			| 'documentURL'
		> = {
			accepterCompany,
			employee,
			payRate,
			chargeOutRate,
			onCost,
			margin,
			notes,
			site,
			documentURL,
		};

		await docRef.update(updatedContract);
	};

	const addContract = async (contract: UpdatableContract): Promise<void> => {
		const {
			site,
			employee,
			accepterCompany,
			payRate,
			chargeOutRate,
			onCost,
			notes,
			document,
			margin,
		} = contract;

		if (
			userDetails === null ||
			!accepterCompany ||
			!employee ||
			typeof document === 'string'
		)
			return;

		const docRef = firebaseApi.createContractDocRef();
		const contractID = docRef.id;

		let documentURL: string | null = null;

		if (document) {
			const storageRef = Storage.ref().child(
				`${CONTRACT_FILE_PATH}/${contractID}`,
			);

			await storageRef.put(document);

			documentURL = await storageRef.getDownloadURL();
		}

		const newContract: Contract = {
			id: contractID,
			supplierCompany: {
				id: userDetails.companyID,
				name: userDetails.company,
			},
			accepterCompany: accepterCompany,
			employee: employee,
			payRate: payRate,
			chargeOutRate: chargeOutRate,
			onCost: onCost,
			margin: margin,
			notes: notes,
			site: site,
			createdBy: {
				id: userDetails.userID,
				name: userDetails.displayName,
			},
			acceptedBy: null,
			dateCreated: Timestamp.now(),
			status: ContractStatus.Active,
			documentURL: documentURL,
		};

		await docRef.set(newContract);
	};

	const acceptContract = async (id: string): Promise<void> => {
		if (!userDetails) {
			return;
		}
		const update: Contract['acceptedBy'] = {
			id: userDetails.userID,
			name: userDetails.displayName,
		};
		await firebaseApi.acceptContract(id, update);
	};

	const archiveContract = async (id: string): Promise<void> => {
		await firebaseApi.archiveContract(id);
	};

	const tableTitle = (): JSX.Element => (
		<Grid container spacing={1} pt={2}>
			<Grid xs={12} item>
				<Typography variant="h5">Rates</Typography>
			</Grid>
			<Grid item xs={12}>
				<Stack spacing={1} direction="row">
					{ContractsDisplayFilters.map((title) => (
						<FilterChip
							title={
								title === ContractStatus.Active
									? SafetyCourseValidationStatus.Pending
									: title
							}
							key={title}
							currentFilter={
								contractsFilter === ContractStatus.Active
									? SafetyCourseValidationStatus.Pending
									: contractsFilter
							}
							onClick={(): void => setContractsFilter(title)}
							count={contractsStatusCount[title]}
						/>
					))}
				</Stack>
			</Grid>
		</Grid>
	);

	const renderButtons = (
		contract: Contract,
		dataIndex: number,
	): JSX.Element => (
		<Stack spacing={1} alignItems="flex-start" height="100%">
			<Button
				fullWidth
				variant="outlined"
				size="small"
				sx={{ height: '100%' }}
				endIcon={<DownloadIcon />}
				disabled={contract.documentURL === null}
				onClick={(): Window | null | undefined =>
					contract.documentURL !== null
						? window.open(contract.documentURL)
						: undefined
				}>
				{contract.documentURL !== null ? 'File' : 'No File'}
			</Button>

			{userAccountInfo.allowedActions.has('archive') && (
				<>
					<Button
						fullWidth
						size="small"
						variant="outlined"
						onClick={(): void =>
							setConfirmArchive({
								id: contract.id,
								supplierName: contract.supplierCompany.name,
								dataIndex,
							})
						}
						disabled={contract.status !== ContractStatus.Active}>
						Archive
					</Button>
				</>
			)}
			{userAccountInfo.allowedActions.has('edit') && (
				<Button
					fullWidth
					size="small"
					variant="contained"
					disabled={contract.status !== ContractStatus.Active}
					onClick={(): void => setEditModalID(contract.id)}>
					Edit
				</Button>
			)}
			{userAccountInfo.allowedActions.has('accept') && (
				<>
					<Button
						fullWidth
						size="small"
						sx={{ height: '100%' }}
						variant="contained"
						onClick={(): void =>
							setConfirmAccept({
								id: contract.id,
								dataIndex,
								supplierName: contract.supplierCompany.name,
							})
						}
						disabled={contract.status !== ContractStatus.Active}>
						Accept
					</Button>
				</>
			)}
		</Stack>
	);

	const expandedTableRow = (
		open: boolean,
		contract: Contract,
		dataIndex: number,
	): JSX.Element => (
		<TableRow>
			<TableCell sx={{ pb: 0, pt: 0 }} colSpan={7}>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<Box sx={{ pb: 1, pt: 1 }}>
						<Grid
							container
							spacing={1}
							alignItems="center"
							columns={10}>
							<Grid item xs={2}>
								<Typography variant="caption">
									Created By:
								</Typography>
								<Typography variant="body2">
									{contract.createdBy.name}
								</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography variant="caption">
									Accepted By:
								</Typography>
								<Typography variant="body2">
									{contract.acceptedBy?.name ?? '-'}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size="small"
									multiline
									label="Notes"
									disabled
									maxRows={4}
									minRows={4}
									placeholder="No Note"
									fullWidth
									value={contract.notes}
								/>
							</Grid>
							<Grid item xs={2} alignSelf="stretch">
								{renderButtons(contract, dataIndex)}
							</Grid>
						</Grid>
					</Box>
				</Collapse>
			</TableCell>
		</TableRow>
	);

	const expansionIcon = (open: boolean): JSX.Element => (
		<Icon>
			{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
		</Icon>
	);

	const mainTableRow = (
		open: boolean,
		dataIndex: number,
		data: FixMeLater[],
	): JSX.Element => (
		<TableRow
			hover={!open}
			sx={{
				'& > *': { borderBottom: 'unset' },
				alignContent: 'center',
				cursor: 'pointer',
			}}
			onClick={(): void =>
				setExpandedIDs((prev) => {
					prev.has(dataIndex)
						? prev.delete(dataIndex)
						: prev.add(dataIndex);

					return new Set(prev);
				})
			}>
			{data.map((dataRow, index, arr) =>
				// in order to make sure everything aligns (among other styling bugs) we need to make the little expansion arrow a child of the last table column
				index + 1 === arr.length ? (
					<TableCell key={`${index}+${dataRow}`}>
						<Grid
							container
							justifyContent="space-between"
							wrap="nowrap">
							{dataRow}
							{expansionIcon(open)}
						</Grid>
					</TableCell>
				) : (
					<TableCell key={`${index}+${dataRow}`}>{dataRow}</TableCell>
				),
			)}
		</TableRow>
	);

	const options: MUIDataTableOptions = {
		selectableRows: 'none',
		elevation: 1,
		fixedHeader: true,
		// 320px is the magic number, but is NOT screen size dependant
		// it represents top of viewport to top of the top row of data without scrolling
		tableBodyHeight: 'calc(100vh - 320px)',
		filter: false,
		viewColumns: false,
		rowsPerPage,
		onChangeRowsPerPage: (numberOfRows: number) =>
			setRowsPerPage(numberOfRows),
		rowsPerPageOptions: [25, 50, 100],
		expandableRowsHeader: false,
		customToolbar: () => {
			return showAddContractBtn && <AddContractBtn />;
		},
		customRowRender: (data, dataIndex, rowIndex) => {
			const contractsList = Object.values(contracts);
			const open = expandedIDs.has(dataIndex);
			const contract = contractsList[dataIndex];
			return (
				<Fragment key={rowIndex}>
					{mainTableRow(open, dataIndex, data)}
					{expandedTableRow(open, contract, dataIndex)}
				</Fragment>
			);
		},
		textLabels: {
			body: {
				noMatch: loading ? (
					<LoadingDots />
				) : (
					'Sorry, no matching rates found'
				),
			},
		},
		onDownload: onDownload(columns),
	};

	return (
		<>
			<ContractDialog
				modalOpen={modalOpen}
				closeModal={(): void => setModalOpen(false)}
				onSave={addContract}
				action="add"
				firebaseApi={firebaseApi}
			/>
			{editModalID && (
				<ContractDialog
					modalOpen={!!editModalID}
					closeModal={(): void => setEditModalID(undefined)}
					onSave={async (contract): Promise<void> =>
						await editContract(contract, editModalID)
					}
					action="edit"
					contract={contracts[editModalID]}
					firebaseApi={firebaseApi}
				/>
			)}
			{confirmAccept && (
				<ConfirmationDialog
					title="Accept Contract"
					question={`Are you sure you want to accept this contract from ${confirmAccept.supplierName}?`}
					isOpen={confirmAccept !== null}
					onClose={(): void => setConfirmAccept(null)}
					onConfirm={async (): Promise<void> => {
						await acceptContract(confirmAccept.id);
						expandedIDs.delete(confirmAccept.dataIndex);
						setConfirmAccept(null);
					}}
				/>
			)}
			{confirmArchive && (
				<ConfirmationDialog
					title="Archive Contract"
					question={`Are you sure you want to archive this contract for  ${confirmArchive.supplierName}?`}
					isOpen={confirmArchive != null}
					onClose={(): void => setConfirmArchive(null)}
					onConfirm={async (): Promise<void> => {
						await archiveContract(confirmArchive.id);
						expandedIDs.delete(confirmArchive.dataIndex);
						setConfirmArchive(null);
					}}
				/>
			)}
			<MUIDataTable
				title={tableTitle()}
				data={tableData}
				columns={columns}
				options={options}
			/>
		</>
	);
};
