import {
	Box,
	Button,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { MUIDataTableOptions, Responsive } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { CloudFunctionApi } from '../../../cloudfunctions';
import {
	accountsAccountType,
	UserInvitesAccountType,
} from '../../../constants/Accounts';
import {
	AccountTypeHumanName,
	UserDetails,
	assertAccountType,
} from '../../../constants/Common';
import { UserInvite } from '../../../constants/UserInvite';
import type { User } from '../../../firebase/firebase';
import { useAbortController } from '../../../hooks/useAbortController';
import { DataTable } from '../../DataTable/DataTable';
import { AccountsTableTheme } from '../../Documents/TableWrappers/AccountsTableWrapper';
import {
	nameCustomTableSort,
	userInvitePlacementTableSort,
	userInviteSentOnTableSort,
} from '../../helpers/muiDataTableCustomSorts';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { CreateUserInviteDialog } from '../CreateUserInviteDialog/CreateUserInviteDialog';
import { AccountsState } from '../StateManagement/actions';
import { UserInvitesTableData } from './helpers';
import { UserInviteEmployee } from './UserInviteEmployee';
import { UserInviteOptions } from './UserInviteOptions';
import { UserInvitePlacement } from './UserInvitePlacement';
import { UserInviteSeenIcon } from './UserInviteSeenIcon';
import { UserInviteSentOn } from './UserInviteSentOn';
import { UserInviteStatusChip } from './UserInvitesStatusChip';
import { userInvitesTableRowSearch } from './userInvitesTableRowSearch';

export type UserInvitesProps = {
	user: User | null;
	userDetails: UserDetails;
	userCompany: AccountsState['userCompany'];
	userInvites: AccountsState['userInvites'];
	companies: AccountsState['companies'];
	sites: AccountsState['sites'];
	loading: AccountsState['loadingUserInvites'];
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		'createUserInvite' | 'resendUserInvite'
	>;
};

export const UserInvites = ({
	user,
	userCompany,
	userDetails,
	userInvites,
	companies,
	sites,
	loading,
	cloudFunctionApi,
}: UserInvitesProps): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<UserInvitesAccountType>(accountType, accountsAccountType);

	const abortSignal = useAbortController();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

	const [tableData, setTableData] = useState<UserInvitesTableData[]>([]);
	const [createDialogOpen, setCreateDialogOpen] = useState(false);

	const numCells = 7;
	const cellWidth = { width: `${100 / numCells}%` };
	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});
	const cellWidthSmall = '0%';

	useEffect(() => {
		setTableData(createUserInvitesTableData(userInvites));
	}, [userInvites]);

	const createUserInvitesTableData = (
		userInvites: AccountsState['userInvites'],
	): UserInvitesTableData[] => {
		return Object.values(userInvites).map((invite) => ({
			id: invite.id,
			userInfo: {
				name: `${invite.firstName} ${invite.lastName}`,
				email: invite.email,
				mobileNumber: invite.mobileNumber,
			},
			accountType: invite.accountType,
			placement: {
				contractedTo: invite.contractedTo,
				site: invite.site,
			},
			sentOn: {
				createdAt: invite.createdAt,
				statusUpdatedAt: invite.statusUpdatedAt,
				status: invite.status,
			},
			readAt: invite.readAt,
			status: invite.status,
		}));
	};

	const resendUserInvite = (id: UserInvite['id']) => {
		return async (): Promise<void> => {
			if (user) {
				await cloudFunctionApi.resendUserInvite(abortSignal, user, {
					id,
				});
			}
		};
	};

	const columns = [
		{
			name: 'userInfo',
			label: 'Name',
			options: {
				setCellHeaderProps,
				customBodyRender: (
					userInfo: UserInvitesTableData['userInfo'],
				) => <UserInviteEmployee userInfo={userInfo} />,
				sortCompare: nameCustomTableSort,
			},
		},
		{
			name: 'accountType',
			label: 'Account Type',
			options: {
				setCellHeaderProps,
				customBodyRender: (
					accountType: UserInvitesTableData['accountType'],
				): JSX.Element => (
					<Box display="flex" justifyContent="center">
						{AccountTypeHumanName[accountType]}
					</Box>
				),
			},
		},
		{
			name: 'placement',
			label: 'Placement',
			options: {
				setCellHeaderProps,
				customBodyRender: (
					placement: UserInvitesTableData['placement'],
				) => <UserInvitePlacement placement={placement} />,
				sortCompare: userInvitePlacementTableSort,
			},
		},
		{
			name: 'sentOn',
			label: 'Sent On',
			options: {
				setCellHeaderProps,
				customBodyRender: (sentOn: UserInvitesTableData['sentOn']) => (
					<UserInviteSentOn sentOn={sentOn} />
				),
				sortCompare: userInviteSentOnTableSort,
			},
		},
		{
			name: 'readAt',
			label: 'Seen',
			options: {
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthSmall,
					},
				}),
				customBodyRender: (readAt: UserInvitesTableData['readAt']) => (
					<Box display="flex" justifyContent="center">
						<UserInviteSeenIcon isSeen={!!readAt} />
					</Box>
				),
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthSmall,
					},
				}),
				customBodyRender: (
					status: UserInvitesTableData['status'],
				): JSX.Element => (
					<Box display="flex" justifyContent="center">
						<UserInviteStatusChip status={status} />
					</Box>
				),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				searchable: false,
				setCellHeaderProps,
				customBodyRender: (
					id: UserInvite['id'],
				): JSX.Element | undefined => {
					const userInvite = userInvites[id];
					return (
						userInvite && (
							<UserInviteOptions
								id={id}
								status={userInvite.status}
								resendUserInvite={resendUserInvite(id)}
							/>
						)
					);
				},
			},
		},
	];

	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		<Typography>There are no user invites for your company</Typography>
	);

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		filter: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 302px)',
		responsive: isSmallScreen
			? // The mui dataTable responsive type has doesn't recognize 'verticalAlways' as a valid type even though it works
			  // https://github.com/gregnb/mui-datatables/issues/1786
			  ('verticalAlways' as Responsive)
			: 'vertical',
		viewColumns: false,
		selectToolbarPlacement: 'none',
		customSearch: userInvitesTableRowSearch,
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
		sortOrder: {
			name: 'sentOn',
			direction: 'desc',
		},
		customToolbar: () => (
			<Button
				variant="contained"
				sx={{ marginLeft: '8px' }}
				disabled={loading}
				onClick={(): void => setCreateDialogOpen(true)}>
				Invite User
			</Button>
		),
	};

	return (
		<>
			<AccountsTableTheme centeredColumns={[2, 3, 4, 5, 6, 7]}>
				<DataTable
					tableData={tableData}
					columns={columns}
					title="Invites"
					customTableOptions={tableOptions}
				/>
			</AccountsTableTheme>
			{userCompany && (
				<CreateUserInviteDialog
					dialogOpen={createDialogOpen}
					userCompany={userCompany}
					companies={companies}
					sites={sites}
					closeDialog={(): void => setCreateDialogOpen(false)}
					cloudFunctionApi={cloudFunctionApi}
					userAccountType={accountType}
					user={user}
					abortSignal={abortSignal}
				/>
			)}
		</>
	);
};
