import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { emptyFunction } from '../../../constants/Common';
import { UserInvite, UserInviteStatus } from '../../../constants/UserInvite';

export type UserInviteOptionsProps = {
	id: string;
	status: UserInvite['status'];
	resendUserInvite: () => Promise<void>;
};

export const UserInviteOptions = ({
	id,
	status,
	resendUserInvite,
}: UserInviteOptionsProps): JSX.Element => {
	const [loading, setLoading] = useState(false);

	const handleResendUserInvite = async (): Promise<void> => {
		setLoading(true);
		await resendUserInvite();
		setLoading(false);
	};

	return (
		<Grid
			container
			spacing={1}
			justifyContent="center"
			display="flex"
			textAlign="center">
			{status === UserInviteStatus.Pending && (
				<>
					<Grid item xl={6}>
						<LoadingButton
							size="small"
							fullWidth
							onClick={handleResendUserInvite}
							variant="outlined"
							sx={{ minWidth: 76 }}
							loading={loading}
							data-testid={`${id}-resend-button`}>
							Resend
						</LoadingButton>
					</Grid>
					<Grid item xl={6}>
						<LoadingButton
							size="small"
							fullWidth
							onClick={emptyFunction}
							variant="contained"
							color="error"
							sx={{ minWidth: 76 }}
							loading={loading}
							data-testid={`${id}-revoke-button`}>
							Revoke
						</LoadingButton>
					</Grid>
				</>
			)}
			{status === UserInviteStatus.Expired && (
				<Grid item display="flex" justifyContent="center">
					<LoadingButton
						size="small"
						onClick={handleResendUserInvite}
						variant="outlined"
						fullWidth
						loading={loading}
						data-testid={`${id}-resend-button`}
						sx={{ minWidth: 76 }}>
						Resend
					</LoadingButton>
				</Grid>
			)}
		</Grid>
	);
};
