import { Box, Card } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { useCallback, useEffect, useState } from 'react';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import { ProjectTrackingSite } from '../../../../cloudfunctions/projectTracking';
import { Site, UserDetails } from '../../../../constants/Common';
import type { User } from '../../../../firebase/firebase';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import { IntegrationElement } from '../../../../models/Integrations/IntegrationElements';
import {
	ProjectTrackingLinks,
	SiteLink,
} from '../../../../models/Integrations/ProjectTrackingIntegration';
import { linkTableSort } from '../../../helpers/muiDataTableCustomSorts';
import { IntegrationLinkButton } from '../../../Integrations/Components/IntegrationLinkButton/IntegrationLinkButton';
import {
	IntegrationLinkCell,
	OptionWithDisabled,
} from '../../../Integrations/Components/IntegrationLinkCell/IntegrationLinkCell';
import {
	IntegrationLinkStatus,
	IntegrationStatusChip,
} from '../../../Integrations/Components/IntegrationStatusChip/IntegrationStatusChip';
import { IntegrationTable } from '../../../Integrations/Components/IntegrationTable/IntegrationTable';
import { siteIntegrationTableRowSearch } from '../../Common/Helpers/siteIntegrationTableRowSearch';
import {
	SiteIntegrationTableRow,
	TempSiteLink,
	isSiteLink,
} from '../../Common/Models/sitesTab';

const getLinkStatus = (
	link: SiteLink | null,
	integrationSite: ProjectTrackingSite | null,
): IntegrationLinkStatus => {
	if (link === null) {
		return IntegrationLinkStatus.Unlinked;
	} else if (integrationSite === null) {
		return IntegrationLinkStatus.Missing;
	} else {
		return IntegrationLinkStatus.Linked;
	}
};

export type SitesTabProps = {
	firebaseApi: Pick<
		FirebaseApi,
		| 'activeSitesByCompanySubscription'
		| 'siteSubscription'
		| 'projectTrackingIntegrationSiteLinksSubscription'
		| 'projectTrackingIntegrationSingleSiteLinkSubscription'
	>;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		| 'fetchProjectTrackingSiteIDs'
		| 'createProjectTrackingSiteLink'
		| 'deleteProjectTrackingSiteLink'
	>;
	userDetails: UserDetails;
	user: User;
};

export const SitesTab = ({
	firebaseApi,
	cloudFunctionApi,
	user,
	userDetails,
}: SitesTabProps): JSX.Element => {
	const abortSignal = useAbortController();

	const [tableData, setTableData] = useState<SiteIntegrationTableRow[]>([]);
	const [sites, setSites] = useState<Record<string, Site>>({});
	const [integrationSites, setIntegrationSites] = useState<
		Record<string, ProjectTrackingSite>
	>({});
	const [siteLinks, setSiteLinks] = useState<Record<string, SiteLink>>({});
	const [newLinks, setNewLinks] = useState<Record<string, SiteLink>>({});
	const [loading, setLoading] = useState<
		Record<'siteLinks' | 'sites' | 'integrationSites', boolean>
	>({
		siteLinks: true,
		sites: true,
		integrationSites: true,
	});
	const [linkingRecords, setLinkingRecords] = useState<
		Record<string, boolean>
	>({});

	const numCells = 4;
	const cellWidth = { width: `${100 / numCells}%` };
	const cellWidthExtra = `${(100 / numCells) * 1.25}%`;
	const cellWidthLess = `${(100 / numCells) * 0.75}%`;

	const singleSite = userDetails?.accountType === 'management';

	const fetchSiteIDs = useCallback(async (): Promise<void> => {
		setLoading((prev) => ({ ...prev, integrationSites: true }));
		const integrationSites =
			await cloudFunctionApi.fetchProjectTrackingSiteIDs(
				abortSignal,
				user,
			);

		const integrationSiteRecord = integrationSites.projects.reduce<
			Record<string, ProjectTrackingSite>
		>(
			(prev, current) => ({
				...prev,
				[current.id]: current,
			}),
			{},
		);

		setIntegrationSites(integrationSiteRecord);
		setLoading((prev) => ({ ...prev, integrationSites: false }));
	}, [abortSignal, cloudFunctionApi, user]);

	useEffect(() => {
		if (userDetails.companyID === '') {
			return;
		}
		setLoading((prev) => ({ ...prev, sites: true }));
		if (singleSite) {
			return firebaseApi.siteSubscription(userDetails.siteID, (site) => {
				setSites({ [site.id]: site });
				setLoading((prev) => ({ ...prev, sites: false }));
			});
		} else {
			return firebaseApi.activeSitesByCompanySubscription(
				userDetails.companyID,
				(sites) => {
					setSites(sites);
					setLoading((prev) => ({ ...prev, sites: false }));
				},
			);
		}
	}, [firebaseApi, singleSite, userDetails]);

	useEffect(() => {
		fetchSiteIDs();
	}, [fetchSiteIDs]);

	useEffect(() => {
		if (userDetails.companyID === '') {
			return;
		}
		setLoading((prev) => ({ ...prev, siteLinks: true }));
		if (singleSite) {
			if (userDetails.siteID === '') {
				return;
			}
			return firebaseApi.projectTrackingIntegrationSingleSiteLinkSubscription(
				userDetails.companyID,
				userDetails.siteID,
				(link) => {
					if (link !== null) {
						setSiteLinks({ [link.id]: link });
					}
					setLoading((prev) => ({ ...prev, siteLinks: false }));
				},
			);
		} else {
			return firebaseApi.projectTrackingIntegrationSiteLinksSubscription(
				userDetails.companyID,
				(links) => {
					setSiteLinks(links);
					setLoading((prev) => ({ ...prev, siteLinks: false }));
				},
			);
		}
	}, [firebaseApi, singleSite, userDetails]);

	useEffect(() => {
		const tableData = Object.values(sites).map((site) =>
			mapTableData(site, siteLinks, newLinks, integrationSites),
		);

		setTableData(tableData);
	}, [integrationSites, newLinks, siteLinks, sites]);

	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});

	const mapTableData = (
		site: Site,
		links: Record<string, SiteLink>,
		newLinks: Record<string, SiteLink>,
		integrationSites: Record<string, ProjectTrackingSite>,
	): SiteIntegrationTableRow => {
		const link: SiteLink | null = links[site.id] ?? null;
		const integrationSite = integrationSites[link?.integrationID] ?? null;

		const status = getLinkStatus(link, integrationSite);

		return {
			id: site.id,
			name: site.name,
			status: status,
			job: link ?? newLinks[site.id] ?? { id: site.id },
		};
	};

	const createLink = async (link: SiteLink): Promise<void> => {
		await cloudFunctionApi.createProjectTrackingSiteLink(
			abortSignal,
			user,
			link,
		);
	};

	const deleteLink = async (link: SiteLink): Promise<void> => {
		await cloudFunctionApi.deleteProjectTrackingSiteLink(
			abortSignal,
			user,
			link,
		);
	};

	const triggerRefresh = async (): Promise<void> => await fetchSiteIDs();

	const onLinkCellChange =
		(
			siteLink: TempSiteLink,
			site: Site,
			integrationSiteList: Record<string, IntegrationElement>,
		) =>
		(integrationID?: string): void => {
			if (integrationID === undefined) {
				setNewLinks((prev) => {
					const { [siteLink.id]: _, ...remaining } = prev;
					return remaining;
				});
				return;
			}

			const integrationSite = integrationSiteList[integrationID];

			if (integrationSite === undefined) {
				return;
			}

			setNewLinks((prev) => ({
				...prev,
				[siteLink.id]: {
					id: siteLink.id,
					name: site.name,
					integrationID: integrationID,
					integrationName: integrationSite.name,
					type: ProjectTrackingLinks.Site,
				},
			}));
		};

	const renderStatusChip = (status: IntegrationLinkStatus): JSX.Element => (
		<IntegrationStatusChip status={status} />
	);

	const renderJob = (siteLink: TempSiteLink): JSX.Element => {
		const trueLink = siteLinks[siteLink.id];
		const site = sites[siteLink.id];

		const options = Object.values(integrationSites).reduce<
			Record<string, OptionWithDisabled>
		>(
			(prev, current) => ({
				...prev,
				[current.id]: {
					id: current.id,
					name: `${current.name} - ${current.description}`,
					disabled: false,
				},
			}),
			{},
		);

		const onChange = onLinkCellChange(siteLink, site, integrationSites);
		const linking = !!linkingRecords[siteLink.id];

		return (
			<IntegrationLinkCell
				link={trueLink}
				linkIntegrationID={
					isSiteLink(siteLink) ? siteLink.integrationID : undefined
				}
				options={options}
				onChange={onChange}
				missingLinkLabel="Missing Workbench Job"
				missingLinkMessage="Linked item not found in Workbench"
				disabled={linking}
				selectOverride="Select Job"
			/>
		);
	};

	const renderOptionButton = (siteID: string): JSX.Element => {
		const link: SiteLink | undefined = siteLinks[siteID];
		const newLink: SiteLink | undefined = newLinks[siteID];

		const handleCreateLink = async (): Promise<void> => {
			setLinkingRecords((prev) => ({ ...prev, [siteID]: true }));
			await createLink(newLink);
			setNewLinks((prev) => {
				const { [siteID]: _, ...remaining } = prev;
				return remaining;
			});
			setLinkingRecords((prev) => {
				const { [siteID]: _, ...remaining } = prev;
				return remaining;
			});
		};
		const handleDeleteLink = async (): Promise<void> => {
			setLinkingRecords((prev) => ({ ...prev, [siteID]: true }));
			await deleteLink(link);
			setLinkingRecords((prev) => {
				const { [siteID]: _, ...remaining } = prev;
				return remaining;
			});
		};

		const disabled = !link && newLink === undefined;

		return (
			<IntegrationLinkButton
				link={link}
				disabled={disabled}
				handleLinkClick={handleCreateLink}
				handleUnlinkClick={handleDeleteLink}
			/>
		);
	};

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'name',
			label: 'Name',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthExtra,
					},
				}),
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthLess,
					},
				}),
				customBodyRender: (value: IntegrationLinkStatus) => (
					<Box display="flex" justifyContent="center">
						{renderStatusChip(value)}
					</Box>
				),
			},
		},
		{
			name: 'job',
			label: 'Workbench Job',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthExtra,
					},
				}),
				sortCompare: linkTableSort,
				customBodyRender: (siteLink: SiteLink) => (
					<Box display="flex" justifyContent="center">
						{renderJob(siteLink)}
					</Box>
				),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthLess,
					},
				}),
				customBodyRender: renderOptionButton,
			},
		},
	];

	return (
		<Box flex="1">
			<Card>
				<IntegrationTable
					title="Link Sites"
					data={tableData}
					columns={columns}
					triggerRefresh={triggerRefresh}
					loading={
						loading.siteLinks ||
						loading.sites ||
						loading.integrationSites
					}
					missingDataLabel="Sorry, no sites found"
					centeredColumns={[2, 3, 4]}
					customSearch={siteIntegrationTableRowSearch}
				/>
			</Card>
		</Box>
	);
};
