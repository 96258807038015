import firebase from 'firebase';
import { UserInvite } from '../constants/UserInvite';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type ResendUserInviteBody = {
	id: UserInvite['id'];
};

export const resendUserInvite = async (
	abortSignal: AbortSignal,
	user: firebase.User,
	body: ResendUserInviteBody,
): Promise<boolean> =>
	await makeCloudFunctionRequestReturnSuccessStatus<ResendUserInviteBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.ResendUserInvite,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body,
	});
