import {
	Autocomplete,
	MenuItem,
	TextField,
	TextFieldProps,
} from '@mui/material';
import { ExplicitAny } from '../../constants/AnyTypes';
import { Company } from '../../constants/Common';
import {
	GroupBy,
	sortObjectByField,
	sortObjectByGroupBy,
} from '../helpers/sortHelpers';

export type SimpleCompanyAutocompleteProps<
	GroupByOrder extends string[] = [
		CompanySelectGroup.RecentClients,
		CompanySelectGroup.Clients,
	],
> = {
	label: string;
	value: string | null;
	onChange: (company: Pick<Company, 'id' | 'name'> | null) => void;
	companies: Record<string, Company>;
	disabled: boolean;
	error?: boolean;
	helperText?: TextFieldProps['helperText'];
	groupBy?: GroupBy<GroupByOrder, Company>;
	size?: TextFieldProps['size'];
	required?: boolean;
};

export enum CompanySelectGroup {
	RecentClients = 'Recent Clients',
	Clients = 'Clients',
}

export const SimpleCompanyAutocomplete = function <
	GroupByOrder extends string[] = [
		CompanySelectGroup.RecentClients,
		CompanySelectGroup.Clients,
	],
>({
	label,
	value,
	onChange,
	companies,
	groupBy,
	disabled,
	error,
	helperText,
	required,
	size = 'small',
}: SimpleCompanyAutocompleteProps<GroupByOrder>): JSX.Element {
	let sortedCompanies = sortObjectByField(companies, 'name');
	if (groupBy) {
		sortedCompanies = sortObjectByGroupBy(
			sortedCompanies,
			groupBy.getGroup,
			groupBy.order,
		);
	}
	const keyGroupBy = groupBy?.getGroup ?? ((): string => '');

	const handleOnChange = (
		_: React.SyntheticEvent,
		selectedCompany: Company | null,
	): void =>
		onChange(
			selectedCompany
				? {
						id: selectedCompany.id,
						name: selectedCompany.name,
				  }
				: selectedCompany,
		);

	return (
		<Autocomplete
			fullWidth
			data-testid="company-autocomplete"
			value={value ? companies[value] ?? null : null}
			options={Object.values(sortedCompanies)}
			getOptionLabel={(option): string => option?.name ?? ''}
			isOptionEqualToValue={(option, value): boolean => option === value}
			groupBy={groupBy?.getGroup}
			onChange={handleOnChange}
			disabled={disabled}
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					label={label}
					size={size}
					error={error}
					helperText={helperText}
					required={required}
				/>
			)}
			renderOption={(
				props: ExplicitAny,
				option: Company,
			): JSX.Element | undefined => (
				<MenuItem
					{...props}
					label={option.name}
					variant="standard"
					key={option.id + keyGroupBy(option)}>
					{option ? option.name : 'No Company'}
				</MenuItem>
			)}
		/>
	);
};
