import {
	accountUserInvitesMap,
	UserInvitesAccountType,
} from '../../../constants/Accounts';
import { CompanyTypes } from '../../../constants/Common';
import {
	CreateUserInviteDialogActions,
	CreateUserInviteDialogActionTypes,
	CreateState,
	CreateStateBase,
} from './actions';
import { CreateUserInviteStatuses } from './CreateUserInviteSnackBar';

export const createInitialCreateUserDialogState = (initArg: {
	userAccountType: UserInvitesAccountType;
}): CreateState => {
	const initialState: CreateStateBase = {
		firstName: '',
		lastName: '',
		email: '',
		accountType: accountUserInvitesMap[initArg.userAccountType][0],
		mobileNumber: '+64',
		site: null,
		contractedTo: null,
		createAnother: false,
		errors: {},
		createUserInviteStatus: CreateUserInviteStatuses.None,
	};

	return {
		...initialState,
		initialState: { ...initialState },
	};
};

export const createUserDialogReducer = (
	state: CreateState,
	action: CreateUserInviteDialogActions,
): CreateState => {
	switch (action.type) {
		case CreateUserInviteDialogActionTypes.UPDATE_FIRST_NAME: {
			return {
				...state,
				firstName: action.payload.firstName,
				errors: {
					...state.errors,
					firstName: undefined,
				},
			};
		}
		case CreateUserInviteDialogActionTypes.UPDATE_LAST_NAME: {
			return {
				...state,
				lastName: action.payload.lastName,
				errors: {
					...state.errors,
					lastName: undefined,
				},
			};
		}
		case CreateUserInviteDialogActionTypes.UPDATE_EMAIL: {
			return {
				...state,
				email: action.payload.email,
				errors: {
					...state.errors,
					email: undefined,
				},
			};
		}
		case CreateUserInviteDialogActionTypes.UPDATE_ACCOUNT_TYPE: {
			return {
				...state,
				accountType: action.payload.accountType,
			};
		}
		case CreateUserInviteDialogActionTypes.UPDATE_MOBILE_NUMBER: {
			return {
				...state,
				mobileNumber: action.payload.mobileNumber,
				errors: {
					...state.errors,
					mobileNumber: undefined,
				},
			};
		}
		case CreateUserInviteDialogActionTypes.UPDATE_SITE: {
			const isRecruitmentSite =
				action.payload.site?.companyType === CompanyTypes.recruitment;

			const updatedSiteFields = action.payload.site
				? {
						site: action.payload.site,
						contractedTo: isRecruitmentSite
							? {
									id: action.payload.site.companyID,
									name: action.payload.site.company,
							  }
							: // if the current site state is changing from recruitment then we need to reset the contractedTo field
							state.site?.companyType === CompanyTypes.recruitment
							? null
							: state.contractedTo,
				  }
				: {
						site: null,
				  };

			return {
				...state,
				...updatedSiteFields,
			};
		}
		case CreateUserInviteDialogActionTypes.UPDATE_CONTRACTED_TO: {
			return {
				...state,
				contractedTo: action.payload.contractedTo,
			};
		}
		case CreateUserInviteDialogActionTypes.UPDATE_CREATE_ANOTHER: {
			return {
				...state,
				createAnother: action.payload.createAnother,
			};
		}
		case CreateUserInviteDialogActionTypes.UPDATE_ERRORS: {
			return {
				...state,
				errors: { ...state.errors, ...action.payload.errors },
			};
		}
		case CreateUserInviteDialogActionTypes.UPDATE_USER_INVITE_STATUS: {
			return {
				...state,
				createUserInviteStatus: action.payload.createUserInviteStatus,
			};
		}
		case CreateUserInviteDialogActionTypes.SET_INITIAL_STATE: {
			return {
				...state.initialState,
				...action.payload.newState,
				initialState: state.initialState,
			};
		}
		default:
			return state;
	}
};
