import type { User } from '../firebase/firebase';
import { AllIntegrationActivityTypesResponse } from '../models/Integrations/IntegrationElements';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export const fetchInvoicingActivityIDs = async (
	abortSignal: AbortSignal,
	user: User,
): Promise<AllIntegrationActivityTypesResponse | undefined> => {
	return await makeCloudFunctionRequestDeprecated<AllIntegrationActivityTypesResponse>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.InvoicingActivityIDs,
			method: 'GET',
			services: defaultCloudFunctionServices,
		},
	);
};
