import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type CreateTemplateBody = {
	templateName: string;
	templateFields: Record<string, number>;
	templateBody: string;
};

export const createTemplate = async (
	abortSignal: AbortSignal,
	user: User,
	templateName: string,
	templateFields: Record<string, number>,
	templateBody: string,
): Promise<void> => {
	await makeCloudFunctionRequestReturnSuccessStatus<CreateTemplateBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.CreateTemplate,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: {
			templateName,
			templateFields,
			templateBody,
		},
	});
};
