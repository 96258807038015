import { MUIDataTableColumnDef } from 'mui-datatables';
import { Dispatch, SetStateAction } from 'react';
import type { Timestamp } from '../../firebase/firebase';
import {
	AccountType,
	accountTypes,
	Activity,
	DayString,
	SiteLog,
	UserDetails,
	WorkerType,
} from '../Common';
import { TimesheetNote } from '../Note';
import { TimesheetStatus } from './TimesheetStatus';

export type Timesheet = {
	id: string;
	employer: {
		id: string;
		name: string;
	};
	invoiceStatus: InvoiceStatuses;
	payrollStatus: TimesheetPayrollStatus;
	projectTrackingStatus: ProjectTrackingStatuses;
	workHistoryStatus: WorkHistoryStatus;
	site: {
		companyID: string;
		company: string;
		id: string;
		name: string;
	};
	contractedTo: {
		id: string;
		name: string;
	} | null;
	timesheetStatus: TimesheetStatus;
	week: Timestamp;
	weekEnding: Timestamp;
	employee: {
		id: string;
		name: string;
		paid: boolean;
		type: WorkerType;
	};
	dateSubmitted: Timestamp | null;
	reviewedAt: Timestamp | null;
	reviewer: {
		id: string;
		name: string;
	} | null;
	lastEditedBy: {
		id: string;
		name: string;
	};
	hours: {
		monday: TimesheetHours;
		tuesday: TimesheetHours;
		wednesday: TimesheetHours;
		thursday: TimesheetHours;
		friday: TimesheetHours;
		saturday: TimesheetHours;
		sunday: TimesheetHours;
		total: TimesheetHours;
	};
	cost: {
		billable: number; // chargeOutRate * hours.total.billable
	};
	contract: PayAndChargeOutRate;
	preApproval?: {
		reviewedAt: NonNullable<Timesheet['reviewedAt']>;
		reviewer: NonNullable<Timesheet['reviewer']>;
		weekEnding: Timesheet['weekEnding'];
		hours: Timesheet['hours'];
	};
};

type TimesheetHours = {
	billable: number;
	break: number;
};

export enum ProjectTrackingStatuses {
	Sent = 'Sent',
	Unsent = 'Unsent',
	Failed = 'Failed',
}
export type ProjectTrackingStatus = `${ProjectTrackingStatuses}`;

export enum InvoiceStatuses {
	Sent = 'Sent',
	Unsent = 'Unsent',
	Failed = 'Failed',
}
export const invoiceStatus = [
	InvoiceStatuses.Unsent,
	InvoiceStatuses.Sent,
	InvoiceStatuses.Failed,
] as const;
export type InvoiceStatus = (typeof invoiceStatus)[number];

export const timesheetPayrollStatus = ['Unsent', 'Sent', 'Failed'] as const;
export type TimesheetPayrollStatus = (typeof timesheetPayrollStatus)[number];
export enum TimesheetPayrollStatuses {
	Sent = 'Sent',
	Unsent = 'Unsent',
	Failed = 'Failed',
}

export enum WorkHistoryStatus {
	Unsent = 'Unsent',
	Sent = 'Sent',
	Failed = 'Failed',
}

export type TimesheetDateDataTableProps = {
	title: React.ReactNode;
	data: Timesheet[];
	columns: MUIDataTableColumnDef[];
	startDate: Date;
	setStartDate: Dispatch<SetStateAction<Date>>;
	selected: number;
	setSelected: Dispatch<SetStateAction<number>>;
	loading: boolean;
	type: UserDetails['accountType'];
	handleClickOpen?: () => void;
};

export type SplitOptions = 'employer' | 'site';

export type TimesheetFirebaseFilterType =
	| 'approved'
	| 'archived'
	| 'review'
	| 'current';

export type TimesheetConfig = {
	titles: string[];
	firebaseFilters: Partial<
		Record<TimesheetFirebaseFilterType, (TimesheetStatus | '')[]>
	>;
};

export type PayAndChargeOutRate =
	| {
			payRate: number;
			chargeOutRate: number;
			source: 'Employee';
	  }
	| {
			id: string;
			payRate: number;
			chargeOutRate: number;
			source: 'Contract';
	  };

export type ValidStatus = Exclude<TimesheetStatus, TimesheetStatus.Active>;

export const timesheetAccountType = [
	'management',
	'handler',
	'seniorManagement',
] as const;

export type TimesheetAccountType = AccountType &
	(typeof timesheetAccountType)[number];

export const getTimesheetConfig = (
	accountType: TimesheetAccountType,
	canEditSite?: boolean,
): TimesheetConfig => {
	const current = [
		TimesheetStatus.Submitted,
		TimesheetStatus.Active,
		TimesheetStatus.Approved,
	];

	switch (accountType) {
		case accountTypes.management:
		case accountTypes.seniorManagement: {
			return {
				titles: [
					'overview',
					'review',
					'create',
					'export',
					'site activities',
				],
				firebaseFilters: {
					current,
				},
			};
		}
		case accountTypes.handler: {
			const titles = ['overview', 'details', 'create', 'export'];
			if (canEditSite) titles.push('site activities');
			return {
				titles,
				firebaseFilters: {
					current,
					archived: [TimesheetStatus.Archived],
				},
			};
		}
	}
};

export type ActivityEditFields = 'id' | 'activity' | 'hours' | 'day';
export type TimesheetTableActivity = Pick<Activity, ActivityEditFields>;

export type TimesheetTableBreaks = Record<
	Lowercase<DayString>,
	Pick<Timesheet['hours']['total'], 'break'>
>;

export type TimesheetTableRowTimesheet = {
	day: DayString;
	activities: TimesheetTableActivity[];
	siteLogs: {
		In: TimesheetSiteLog | null;
		Out: TimesheetSiteLog | null;
		formattedLogs: string | null;
	};
	breaks: TimesheetTableBreaks[Lowercase<DayString>] | null;
	autoCheck: boolean | null;
	notes: TimesheetNote[];
};

export type TimesheetSiteLog = Pick<
	SiteLog,
	'id' | 'datetime' | 'type' | 'url'
>;

export type TempTimesheet = Pick<
	Timesheet,
	| 'id'
	| 'employee'
	| 'employer'
	| 'site'
	| 'hours'
	| 'dateSubmitted'
	| 'contractedTo'
	| 'payrollStatus'
	| 'projectTrackingStatus'
	| 'invoiceStatus'
	| 'preApproval'
> & {
	timesheetStatus: null;
};

export const isTempTimesheet = (
	timesheet: Timesheet | TempTimesheet,
): timesheet is TempTimesheet => {
	// If timesheetStatus is null it must be a temp timesheet
	const hasNullTimesheetStatus = timesheet.timesheetStatus === null;
	return hasNullTimesheetStatus;
};
