import { InducteeType, InductionEntry } from '../constants/InductionEntry';
import { SafetyCourse } from '../constants/SafetyCourse';
import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';
import { inductionStepCreate } from './inductionStepCreate';
import { inductionStepDelete } from './inductionStepDelete';
import { inductionStepUpdate } from './inductionStepUpdate';

type CreateInductionRequest = {
	inductee: Pick<InductionEntry['inductee'], 'type' | 'id'>;
	site: {
		id: string;
	};
	safetyCourse?: {
		id: SafetyCourse['course']['id'];
		type: SafetyCourse['course']['type'];
		name: SafetyCourse['course']['name'];
		expiryDate: string | null;
	};
};

const createInduction = async (
	abortSignal: AbortSignal,
	user: User,
	inducteeID: string,
	inducteeType: InducteeType,
	siteID: string,
	course?: SafetyCourse['course'],
): Promise<boolean | undefined> => {
	const body: CreateInductionRequest = {
		inductee: {
			id: inducteeID,
			type: inducteeType,
		},
		site: { id: siteID },
		safetyCourse: course
			? {
					id: course.id,
					type: course.type,
					name: course.name,
					expiryDate: course.expiryDate
						? course.expiryDate.toDate().toISOString()
						: null,
			  }
			: undefined,
	};
	return await makeCloudFunctionRequestReturnSuccessStatus({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.CreateInduction,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body,
	});
};

type UpdateInductionRequest = {
	id: string;
	safetyCourse: {
		id: SafetyCourse['course']['id'];
		type: SafetyCourse['course']['type'];
		name: SafetyCourse['course']['name'];
		expiryDate: string | null;
	};
};

const updateInduction = async (
	abortSignal: AbortSignal,
	user: User,
	inductionID: string,
	course: SafetyCourse['course'],
): Promise<boolean | undefined> => {
	const body: UpdateInductionRequest = {
		id: inductionID,
		safetyCourse: {
			id: course.id,
			type: course.type,
			name: course.name,
			expiryDate: course.expiryDate
				? course.expiryDate.toDate().toISOString()
				: null,
		},
	};
	return await makeCloudFunctionRequestReturnSuccessStatus({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.UpdateInduction,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body,
	});
};

export const inductionsFirestore = {
	createInduction,
	updateInduction,
	inductionStepCreate,
	inductionStepUpdate,
	inductionStepDelete,
};
