import { Box, Typography, Button, Stack } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useState } from 'react';
import {
	SubscriptionStatuses,
	UserDetails,
	accountTypes,
} from '../../constants/Common';
import type { User } from '../../firebase/firebase';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { DataTable } from '../DataTable/DataTable';
import { CustomActionDialog } from '../Dialogs/CustomActionDialog';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { configureManageSubscriptionButton } from '../SubscriptionControl/ManageSubscriptionButton';
import { ApproveAccountDialog } from './ApproveAccountDialog';
import { AccountsState } from './StateManagement/actions';

export type NewAccountsProps = {
	userDetails: UserDetails;
	user?: User | null;
	userCompany: AccountsState['userCompany'];
	sites: AccountsState['sites'];
	companies: AccountsState['companies'];
	unapprovedUsers: AccountsState['unapprovedUsers'];
	loading: AccountsState['loadingNewAccounts'];
	firebaseApi: Pick<
		FirebaseApi,
		'updateSiteContractedToCompany' | 'approveUserAccount'
	>;
};

export const NewAccounts = ({
	user,
	userDetails,
	unapprovedUsers,
	userCompany,
	sites,
	companies,
	loading,
	firebaseApi,
}: NewAccountsProps): JSX.Element => {
	const [selectedUser, setSelectedUser] = useState<UserDetails>();
	const [approvedDialogOpen, setApprovedDialogOpen] = useState(false);
	const [warningDialogOpen, setWarningDialogOpen] = useState(false);

	const canApproveUsersWhileTrialing =
		userDetails.accountType === accountTypes.handler;
	const canManageSubscription =
		userDetails.accountType === accountTypes.seniorManagement ||
		userDetails.accountType === accountTypes.handler;
	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		'No accounts to approve'
	);

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 302px)',
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
	};

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'displayName',
			label: 'Name',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '50%',
					},
				}),
			},
		},
		{
			name: 'site',
			label: 'Site',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '30%',
					},
				}),
				customBodyRender: (site): JSX.Element => (
					<>{site ? site : 'No Site'}</>
				),
			},
		},
		{
			name: 'userID',
			label: 'Options',
			options: {
				sort: false,
				filter: false,
				setCellHeaderProps: () => ({
					style: {
						width: '20%',
					},
				}),
				customBodyRender: (id): JSX.Element => (
					<Stack direction="row" spacing={1} width="100%" pr={1}>
						<Box minWidth="50%">
							<Button
								fullWidth
								variant="outlined"
								onClick={(): Promise<void> => denyUser(id)}
								data-testid={`${id}-deny-button`}>
								Deny
							</Button>
						</Box>
						<Box minWidth="50%">
							<Button
								fullWidth
								variant="contained"
								onClick={(): void => approveUser(id)}
								data-testid={`${id}-approve-button`}>
								Approve
							</Button>
						</Box>
					</Stack>
				),
			},
		},
	];

	const approveUser = (id: string): void => {
		const selectedUser = unapprovedUsers[id];
		if (!selectedUser) {
			return;
		} else if (
			userCompany?.subscriptionStatus === SubscriptionStatuses.Trialing &&
			selectedUser.companyID === userDetails.companyID &&
			!canApproveUsersWhileTrialing
		) {
			// This stops construction companies from approving their users if they are on a trial
			setWarningDialogOpen(true);
		} else {
			setSelectedUser(selectedUser);
			setApprovedDialogOpen(true);
		}
	};

	const denyUser = async (id: string): Promise<void> => {
		const selectedUser = unapprovedUsers[id];
		if (selectedUser) {
			await firebaseApi.updateSiteContractedToCompany(
				selectedUser.userID,
				{
					site: '',
					siteID: '',
					siteCompany: '',
					siteCompanyID: '',
					company: '',
					companyID: '',
					contractedTo: null,
				},
			);
		}
	};

	const approve = async (
		newAccountInfo: Pick<
			UserDetails,
			| 'accountType'
			| 'workerType'
			| 'site'
			| 'siteID'
			| 'siteCompany'
			| 'siteCompanyID'
			| 'contractedTo'
			| 'company'
			| 'companyID'
		>,
	): Promise<void> => {
		if (selectedUser?.userID) {
			await firebaseApi.approveUserAccount(
				selectedUser.userID,
				newAccountInfo,
			);
		}
	};

	const warningText = !canManageSubscription ? (
		<>
			<Typography>
				{userDetails.company} is on a free trial which does not allow
				for the inclusion of direct employees other than management. You
				do not have permission within {userDetails.company} company to
				upgrade your plan.
			</Typography>
			<Typography marginTop={2}>
				To begin onboarding staff, contact Trade Legion or your manager.
			</Typography>
		</>
	) : (
		<>
			<Typography>
				{userDetails.company} is on a free trial, which does not allow
				for the inclusion of direct employees other than management.
				Upgrade your plan to begin onboarding staff.
			</Typography>
			<Typography marginTop={2}>
				Contact Trade Legion if you would like support with this.
			</Typography>
		</>
	);

	return (
		<>
			<Box flex={1} mt={1}>
				<DataTable
					tableData={Object.values(unapprovedUsers)}
					columns={columns}
					title="New Accounts"
					customTableOptions={tableOptions}
				/>
			</Box>
			{selectedUser && (
				<ApproveAccountDialog
					userDetails={userDetails}
					closeDialog={(): void => setApprovedDialogOpen(false)}
					dialogOpen={approvedDialogOpen}
					userForApproval={selectedUser}
					companies={companies}
					sites={sites}
					approveUser={approve}
				/>
			)}
			{user &&
				userCompany?.subscriptionStatus &&
				userCompany.subscriptionStatus !==
					SubscriptionStatuses.Incomplete && (
					<CustomActionDialog
						title="Trialing Subscription"
						content={warningText}
						isOpen={warningDialogOpen}
						setIsOpen={setWarningDialogOpen}
						actionButton={
							canManageSubscription ? (
								configureManageSubscriptionButton(
									userCompany,
									user,
									window.location.href,
								)
							) : (
								<></>
							)
						}
					/>
				)}
		</>
	);
};
