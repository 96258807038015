import Close from '@mui/icons-material/Close';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Stack,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import earmuffsImg from '../../images/Earmuffs.png';
import glovesImg from '../../images/Gloves.png';
import highVisImg from '../../images/High-Vis.jpg';
import maskImg from '../../images/Mask.jpg';
import glassesImg from '../../images/Safety-Glasses.png';
import hatImg from '../../images/Work-Hat.jpg';
import { PPECard, PPECardInfo, PPEItem } from './PPECard';
import { PPECart } from './PPECart';

export const PPEStore = (): JSX.Element => {
	const [items, setItems] = useState<PPEItem[]>([]);
	const [toDelete, setToDelete] = useState<number | null>(null);
	const [toUpdate, setToUpdate] = useState<
		[number, 'quantity', number] | [number, 'size', string | null] | null
	>(null);
	const [open, setOpen] = useState(false);
	const [purchaseChoice, setPurchaseChoice] = useState(0);
	const storeItemLimit = 1000;

	useEffect(() => {
		if (toDelete !== null) {
			const newList = [...items];
			newList.splice(toDelete, 1);
			setItems(newList);
			setToDelete(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toDelete]);

	useEffect(() => {
		if (toUpdate !== null) {
			const [index, updatefield, value] = toUpdate;
			if (updatefield === 'quantity') {
				const newList = [...items];
				newList[index].quantity = value;
				setItems(newList);
				setToUpdate(null);
			} else if (updatefield === 'size') {
				const newList = [...items];
				newList[index].size = value;
				setItems(newList);
				setToUpdate(null);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toUpdate]);

	const addItem = (item: PPEItem): void => {
		const foundIndex = items.findIndex(
			(iteratingItem) =>
				iteratingItem.name === item.name &&
				iteratingItem.size === item.size,
		);
		if (foundIndex < 0) {
			const newList = [...items];
			newList.push(item);
			setItems(newList);
		} else {
			const oldItem = items[foundIndex];
			if (oldItem.quantity + item.quantity > storeItemLimit) {
				return;
			}
			oldItem.quantity = oldItem.quantity + item.quantity;
			const newList = [...items];
			newList[foundIndex] = oldItem;
			setItems(newList);
		}
	};

	const removeItem = useCallback(
		(index: number | null) => {
			setToDelete(index);
		},
		[setToDelete],
	);

	const updateItem = useCallback(
		(
			update:
				| [number, 'quantity', number]
				| [number, 'size', string | null]
				| null,
		) => {
			setToUpdate(update);
		},
		[setToUpdate],
	);

	const openModal = useCallback(() => {
		setOpen(!open);
	}, [open]);

	const onClose = (): void => {
		setOpen(!open);
	};

	const modalBody = (
		<DialogContent>
			<DialogTitle>
				Purchase Options
				{onClose && (
					<IconButton
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}>
						<Close />
					</IconButton>
				)}
			</DialogTitle>
			<DialogActions>
				<Grid container spacing={2} padding={2}>
					<Grid item xs={12}>
						<Button
							variant="contained"
							disableElevation
							onClick={(): void => setPurchaseChoice(1)}
							fullWidth
							color={
								purchaseChoice === 1 ? 'primary' : 'neutral'
							}>
							Ship to Office
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							disableElevation
							onClick={(): void => setPurchaseChoice(2)}
							fullWidth
							color={
								purchaseChoice === 2 ? 'primary' : 'neutral'
							}>
							Create Monthly Subscription
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							disableElevation
							onClick={(): void => setPurchaseChoice(3)}
							fullWidth
							color={
								purchaseChoice === 3 ? 'primary' : 'neutral'
							}>
							Deliver to Site
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</DialogContent>
	);

	return (
		<>
			<Stack
				direction="row"
				spacing={2}
				divider={<Divider orientation="vertical" flexItem />}>
				<Grid container spacing={2}>
					{cards.map((item, index) => (
						<Grid
							item
							key={index}
							xs={12}
							sm={6}
							md={4}
							display="flex"
							justifyContent="center">
							<PPECard
								card={item}
								addItem={addItem}
								itemLimit={storeItemLimit}
							/>
						</Grid>
					))}
				</Grid>
				<Box
					sx={{
						width: '35%',
					}}>
					<PPECart
						items={items}
						removeItem={removeItem}
						updateItem={updateItem}
						openModal={openModal}
						itemLimit={storeItemLimit}
					/>
				</Box>
			</Stack>
			<Dialog open={open} onClose={openModal} maxWidth="xs">
				{modalBody}
			</Dialog>
		</>
	);
};

/*
    This is demo data not from firestore.
    All this data is meant for demo purposes only.
*/
const cards: PPECardInfo[] = [
	{
		imgSrc: glovesImg,
		name: 'Gloves',
		price: 1,
		sizes: ['XS', 'S', 'M', 'L', 'XL'],
		id: 'gloves',
	},
	{
		imgSrc: glassesImg,
		name: 'Glasses',
		price: 1,
		id: 'glasses',
	},
	{
		imgSrc: hatImg,
		name: 'Work Hats',
		price: 6,
		id: 'work-hats',
	},
	{
		imgSrc: earmuffsImg,
		name: 'Earmuffs',
		price: 9,
		id: 'earmuffs',
	},
	{
		imgSrc: maskImg,
		name: 'Masks',
		price: 1,
		id: 'masks',
	},
	{
		imgSrc: highVisImg,
		name: 'High Vis',
		price: 8,
		sizes: ['XS', 'S', 'M', 'L', 'XL'],
		id: 'high-vis',
	},
];
