import { Stack, Typography } from '@mui/material';
import { formatSlashedDate } from '../../helpers/dateFormatters';
import { getSentOnStatusMessage, UserInvitesTableData } from './helpers';

export type UserInviteSentOnProps = {
	sentOn: UserInvitesTableData['sentOn'];
};

export const UserInviteSentOn = ({
	sentOn,
}: UserInviteSentOnProps): JSX.Element => {
	const dateCreated = sentOn.createdAt.toDate();
	const formattedDateCreated = formatSlashedDate(dateCreated);
	const message = getSentOnStatusMessage(sentOn, new Date());

	return (
		<Stack
			display="flex"
			alignItems="center"
			flexDirection="column"
			justifyContent="center"
			textAlign="center">
			<Typography variant="body2">{formattedDateCreated}</Typography>
			<Typography variant="body2" lineHeight={1}>
				{message}
			</Typography>
		</Stack>
	);
};
