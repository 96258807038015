import { UserInvite } from '../constants/UserInvite';
import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type CreateUserInviteBody = {
	firstName: UserInvite['firstName'];
	lastName: UserInvite['lastName'];
	email: UserInvite['email'];
	accountType: UserInvite['accountType'];
	mobileNumber: UserInvite['mobileNumber'];
	site: UserInvite['site'];
	company: UserInvite['company'];
	contractedTo: UserInvite['contractedTo'];
};

export const createUserInvite = async (
	abortSignal: AbortSignal,
	user: User,
	body: CreateUserInviteBody,
): Promise<boolean> =>
	await makeCloudFunctionRequestReturnSuccessStatus<CreateUserInviteBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.CreateUserInvite,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body,
	});
