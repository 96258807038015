import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { Activity, DayString, TimesheetActivity } from '../../constants/Common';
import { Timestamp } from '../../firebase/firebase';
import { formatActivityDisplayDay } from '../helpers/dateFormatters';
import { getDayOfWeekDate } from '../helpers/dateUtilities';
import { getUuid } from '../helpers/uuidHelpers';

/**
 * @deprecated This is the timesheets display timesheeet type and should not be used
 */

export type TimesheetType =
	| 'history'
	| 'review'
	| 'approved'
	| 'archived'
	| 'current'
	| 'submitted'
	| 'active';

/**
 * @deprecated This is the old timesheets display WeekActivities type and should not be used
 */

export type WeekActivities = Record<
	DayString,
	{
		activities: Record<string, Activity>;
	}
>;
/**
 * @deprecated This is an old timesheets display view type and should not be used
 */

export type TotalError = {
	totalError?: string;
};
/**
 * @deprecated This is an old timesheets display view type and should not be used
 */

export type TimesheetError = {
	typeError?: string;
	totalError?: string;
};

export const handleUpdateActivity = (
	id: string,
	day: DayString,
	field: 'hours' | 'id',
	value: string,
	updatedWeekActivities: WeekActivities,
	toAddActivitiesList: Record<string, Activity>,
	toDeleteActivitiesList: string[],
	activityTypeMap: Record<string, TimesheetActivity>,
	setToAddActivitiesList: Dispatch<SetStateAction<Record<string, Activity>>>,
	setUpdatedWeekActivities: Dispatch<SetStateAction<WeekActivities>>,
	setToDeleteActivitiesList: Dispatch<SetStateAction<string[]>>,
	setErrorMap: Dispatch<
		SetStateAction<Partial<Record<string, TimesheetError>>>
	>,
): void => {
	const updatedActivities = cloneDeep(updatedWeekActivities);
	const addedActivities = cloneDeep(toAddActivitiesList);
	const deletedActivitiesList = cloneDeep(toDeleteActivitiesList);

	const currentActivity = cloneDeep(updatedActivities[day].activities[id]);

	const addedActivity = addedActivities[id];
	if (field === 'id') {
		const activity = {
			name: activityTypeMap[value].activityName,
			id: value,
		};
		currentActivity.activity = activity;
		if (addedActivity) {
			addedActivity.activity = activity;
		}
	} else {
		currentActivity[field] = Number(value);
		if (addedActivity) {
			addedActivity[field] = Number(value);
		}
	}
	updatedActivities[day].activities[id] = currentActivity;
	if (addedActivity) {
		addedActivities[id] = addedActivity;
		setToAddActivitiesList(addedActivities);
	}

	setErrorMap((prevErrors) => {
		const error = prevErrors[id];
		field === 'id' ? delete error?.typeError : delete error?.totalError;
		prevErrors[id] = error;
		return prevErrors;
	});
	setUpdatedWeekActivities(updatedActivities);
	setToDeleteActivitiesList(deletedActivitiesList);
};

export const validateTimesheetEntries = (
	updatedWeekActivities: WeekActivities,
	setErrorMap: React.Dispatch<
		React.SetStateAction<Partial<Record<string, TimesheetError>>>
	>,
): boolean => {
	const activityErrorMap: Partial<Record<string, TimesheetError>> = {};

	Object.values(cloneDeep(updatedWeekActivities)).forEach((activity) => {
		const updatedDay = cloneDeep(activity);

		Object.values(updatedDay.activities).forEach((currentActivity) => {
			if (!currentActivity.activity.id) {
				activityErrorMap[currentActivity.id] = {
					typeError: 'Missing Activity',
				};
			}

			if (currentActivity.hours === 0) {
				activityErrorMap[currentActivity.id] = {
					...activityErrorMap[currentActivity.id],
					totalError: 'Hours not set',
				};
			}
		});
	});
	setErrorMap(activityErrorMap);
	return Object.keys(activityErrorMap).length === 0;
};

export const handleAddToDay = (
	day: DayString,
	updatedWeekActivities: WeekActivities,
	toAddActivitiesList: Record<string, Activity>,
	entries: Omit<
		Activity,
		| 'id'
		| 'date'
		| 'day'
		| 'displayDay'
		| 'hours'
		| 'totalCost'
		| 'activity'
		| 'finalReviewBy'
		| 'finalReviewAt'
	>,
	setUpdatedWeekActivities: Dispatch<SetStateAction<WeekActivities>>,
	setToAddActivitiesList: Dispatch<SetStateAction<Record<string, Activity>>>,
): void => {
	const newActivities = cloneDeep(updatedWeekActivities);
	const activitiesToAdd = cloneDeep(toAddActivitiesList);

	const activityId = getUuid();
	const date = getDayOfWeekDate(entries.week.toDate(), day);

	const currentTimesheetEntry: Activity = {
		id: activityId,
		activity: {
			name: '',
			id: '',
		},
		date: Timestamp.fromDate(date),
		day: day,
		displayDay: formatActivityDisplayDay(date),
		employerName: entries.employerName,
		employerID: entries.employerID,
		hours: 0,
		rate: entries.rate,
		finalReviewAt: null,
		finalReviewBy: null,
		siteCompany: entries.siteCompany,
		siteCompanyID: entries.siteCompanyID,
		siteID: entries.siteID,
		siteName: entries.siteName,
		status: entries.status,
		timesheetID: entries.timesheetID,
		totalCost: 0,
		week: entries.week,
		weekEnding: entries.weekEnding,
		workerID: entries.workerID,
		workerName: entries.workerName,
	};

	newActivities[day].activities[activityId] = currentTimesheetEntry;
	activitiesToAdd[activityId] = currentTimesheetEntry;
	setUpdatedWeekActivities(newActivities);
	setToAddActivitiesList(activitiesToAdd);
};

export const handleDeleteActivity = (
	day: DayString,
	activityId: string,
	updatedWeekActivities: WeekActivities,
	toAddActivitiesList: Record<string, Activity>,
	toDeleteActivitiesList: string[],
	setToAddActivitiesList: Dispatch<SetStateAction<Record<string, Activity>>>,
	setUpdatedWeekActivities: Dispatch<SetStateAction<WeekActivities>>,
	setToDeleteActivitiesList: Dispatch<SetStateAction<string[]>>,
): void => {
	const currentActivities = cloneDeep(updatedWeekActivities);
	const addedActivities = cloneDeep(toAddActivitiesList);

	// Remove the activity from updatedWeekActivities
	delete currentActivities[day].activities[activityId];

	if (addedActivities[activityId]) {
		// Activity Entry was added, Remove the activity from the toAdd map
		delete addedActivities[activityId];
		setToAddActivitiesList(addedActivities);
	} else {
		// Otherwise it is already exists, so add to activity delete list
		setToDeleteActivitiesList([...toDeleteActivitiesList, activityId]);
	}

	setUpdatedWeekActivities(currentActivities);
};
