import type { User } from '../firebase/firebase';
import {
	ActivityLink,
	EmployeeLink,
	ProjectTrackingIntegration,
	ProjectTrackingLinks,
	SiteLink,
} from '../models/Integrations/ProjectTrackingIntegration';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type ProjectTrackingCreateEmployeeLinkRequestBody = EmployeeLink;

const createProjectTrackingEmployeeLink = async (
	abortSignal: AbortSignal,
	user: User,
	link: ProjectTrackingCreateEmployeeLinkRequestBody,
): Promise<boolean | undefined> => {
	const response =
		await makeCloudFunctionRequestReturnSuccessStatus<ProjectTrackingCreateEmployeeLinkRequestBody>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.CreateProjectTrackingEmployeeLink,
				method: 'POST',
				services: defaultCloudFunctionServices,
				body: {
					id: link.id,
					name: link.name,
					integrationID: link.integrationID,
					integrationName: link.integrationName,
					type: ProjectTrackingLinks.Employee,
				},
			},
		);

	return response;
};

type ProjectTrackingDeleteEmployeeLinkRequestBody = EmployeeLink;

const deleteProjectTrackingEmployeeLink = async (
	abortSignal: AbortSignal,
	user: User,
	link: ProjectTrackingCreateEmployeeLinkRequestBody,
): Promise<boolean | undefined> => {
	const response =
		await makeCloudFunctionRequestReturnSuccessStatus<ProjectTrackingDeleteEmployeeLinkRequestBody>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.DeleteProjectTrackingEmployeeLink,
				method: 'POST',
				services: defaultCloudFunctionServices,
				body: {
					id: link.id,
					name: link.name,
					integrationID: link.integrationID,
					integrationName: link.integrationName,
					type: ProjectTrackingLinks.Employee,
				},
			},
		);

	return response;
};

type ProjectTrackingCreateSiteLinkRequestBody = SiteLink;

const createProjectTrackingSiteLink = async (
	abortSignal: AbortSignal,
	user: User,
	link: ProjectTrackingCreateSiteLinkRequestBody,
): Promise<boolean | undefined> => {
	const response =
		await makeCloudFunctionRequestReturnSuccessStatus<ProjectTrackingCreateSiteLinkRequestBody>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.CreateProjectTrackingSiteLink,
				method: 'POST',
				services: defaultCloudFunctionServices,
				body: {
					id: link.id,
					name: link.name,
					integrationID: link.integrationID,
					integrationName: link.integrationName,
					type: ProjectTrackingLinks.Site,
				},
			},
		);

	return response;
};

type ProjectTrackingDeleteSiteLinkRequestBody = SiteLink;

const deleteProjectTrackingSiteLink = async (
	abortSignal: AbortSignal,
	user: User,
	link: ProjectTrackingDeleteSiteLinkRequestBody,
): Promise<boolean | undefined> => {
	const response =
		await makeCloudFunctionRequestReturnSuccessStatus<ProjectTrackingDeleteSiteLinkRequestBody>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.DeleteProjectTrackingSiteLink,
				method: 'POST',
				services: defaultCloudFunctionServices,
				body: {
					id: link.id,
					name: link.name,
					integrationID: link.integrationID,
					integrationName: link.integrationName,
					type: ProjectTrackingLinks.Site,
				},
			},
		);

	return response;
};

type ProjectTrackingCreateActivityLinkRequestBody = {
	siteId: string;
	link: ActivityLink;
};

const createProjectTrackingActivityLink = async (
	abortSignal: AbortSignal,
	user: User,
	siteID: string,
	link: ActivityLink,
): Promise<boolean | undefined> => {
	const response =
		await makeCloudFunctionRequestReturnSuccessStatus<ProjectTrackingCreateActivityLinkRequestBody>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.CreateProjectTrackingActivityLink,
				method: 'POST',
				services: defaultCloudFunctionServices,
				body: {
					siteId: siteID,
					link: {
						id: link.id,
						name: link.name,
						taskCode: link.taskCode,
						costCode: link.costCode,
						timeCode: link.timeCode,
						type: ProjectTrackingLinks.Activity,
					},
				},
			},
		);

	return response;
};

type ProjectTrackingDeleteActivityLinkRequestBody = {
	siteId: string;
	link: ActivityLink;
};

const deleteProjectTrackingActivityLink = async (
	abortSignal: AbortSignal,
	user: User,
	siteID: string,
	link: ActivityLink,
): Promise<boolean | undefined> => {
	const response =
		await makeCloudFunctionRequestReturnSuccessStatus<ProjectTrackingDeleteActivityLinkRequestBody>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.DeleteProjectTrackingActivityLink,
				method: 'POST',
				services: defaultCloudFunctionServices,
				body: {
					siteId: siteID,
					link: {
						id: link.id,
						name: link.name,
						taskCode: link.taskCode,
						costCode: link.costCode,
						timeCode: link.timeCode,
						type: ProjectTrackingLinks.Activity,
					},
				},
			},
		);

	return response;
};

type ProjectTrackingStatusUpdateRequestBody = Pick<
	ProjectTrackingIntegration,
	'status'
>;

const updateProjectTrackingIntegrationStatus = async (
	abortSignal: AbortSignal,
	user: User,
	status: ProjectTrackingIntegration['status'],
): Promise<boolean> => {
	const response =
		await makeCloudFunctionRequestReturnSuccessStatus<ProjectTrackingStatusUpdateRequestBody>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.UpdateProjectTrackingIntegrationStatus,
				method: 'POST',
				services: defaultCloudFunctionServices,
				body: { status },
			},
		);

	return response;
};

export const projectTrackingFirestore = {
	createProjectTrackingEmployeeLink,
	deleteProjectTrackingEmployeeLink,
	createProjectTrackingSiteLink,
	deleteProjectTrackingSiteLink,
	createProjectTrackingActivityLink,
	deleteProjectTrackingActivityLink,
	updateProjectTrackingIntegrationStatus,
};
