import { AllowDateOrTimestamp } from '../../components/helpers/dateUtilities';
import { Activity } from '../../constants/Common';
import { PartialWithID } from '../../constants/TypescriptUtilities';
import { Firestore, FirestoreError, WriteBatch } from '../firebase';

const getActivitiesByTimesheet = (
	timesheetID: string,
): Promise<Record<string, Activity>> =>
	Firestore.collection('activities')
		.where('timesheetID', '==', timesheetID)
		.orderBy('date', 'desc')
		.get()
		.then((querySnapshot) => {
			const allActivities: Record<string, Activity> = {};
			querySnapshot.forEach((docSnapshot) => {
				const data = docSnapshot.data() as Activity;
				allActivities[docSnapshot.id] = {
					...data,
					id: docSnapshot.id,
				};
			});
			return allActivities;
		});

const getActivitiesByStatusTimesheet = async (
	statuses: Activity['status'][],
	timesheetID: string,
	startDate: Date,
	endDate: Date,
): Promise<Activity[]> => {
	const activityDocs = await Firestore.collection('activities')
		.where('status', 'in', statuses)
		.where('timesheetID', '==', timesheetID)
		.where('date', '>=', startDate)
		.where('date', '<=', endDate)
		.orderBy('date', 'desc')
		.get();
	return activityDocs.docs.map(
		(doc) => ({ id: doc.id, ...doc.data() } as Activity),
	);
};

export const getActivityIDsByTimesheet = async (
	timesheetID: string,
): Promise<string[]> => {
	const querySnapshot = await Firestore.collection('activities')
		.where('timesheetID', '==', timesheetID)
		.orderBy('date', 'desc')
		.get();
	return querySnapshot.docs.map((docSnapshot) => docSnapshot.id);
};

export const updateActivitiesWithBatch = (
	batch: WriteBatch,
	updatedActivities: PartialWithID<AllowDateOrTimestamp<Activity>>[],
): void => {
	updatedActivities.forEach((activity) =>
		batch.update(
			Firestore.collection('activities').doc(activity.id),
			activity,
		),
	);
};

export const deleteActivitiesWithBatch = (
	batch: WriteBatch,
	activityIDs: string[],
): void =>
	activityIDs.forEach((id) =>
		batch.delete(Firestore.collection('activities').doc(id)),
	);

export const setActivitiesWithBatch: {
	// overloading without having to use functions
	(
		batch: WriteBatch,
		updatedActivities: Omit<AllowDateOrTimestamp<Activity>, 'id'>[],
	): void;
	(
		batch: WriteBatch,
		updatedActivities: Omit<
			AllowDateOrTimestamp<Activity>,
			'id' | 'timesheetID'
		>[],
		timesheetID: string,
	): void;
} = (
	batch: WriteBatch,
	updatedActivities:
		| Omit<AllowDateOrTimestamp<Activity>, 'id'>[]
		| Omit<AllowDateOrTimestamp<Activity>, 'id' | 'timesheetID'>[],
	timesheetID?: string,
) =>
	updatedActivities.forEach((activity) => {
		const ref = Firestore.collection('activities').doc();
		const toSet = timesheetID
			? { ...activity, id: ref.id, timesheetID }
			: { ...activity, id: ref.id };
		batch.set(ref, toSet);
	});

const activitySubscription = (
	timesheetID: string,
	onNext: (activities: Record<string, Activity>) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) =>
	Firestore.collection('activities')
		.where('timesheetID', '==', timesheetID)
		.orderBy('date', 'desc')
		.onSnapshot(
			(snapshot) =>
				onNext(
					Object.fromEntries(
						snapshot.docs.map((doc) => [
							doc.id,
							{ ...doc.data(), id: doc.id } as Activity,
						]),
					),
				),
			onError,
		);

const getActivitiesBySiteWeek = async (
	siteID: string,
	week: Date,
): Promise<Activity[]> => {
	const activityDocs = await Firestore.collection('activities')
		.where('siteID', '==', siteID)
		.where('date', '==', week)
		.get();
	return activityDocs.docs.map(
		(doc) => ({ id: doc.id, ...doc.data() } as Activity),
	);
};

const getActivitiesBySiteDateRange = async (
	siteID: string,
	startDate: Date,
	endDate: Date,
): Promise<Activity[]> => {
	const activityDocs = await Firestore.collection('activities')
		.where('siteID', '==', siteID)
		.where('date', '>=', startDate)
		.where('date', '<=', endDate)
		.get();
	return activityDocs.docs.map(
		(doc) => ({ id: doc.id, ...doc.data() } as Activity),
	);
};

const getActivitiesByStatusSiteCompanyDateRange = async (
	statuses: Activity['status'][],
	siteCompanyID: string,
	startDate: Date,
	endDate: Date,
): Promise<Activity[]> => {
	const activityDocs = await Firestore.collection('activities')
		.where('status', 'in', statuses)
		.where('siteCompanyID', '==', siteCompanyID)
		.where('date', '>=', startDate)
		.where('date', '<=', endDate)
		.get();
	return activityDocs.docs.map(
		(doc) => ({ id: doc.id, ...doc.data() } as Activity),
	);
};

const deleteActivities = async (activityIDs: string[]): Promise<void> => {
	const batch = Firestore.batch();
	deleteActivitiesWithBatch(batch, activityIDs);
	return batch.commit();
};

const activitiesFirebaseApi = {
	activitySubscription,
	getActivitiesByTimesheet,
	getActivitiesBySiteWeek,
	getActivitiesBySiteDateRange,
	getActivitiesByStatusSiteCompanyDateRange,
	deleteActivities,
	getActivitiesByStatusTimesheet,
};

export default activitiesFirebaseApi;
